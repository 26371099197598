import React, {useState} from "react";
import Box from "@amzn/meridian/box";
import Text from "@amzn/meridian/text";
import Divider from "@amzn/meridian/divider";
import Row from "@amzn/meridian/row";
import {DRIVER_ENTITY_HEADING} from "../../constants/Constants";
import {FormattedMessage, injectIntl} from "react-intl";
import {DetailsCardView} from "@amzn/dolphin-uiweb-framework";
import {getDisplayName} from "../../handler/TranslationHandler";
import {withRouter} from "react-router";
import PropTypes from 'prop-types';
import {getRegion} from "../../config/NetConf";
import {Constants} from "@amzn/dolphin-web-framework";

const initialState = {
    showOverView: true,
    showDetails: false
}

const DriverEntityCommon = ({transporter, intl}) => {

    const [state, setState] = useState(initialState);
    const showTransporterPersonalDetails = getRegion() !== Constants.Region.REGION_EU;

    const showOverview = () => {
        setState({showOverView: true, showDetails: false})
    }

    const showDetails = () => {
        setState({showOverView: false, showDetails: true})
    }
    return (
        <Box >
            <Box spacingInset="small medium none medium">
                <Text type="b400" breakWord={true}>{getText(showTransporterPersonalDetails, transporter)}</Text>
            </Box>
            <Divider size={"small"} spacingAfter="xsmall" spacingBefore="xsmall"/>
            <Row spacingInset="none medium none medium" >
                <div onClick={showOverview}>
                    <Text type={getTextType(DRIVER_ENTITY_HEADING.OVERVIEW, state)}><FormattedMessage id="overview"/></Text>
                </div>
                <div onClick={showDetails}>
                    <Text type={getTextType(DRIVER_ENTITY_HEADING.DETAILS, state)}><FormattedMessage id="details"/></Text>
                </div>
            </Row>
            <Box  spacingInset={"xsmall medium none medium"} >
                <DetailsCardView snapshots={getDataForDetailsCardView(transporter, intl, state, showTransporterPersonalDetails)}
                                 display="Column"/>
            </Box>
            <Divider size={"small"} spacingAfter="medium" spacingBefore="medium"/>
        </Box>
    )
}

const getTextType = (heading, state) => {
    if(DRIVER_ENTITY_HEADING.OVERVIEW === heading) {
        return state.showOverView ? "h200":"b400"
    } else {
        return state.showDetails ? "h200":"b400"
    }
}

const getDataForDetailsCardView = (transporter, intl, state, showTransporterPersonalDetails) => {
    var data = []
    if(state.showOverView) {
        if(showTransporterPersonalDetails) {
            data.push({
                title: getDisplayName(intl, "transporter_overview_name"),
                description: transporter.transporterName
            })
        }
        else {
            data.push({
                title: getDisplayName(intl, "transporter_id"),
                description: transporter.transporterId
            })
        }
    }else {
        if(showTransporterPersonalDetails) {
            data.push({title: getDisplayName(intl, "transporter_name"), description: transporter.transporterName})
            data.push({title: getDisplayName(intl, "transporter_email"), description: transporter.email})
            data.push({title: getDisplayName(intl, "transporter_id"), description: transporter.transporterId})
            data.push({title: getDisplayName(intl, "transporter_phone_number"), description: transporter.contactInfo})
        }
        else {
            data.push({title: getDisplayName(intl, "transporter_id"), description: transporter.transporterId})
            data.push({title: getDisplayName(intl, "transporter_short_code"), description: transporter.shortCode})
        }
    }
    return data
}

const getText = (showTransporterPersonalDetails, transporter) => {
    const parameters = showTransporterPersonalDetails ? [transporter.transporterName, transporter.email]
                                : [transporter.transporterId, transporter.shortCode];
    return parameters.filter(param => !!param).join(' | ')
}

DriverEntityCommon.propTypes = {
    transporter: PropTypes.object,
    intl: PropTypes.object
};
export default withRouter(injectIntl(DriverEntityCommon))