import React from 'react';
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import {
  getPackagesCountString
} from "../../helper/ScannedPackagesListHelper";
import {getScannedScannableIds} from "../../dataStore/PSExecutionDataStore";
import Column from "@amzn/meridian/column";
import PropTypes from 'prop-types';
import {getInfoBarStyleConfig, getInfoBarSwiperConfig} from "../../constants/ScannedPackageListStyles";
import {FormattedMessage} from "react-intl";

export const ScannedPackagesInfoBar = ({rightHeader}) => {

  return (
    <div style={getInfoBarStyleConfig()}>
      <hr style={getInfoBarSwiperConfig()}/>
      <Row width="100%" widths={["40%", "60%"]}
           wrap="none"
           spacing="xxsmall"
           spacingInset="small">
        <Column alignmentHorizontal="left">
          <Text type="h100">
              {getScannedScannableIds().length} <FormattedMessage id={getPackagesCountString(getScannedScannableIds().length)}/>
          </Text>
        </Column>
        <Column alignmentHorizontal="right">
          {rightHeader &&
            <Text alignment="right" type="h100"><FormattedMessage id={rightHeader}/></Text>
          }
        </Column>
      </Row>
    </div>
  );
};

ScannedPackagesInfoBar.propTypes = {
  rightHeader: PropTypes.any
};