import {ScrubCategories} from "../components/scrub/constants/constants";
import {getExceptionShipmentSummaryWithoutShipDetailsWithDispatcher} from "../network/GetExceptionShipmentSummaryWithoutShipDetailsAPI";
import {LEFT_TO_FIX_REDUCERS_ACTION_TYPE} from "../reducers/LeftToFixReducer";
import {getStartTimeForScrub} from "../components/scrub/helper/helper";

export function fetchExceptionShipmentSummaryWithoutShipDetails(dispatcher) {
    const categories = ScrubCategories.ALL
    getExceptionShipmentSummaryWithoutShipDetailsWithDispatcher({
        category: categories,
        startDate: getStartTimeForScrub(),
        endDate: new Date().toString()
    }, dispatcher, LEFT_TO_FIX_REDUCERS_ACTION_TYPE.FETCH_EXCEPTION_SHIPMENT_COUNT, LEFT_TO_FIX_REDUCERS_ACTION_TYPE.ERROR)
}

export const fetchScrubFilters = ({exceptionShipmentSummaryForCategory}) => {
    let scrubCount = 0
    ScrubCategories.ALL.forEach((filter) => {
        scrubCount += exceptionShipmentSummaryForCategory[filter].OPEN.length
    })
    return [{queueName: "left_to_fix_scrub_queue", exceptionCount: scrubCount}]
}