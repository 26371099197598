import {ScrubCategories, PS_STATION_SWEEP} from "../constants/constants";
import {
    fetchExceptionShipmentSummary,
    fetchExceptionShipmentSummaryWithoutShipDetails, fetchScrubDetailCategoryMap,
    fetchScrubExceptionToCount, fetchScrubPackage
} from "../helper/helper";
import {getPackageCategory} from "../views/Utils";
import {ScrubDataStore} from "../DataStore/ScrubDataStore";

export const ScrubReducerInitialState = {
    refreshPage: true,
    loading: false,
    queueToCategories:ScrubCategories,
    displayNotification: null,
    resolvedPackages: 0
};

export const SCRUB_ACTION_TYPE = {
    SET_REFRESH_PAGE: "SET_REFRESH_PAGE",
    FETCH_SCRUB_COUNT: "FETCH_SCRUB_COUNT",
    SET_SCRUB_COUNT: "SET_EXCEPTION_CATEGORIES_TO_COUNT",
    FETCH_SCRUB_EXCEPTION_DETAILS: "FETCH_SCRUB_EXCEPTION_DETAILS",
    SET_SCRUB_EXCEPTION_DETAILS: "SET_SCRUB_EXCEPTION_DETAILS",
    SET_SELECTED_CATEGORY: "SET_SELECTED_CATEGORY",
    SCRUB_PACKAGE: "SCRUB_PACKAGE",
    SCRUB_PACKAGE_RESPONSE: "SCRUB_PACKAGE_RESPONSE",
    RESET_SCRUB_PACKAGE_RESPONSE: "RESET_SCRUB_PACKAGE_RESPONSE",
    ERROR: "ERROR",
    SET_NOTIFICATION: "SET_NOTIFICATION",
    RESET_NOTIFICATION: "RESET_NOTIFICATION",
    INCREMENT_RESOLVED_PACKAGE: "INCREMENT_RESOLVED_PACKAGE",
    ERROR_NOTIFICATION: "ERROR_NOTIFICATION"
}

export const ScrubReducer = (state, action) => {
    switch (action.type) {
        case SCRUB_ACTION_TYPE.FETCH_SCRUB_COUNT:
            fetchExceptionShipmentSummaryWithoutShipDetails(action.dispatcher, action.notifier)
            return {...state, loading:true};

        case SCRUB_ACTION_TYPE.SET_SCRUB_COUNT: {
            const scrubCategoriesToCount = fetchScrubExceptionToCount(action.data)
            ScrubDataStore.setScrubCategoryMap(scrubCategoriesToCount)
            return {...state, loading: false}
        }

        case SCRUB_ACTION_TYPE.SET_SELECTED_CATEGORY:
            ScrubDataStore.setSelectedCategory(action.data)
            return {...state, selectedScrubCategory:action.data}

        case SCRUB_ACTION_TYPE.FETCH_SCRUB_EXCEPTION_DETAILS: {
            const selectedScrubCategory = ScrubDataStore.getSelectedCategory()
            const category = selectedScrubCategory ? selectedScrubCategory : PS_STATION_SWEEP
            fetchExceptionShipmentSummary(category, action.dispatcher, action.notifier)
            return {...state, loading: true};
        }

        case SCRUB_ACTION_TYPE.SET_SCRUB_EXCEPTION_DETAILS: {
            const selectedScrubCategory = ScrubDataStore.getSelectedCategory()
            const category = selectedScrubCategory ? selectedScrubCategory : PS_STATION_SWEEP
            const exceptionDetails = fetchScrubDetailCategoryMap(category, action.data)
            ScrubDataStore.setScrubCategoryExceptionDetails(exceptionDetails)
            return {...state, loading: false}
        }

        case SCRUB_ACTION_TYPE.SCRUB_PACKAGE: {
            const trackingId = action.data
            const category = getPackageCategory(trackingId)
            fetchScrubPackage(trackingId, category, action.dispatcher, action.notifier)
            return {...state, loading: true}
        }

        case SCRUB_ACTION_TYPE.SCRUB_PACKAGE_RESPONSE:
            ScrubDataStore.setScannedPackageDetails(action.data)
            return {...state, loading: false, displayNotification: null};

        case SCRUB_ACTION_TYPE.RESET_SCRUB_PACKAGE_RESPONSE:
            ScrubDataStore.setScannedPackageDetails(null)
            return {...state}

        case SCRUB_ACTION_TYPE.SET_NOTIFICATION:
            return {...state, displayNotification: action.data};

        case SCRUB_ACTION_TYPE.ERROR_NOTIFICATION:
            return {...state, displayNotification: action.data, loading: false};

        case SCRUB_ACTION_TYPE.RESET_NOTIFICATION:
            return {...state, displayNotification: null};

        case SCRUB_ACTION_TYPE.ERROR:
            return {...state, loading: false};

        case SCRUB_ACTION_TYPE.INCREMENT_RESOLVED_PACKAGE: {
            const count = ScrubDataStore.getResolvedPackages() ? ScrubDataStore.getResolvedPackages() : 0
            ScrubDataStore.setResolvedPackages(count + 1)
            return {...state};
        }

        default:
            return state
    }
};