import React from "react"
import ScrubContextProvider from "../components/scrub/context/ScrubContext";
import ScrubSelectionModeView from "../components/scrub/views/ScrubSelectionModeView";
import {Route, Switch, withRouter} from "react-router-dom";
import ScrubCompleteView from "../components/scrub/views/ScrubComplete";
import ScrubSolveOtherExceptionView from "../components/scrub/views/ScrubSolveOtherExceptionView";
import ScrubPackageReview from "../components/scrub/views/ScrubPackageReview";
import ScrubPackageListView from "../components/scrub/views/ScrubPackageListView";
import ScrubContinueOnScan from "../components/scrub/views/ScrubContinueOnScan";
import {injectIntl} from "react-intl";
import TopNavigation from "../components/common/TopNavigation";
import Column from "@amzn/meridian/column";
import AppContextProvider from "../components/backPressedView/AppContext";

export const SCRUB_ROUTE_PATH = {
    SCRUB: "/scrub/category",
    SCRUB_PACKAGE_LIST: "/scrub/packageList",
    SCRUB_CONTINUE: "/scrub/continue",
    SCRUB_COMPLETE: "/scrub/complete",
    SCRUB_SOLVE_EXCEPTION: "/scrub/solveException",
    SCRUB_REVIEW: "/scrub/review"
}

const ScrubRouter = () => {
    return (
        <ScrubContextProvider>
            <Column height={"100%"} spacing={"none"} id={"container"}>
                <AppContextProvider>
                    <TopNavigation title="operation_problem_solve"/>
                    <Column height="100vh">
                        <Switch>
                            <Route exact path={SCRUB_ROUTE_PATH.SCRUB}
                                   component={withRouter(injectIntl(ScrubSelectionModeView))}/>
                            <Route exact path={SCRUB_ROUTE_PATH.SCRUB_COMPLETE}
                                   component={withRouter(injectIntl(ScrubCompleteView))}/>
                            <Route exact path={SCRUB_ROUTE_PATH.SCRUB_SOLVE_EXCEPTION}
                                   component={withRouter(injectIntl(ScrubSolveOtherExceptionView))}/>
                            <Route exact path={SCRUB_ROUTE_PATH.SCRUB_REVIEW}
                                   component={withRouter(injectIntl(ScrubPackageReview))}/>
                            <Route exact path={SCRUB_ROUTE_PATH.SCRUB_PACKAGE_LIST}
                                   component={withRouter(injectIntl(ScrubPackageListView))}/>
                            <Route exact path={SCRUB_ROUTE_PATH.SCRUB_CONTINUE}
                                   component={withRouter(injectIntl(ScrubContinueOnScan))}/>
                        </Switch>
                    </Column>
                </AppContextProvider>
            </Column>
        </ScrubContextProvider>
    )
}

export default ScrubRouter
