import React from 'react';
import {Route, Switch, useLocation} from 'react-router-dom';
import ScrubRouter from "./ScrubRouter";
import Home from "../components/home/Home";
import HelpPage from "../components/help/HelpPage";
import ProblemSolveExecutionRouter from "./ProblemSolveExecutionRouter";
import Left2FixRouter from "./Left2FixRouter";
import {SWIPE_DISABLED, SWIPE_DOWN_TO_REFRESH_ROUTES, SWIPE_ENABLED} from "../constants/Constants";
import {withRouter} from "react-router";
import {injectIntl} from "react-intl";
import SelectEntity from "../components/selectentity/SelectEntity";
import RouteEntityRouter from "./RouteEntityRouter";
import DriverEntityRouter from "./DriverEntityRouter";
import {PUMAMetricHelper} from "@amzn/dolphin-web-framework";
import withDevSupport from "../dev-support";

export const ROUTE_PATH = {
    HOME: "/",
    HELP_CENTER: "/helpcenter",
    NEW_SESSION: "/newSession",
    SELECT_ENTITY: "/selectEntity",
    DRIVER_ENTITY: "/driverEntity/"
};

function publishRoutePath(location) {
    const metricName = location.pathname;
    PUMAMetricHelper.publishCountToDolphinCSM(metricName);
}

export default function Routing() {
    document.body.style.overscrollBehaviorY =
        (SWIPE_DOWN_TO_REFRESH_ROUTES.includes(useLocation().pathname)) ? SWIPE_ENABLED : SWIPE_DISABLED;
    publishRoutePath(useLocation());
    return withDevSupport(
        <Switch>
            <Route exact path={ROUTE_PATH.HOME} component={Home}/>
            <Route exact path={ROUTE_PATH.HELP_CENTER} component={HelpPage}/>
            <Route exact path={ROUTE_PATH.SELECT_ENTITY} component={withRouter(injectIntl(SelectEntity))}/>
            <Route path="/problemsolve/left2fix" component={Left2FixRouter}/>
            <Route path="/ps/" component={ProblemSolveExecutionRouter}/>
            <Route path="/routeEntity/" component={RouteEntityRouter}/>
            <Route path="/scrub/" component={ScrubRouter}/>
            <Route path={ROUTE_PATH.DRIVER_ENTITY} component={DriverEntityRouter}/>
        </Switch>
    )
}