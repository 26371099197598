import React from 'react';
import Box from "@amzn/meridian/box";
import {injectIntl} from "react-intl";
import {withRouter} from 'react-router-dom';
import ProblemSolveOperationLauncher from "./ProblemSolveOperationLauncher";

const Home = () => {
    return (
        <Box spacingInset="none">
            <ProblemSolveOperationLauncher/>
        </Box>
    );
};

export default withRouter(injectIntl(Home));