import React, {useContext, useEffect, useState} from "react"
import Alert from "@amzn/meridian/alert";
import Text from "@amzn/meridian/text";
import {ScrubContext} from "./context/ScrubContext";
import {isNotEmptyObject} from "./views/Utils";
import {FormattedMessage} from "react-intl";
import Row from "@amzn/meridian/row";

const NotificationView = () => {
    const {state: {displayNotification}, scrubActions} = useContext(ScrubContext)
    const [notification, setNotification] = useState(null)

    useEffect(() => {
        if (isNotEmptyObject(displayNotification)) {
            setNotification(displayNotification)
        }
    }, [displayNotification])

    const clearNotification = () => {
        setNotification(null)
        scrubActions.resetNotification()
    }

    useEffect(() => {
        return () => {
            scrubActions.resetNotification()
        }
    }, [])

    return (
        isNotEmptyObject(notification) ?
            <Row width="100%" widths="fill" spacingInset="medium medium none medium">
            <Alert type="warning" size="medium" onClose={clearNotification}>
                    <Text><FormattedMessage id={notification} /></Text>
                </Alert>
            </Row>: null
    )
}

export default NotificationView;