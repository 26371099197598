import React from "react";
import Row from "@amzn/meridian/row";
import Loader from "@amzn/meridian/loader";
import Text from "@amzn/meridian/text";
import Box from "@amzn/meridian/box";
import {FormattedMessage} from "react-intl";
import PropTypes from 'prop-types';
import {DetailsCardView} from "@amzn/dolphin-uiweb-framework";
import Column from "@amzn/meridian/column";


const getPackageData = (title, description) => {
    return [{title: title, description: description}]
}

const InProgress = ({heading, subHeading, scannableId, text}) => {
    if (heading && scannableId && subHeading) {
        return (
            <Column height="60%" heights={["fill","fit"]}>
                <Column spacingInset="medium">
                    <br/>
                    <Row alignmentHorizontal="center" alignmentVertical="top" height="3%">
                        <Text type="h300"><FormattedMessage id={heading}/></Text>
                    </Row>
                    <div>
                        <hr width="80%" color="#9e9493"></hr>
                        <Row alignmentHorizontal="center" alignmentVertical="top" height="5%">
                            <Text type="b200" alignment="center"><FormattedMessage id={subHeading}/></Text>
                        </Row>
                    </div>
                    <br/>

                    <DetailsCardView snapshots={getPackageData(scannableId, "Package")}></DetailsCardView>
                </Column>
                <Row alignmentHorizontal="center" alignmentVertical="bottom" height="50%" >
                    <div data-testid="Loader">
                        <Loader type="circular" size="large"/>
                    </div>
                </Row>
            </Column>
        )
    } else {
        return (
            <Box spacingInset="xlarge" backgroundColor="#ffffff90" className="appLoader" height="90%" width="100%" heights="fill"
                 alignmentHorizontal="center">
                <Row alignmentHorizontal="center" alignmentVertical="bottom" height="50%" >
                    <div data-testid="Loader">
                        <Loader type="circular" size="medium"/>
                    </div>
                </Row>
                {text &&
                    <Row alignmentHorizontal="center" alignmentVertical="top" height="50%">
                        <Text alignment="center" type="b200"><FormattedMessage id={text} /></Text>
                    </Row>}
            </Box>
        )
    }
}

InProgress.propTypes = {
    heading: PropTypes.string,
    subHeading: PropTypes.string,
    scannableId: PropTypes.string,
    text: PropTypes.string,
};
export default InProgress