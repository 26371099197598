import {getProblemSolveCategoryMap} from "../network/apis/npsw/GetProblemSolveCategoryMapAPI";
import {getProblemTypeToCategoryMap, setProblemTypeToCategoryMap} from "../dataStore/PSExecutionDataStore";
import {ENTITY_TYPES} from "../constants/Constants";

export const ProblemTypeReducerInitialState = {
        problemTypeToCategoryMap: {},
        problemTypes: [],
        fetchingData: false,
}

export const ProblemTypeReducerActionType = {
    GET_PROBLEM_TYPE_TO_CATEGORY_MAP: "GET_PROBLEM_TYPE_TO_CATEGORY_MAP",
    SET_PROBLEM_TYPE_TO_CATEGORY_MAP: "SET_PROBLEM_TYPE_TO_CATEGORY_MAP",
    ERROR: "ERROR"
};

export const ProblemTypeReducer = (state, action) => {
    switch (action.type) {
        case ProblemTypeReducerActionType.GET_PROBLEM_TYPE_TO_CATEGORY_MAP: {
            const problemTypeToCategoryMap = getProblemTypeToCategoryMap()
            if(problemTypeToCategoryMap == null) {
                getProblemSolveCategoryMap(action.data, action.dispatcher,
                    ProblemTypeReducerActionType.SET_PROBLEM_TYPE_TO_CATEGORY_MAP, ProblemTypeReducerActionType.ERROR)
                return {
                    ...state,
                    fetchingData: true
                };
            } else {
                return {
                    ...state,
                    fetchingData: false,
                    problemTypeToCategoryMap: problemTypeToCategoryMap,
                    problemTypes: Object.keys(problemTypeToCategoryMap)
                };
            }
        }

        case ProblemTypeReducerActionType.SET_PROBLEM_TYPE_TO_CATEGORY_MAP:
            setProblemTypeToCategoryMap(action.data[ENTITY_TYPES.PACKAGE]);
            return {
                ...state,
                fetchingData: false,
                problemTypeToCategoryMap: action.data[ENTITY_TYPES.PACKAGE],
                problemTypes: Object.keys(action.data[ENTITY_TYPES.PACKAGE])
            };
        case ProblemTypeReducerActionType.ERROR:
            return {
                ...state,
                fetchingData: false,
            }
        default:
            return state;
    }
}