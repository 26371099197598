import React, {useEffect} from 'react';
import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Alert from "@amzn/meridian/alert";
import {useHistory} from "react-router-dom";
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl} from "react-intl";
import {
    clearBackPressedEventListener,
    setBackPressedEventListener
} from "../backPressedView/BackButton";
import {SoundManager} from "../../audio/SoundManager";
import {getErrorTitle, getErrorDescription} from "../../helper/ErrorScreenHelper";
import Box from "@amzn/meridian/box";

const ErrorScreen = ({location, intl}) => {
    const errorType = (location.state)? location.state.errorType : null;
    const metaData = (location.state)? location.state.metaData : {};

    useEffect(() => {
        SoundManager.playErrorSound();
        setBackPressedEventListener();

        return () => {
            clearBackPressedEventListener();
        }
    }, []);

    const history = useHistory();
    const goBack = () => {
        history.goBack();
    };

    return (
        <Column height="100%" heights={["75%", "fit"]}>
            <Column spacingInset="medium">
                <Box spacingInset="medium none none none"></Box>
                <Alert
                    type="error"
                    size="xlarge"
                    title={getErrorTitle(errorType, metaData, intl.formatMessage)}>
                    {getErrorDescription(errorType, metaData, intl.formatMessage)}
                </Alert>
            </Column>
            <Column width="100%" spacingInset="medium" alignmentVertical="top">
                <Button onClick={goBack}><FormattedMessage id="continue_solving"/></Button>
                <div className="spaceBlocker"/>
            </Column>
        </Column>
    );
};

ErrorScreen.propTypes = {
    intl: PropTypes.object,
    location: PropTypes.object
};

export default injectIntl(ErrorScreen);