import React from "react"
import Toaster from "@amzn/meridian/toaster"
import Alert from "@amzn/meridian/alert"
import {FormattedMessage} from "react-intl";
import PropTypes from 'prop-types';

const ToastMessage = ({text, callback}) => {

    return (
        <div className="toast">
            <Toaster alignmentHorizontal="left" toasts={[{id:"1", timeout:3000}]} onCloseToast={callback}  position="absolute">
                {()=><Alert toast={true} size="medium" type="informational">
                    <FormattedMessage id={text} /><br/><br/>
                </Alert>}
            </Toaster>
        </div>
    )
};

ToastMessage.propTypes = {
    text: PropTypes.string,
    callback: PropTypes.func
};
export default ToastMessage