import {fetchProblemCategoryConfig} from "../handler/ProblemCategoryConfigHandler";
import {
    getProblemTypeToCategoryMap, saveExceptionResolutionResult, saveProblemAction,
    saveProblemCategoryConfig,
    saveProblemType
} from "../dataStore/PSExecutionDataStore";
import {callActionApi, processApiExecutionResponse} from "../helper/ActionComponentsHelper";
import {ExceptionAction, PROBLEM_ACTION_API} from "../constants/Constants";
import {getOpenExceptionTargetAction} from "../helper/ProblemCategoryHelper";
import {useHistory} from "react-router";
import {PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS} from "../router/ProblemSolveExecutionRouter";


export const ProblemCategoryReducerInitialState = {
    selectedProblemType: "",
    problemCategories: [],
    fetchingData: false,
}

export const ProblemCategoryReducerActionType = {
    SET_PROBLEM_TYPE: "SET_PROBLEM_TYPE",
    ERROR: "ERROR",
    MARK_SOLVE_LATER: "MARK_SOLVE_LATER",
    MARK_SOLVE_LATER_RESPONSE: "MARK_SOLVE_LATER_RESPONSE"
};

export const ProblemCategoryReducer = (state, action) => {
    switch (action.type) {
        case ProblemCategoryReducerActionType.SET_PROBLEM_TYPE: {
            const problemTypeToCategoryMap = getProblemTypeToCategoryMap()
            saveProblemType(action.data)
            saveProblemCategoryConfig(fetchProblemCategoryConfig())
            return {
                ...state,
                selectedProblemType: action.data,
                problemCategories: Object.keys(problemTypeToCategoryMap[action.data]),
                problemCategoryConfig: fetchProblemCategoryConfig()
            };
        }
        case ProblemCategoryReducerActionType.ERROR:
            return {
                ...state,
                fetchingData: false,
            };
        case ProblemCategoryReducerActionType.MARK_SOLVE_LATER:
            saveProblemAction(ExceptionAction.NO_OP);
            callActionApi({
                    api: PROBLEM_ACTION_API.CREATE_OPEN_EXCEPTION,
                    properties:{
                        targetAction: getOpenExceptionTargetAction(action.data)
                    }
                },
                action.dispatch,
                ProblemCategoryReducerActionType.MARK_SOLVE_LATER_RESPONSE,
                ProblemCategoryReducerActionType.ERROR);
            return {
                ...state,
                fetchingData: true
            };
        case ProblemCategoryReducerActionType.MARK_SOLVE_LATER_RESPONSE: {
            const {exceptionResolutionResult} = processApiExecutionResponse(action.data)
            saveExceptionResolutionResult(exceptionResolutionResult)
            //eslint-disable-next-line react-hooks/rules-of-hooks
            useHistory().push({
                pathname: PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.SUCCESS_SCREEN,
                state: {
                    properties: {
                        isPrintLabelSupported: false
                    }
                }
            });
            return {...state, fetchingData: false};
        }
        default:
            return state;
    }
}