import Box from "@amzn/meridian/box";
import React from "react";
import {ERROR_SCREEN_ERROR_TYPE} from "../constants/ErrorStringConstants";
import translate from "../handler/TranslationHandler";

const getStageItemErrorTitle = () => {
    return translate("invalid_cart")
}

const getStageItemErrorDescription = (metaData) => {
    return (
        <Box>
            <Box spacingInset="none none medium none">{translate("does_not_belong_to_assigned_route")}</Box>
            {translate("cart")} <b>{metaData.cartLabel}</b>
            <br/>
            {translate("staging_location")} <b>{metaData.stagingAreaLabel}</b>
        </Box>
    );
}

export const getErrorTitle = (errorType, metaData) => {
    if(errorType === ERROR_SCREEN_ERROR_TYPE.INVALID_CART) {
        return getStageItemErrorTitle();
    }
}

export const getErrorDescription = (errorType, metaData) => {
    if(errorType === ERROR_SCREEN_ERROR_TYPE.INVALID_CART) {
        return getStageItemErrorDescription(metaData);
    }
}