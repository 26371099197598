import {getProblemAction, getProblemCategory, getScannedScannableIds} from "../dataStore/PSExecutionDataStore";
import {problemActionConfig} from "../config/ProblemActionConfig";
import {getLabelType} from "./LabelTypeConfig";

export const PRINTER_RESOLUTION = {
    "203": 203
};

export const isPrintingSupported = () => {
    const scannables = getScannedScannableIds();
    const problemCategory = getProblemCategory();
    const targetAction = getTargetActionForProblemAction(getProblemAction());
    if(scannables.length > 1) {
        return false;
    }
    return !!(getLabelType(problemCategory + targetAction));
};

export const getPackageLabelType = () => {
    const problemCategory = getProblemCategory();
    const targetAction = getTargetActionForProblemAction(getProblemAction());
    return getLabelType(problemCategory+targetAction);
};

const getTargetActionForProblemAction = (problemCategory) => {
    return (problemActionConfig[problemCategory] &&
        problemActionConfig[problemCategory][0].properties &&
        problemActionConfig[problemCategory][0].properties.targetAction) ?
        problemActionConfig[problemCategory][0].properties.targetAction: null;
};