import React, {useEffect, useReducer} from 'react';
import {FormattedMessage, injectIntl} from "react-intl";
import {withRouter} from "react-router-dom";
import Column from "@amzn/meridian/column";
import Button from "@amzn/meridian/button";
import Text from "@amzn/meridian/text";
import {
    REPRINT_LABEL_REDUCERS_ACTION_TYPE,
    ReprintLabelInitialState,
    ReprintLabelReducer
} from "../reducers/ReprintLabelReducer";
import InProgress from "../components/common/InProgress";
import NotificationMessage from "../components/common/NotificationMessage";
import {ProblemActionReducerActionType} from "../reducers/ProblemActionReducer";
import PropTypes from 'prop-types';
import {getProblemCategory, getProblemCategoryConfig, getScannedScannableIds} from "../dataStore/PSExecutionDataStore";
import Printer from "./Printer";
import {PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS} from "../router/ProblemSolveExecutionRouter";
import {isSalColorAuditRequired} from "../utils/ShipmentUtils";

const PrintActiveLabel = ({history, location}) => {
    const problemCategoryConfig = getProblemCategoryConfig();
    const [{isLoading, notificationType, notificationMessageId, isPrintSuccess}, dispatch] = useReducer(ReprintLabelReducer, ReprintLabelInitialState);
    const additionalProperties = location.state.additionalProperties;

    const printActiveLabel = () => {
        dispatch({
            type: REPRINT_LABEL_REDUCERS_ACTION_TYPE.FETCH_PACKAGE_LABEL,
            data: {
                scannableId: getScannedScannableIds()[0],
                labelType: "ACTIVE_LABEL"
            },
            dispatcher: dispatch
        });
    };

    const handleContinueButtonClick = () => {
        history.push({
            pathname: isSalColorAuditRequired() ? PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.SAL_COLOR_AUDIT : PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.PROBLEM_ACTION,
            state: {
                properties: {
                    actionList: problemCategoryConfig[getProblemCategory()].actionListAfterPrintLabel
                },
                additionalProperties: additionalProperties
            }
        })
    };

    useEffect(()=>{
        return () => {
            Printer.closePrinter();
        };
    },[]);

    if(isPrintSuccess) {
        //handle print success directly here
    }

    return (
        <Column height="100%" heights={["fill", "fit"]}>
            <Column spacingInset="medium">
                {isLoading && <InProgress/>}
                {notificationType &&
                <NotificationMessage dispatch={dispatch} actionType={ProblemActionReducerActionType.CLOSE_NOTIFICATION}
                                     type={notificationType} messageId={notificationMessageId}/>}
                <Text type="h300">
                    <FormattedMessage id="ps_print_label_confirmation_heading"/>
                </Text>
            </Column>
            <Column width="100%" spacingInset="medium" alignmentVertical="top">

                <Button type="secondary" size="large" onClick={() => printActiveLabel()}>
                    <FormattedMessage id="ps_print_label"/>
                </Button>

                <Button size="large" onClick={() => handleContinueButtonClick()}>
                    <FormattedMessage id="ps_continue_without_label_printing"/>
                </Button>
                <div className="spaceBlockerMedium"/>
            </Column>
        </Column>
    );
};
PrintActiveLabel.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
};
export default withRouter(injectIntl(PrintActiveLabel));