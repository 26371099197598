import {SearchTransporterAPI} from "../network/apis/npsw/SearchTransporterAPI";
import {callActionApi, processApiExecutionResponse} from "../helper/ActionComponentsHelper";
import {NOTIFICATION_TYPE, PROBLEM_ACTION_API} from "../constants/Constants";
import {saveExceptionResolutionResult} from "../dataStore/PSExecutionDataStore";
import {useHistory} from "react-router";
import {PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS} from "../router/ProblemSolveExecutionRouter";

export const SearchTransporterInitialState = {
  transporters: [],
  loading: false,
  notificationType: "",
  notificationMessageId: ""
};

export const SEARCH_TRANSPORTER_ACTION_TYPE = {
  FETCH_TRANSPORTER: "FETCH_TRANSPORTER",
  SET_TRANSPORTER: "SET_TRANSPORTER",
  ERROR: "ERROR",
  CHANGE_TRANSPORTER: "CHANGE_TRANSPORTER",
  CHANGE_TRANSPORTER_RESPONSE: "CHANGE_TRANSPORTER_RESPONSE",
  CLOSE_NOTIFICATION: "CLOSE_NOTIFICATION",
};

export const SearchTransporterReducer = (state, action) => {
  switch (action.type) {
  case SEARCH_TRANSPORTER_ACTION_TYPE.FETCH_TRANSPORTER:
    SearchTransporterAPI(action.data, action.dispatcher);
    return {...state, loading: true};
  case SEARCH_TRANSPORTER_ACTION_TYPE.SET_TRANSPORTER:
    return {...state, loading: false, transporters: action.data};
  case SEARCH_TRANSPORTER_ACTION_TYPE.ERROR:
    return {...state, loading: false, notificationType: NOTIFICATION_TYPE.ALERT,
      notificationMessageId:"internal_error"};
  case SEARCH_TRANSPORTER_ACTION_TYPE.CHANGE_TRANSPORTER:
    callActionApi({
      api: PROBLEM_ACTION_API.UPDATE_EXCEPTION_SHIPMENTS,
      properties: {
        targetAction: "RE_ASSIGN"
      },
      additionalProperties: action.data
    }, action.dispatcher,
        SEARCH_TRANSPORTER_ACTION_TYPE.CHANGE_TRANSPORTER_RESPONSE,
        SEARCH_TRANSPORTER_ACTION_TYPE.ERROR);
      return {...state, loading: true};
  case SEARCH_TRANSPORTER_ACTION_TYPE.CHANGE_TRANSPORTER_RESPONSE: {
    const {exceptionResolutionResult} = processApiExecutionResponse(action.data);
    saveExceptionResolutionResult(exceptionResolutionResult);
    //eslint-disable-next-line react-hooks/rules-of-hooks
    useHistory().push({
      pathname: PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.SUCCESS_SCREEN,
      state: {
        properties: {
          isPrintLabelSupported: false
        },
        metaData: action.metaData
      }
    });
    return {...state, loading: false};
  }
    case SEARCH_TRANSPORTER_ACTION_TYPE.CLOSE_NOTIFICATION:
      return {...state, notificationType: null};
  default:
    return state;
  }
};