import React, {useContext, useEffect, useState} from "react"
import Text from "@amzn/meridian/text";
import Box from "@amzn/meridian/box";
import {ScrubContext} from "../context/ScrubContext";
import Column from "@amzn/meridian/column";
import TableRowView from "../../common/TableRowView";
import PropTypes from 'prop-types';
import {SCRUB_ROUTE_PATH} from "../../../router/ScrubRouter";
import {ScrubDataStore} from "../DataStore/ScrubDataStore";
import {Loader} from "@amzn/dolphin-uiweb-framework";

const ScrubSelectionModeView = (props) => {
    const {
        state: {
            loading = true,
        }, scrubActions
    } = useContext(ScrubContext)

    const [scrubSummaryForCategory, setScrubSummaryForCategory] = useState([])

    useEffect(() => {
        ScrubDataStore.clearScrubData()
        scrubActions.fetchScrubCount(scrubActions.setScrubCount, scrubActions.errorNotification)
    }, [])

    useEffect(() => {
        if (loading === false) {
            if (ScrubDataStore.getScrubCategoryMap() !== null) {
                setScrubSummaryForCategory(ScrubDataStore.getScrubCategoryMap())
            }
        }
    }, [loading])

    const onSelectQueue = ({queueName}) => {
        scrubActions.setSelectedCategory(queueName)
        scrubActions.fetchScrubExceptionDetails(scrubActions.setScrubExceptionDetails, scrubActions.errorNotification)
        props.history.push(SCRUB_ROUTE_PATH.SCRUB_PACKAGE_LIST)
    }

    if (loading) {
        return <Loader/>
    }

    return (
        <Column height="100%">
            <Box spacingInset="medium">
                <Text type="h300">
                    {props.intl.formatMessage({id: "scrub_task_group_spinner_title"})}
                </Text>
            </Box>
            <TableRowView items={scrubSummaryForCategory.filter(({exceptionCount}) => exceptionCount !== 0)}
                          callback={onSelectQueue}/>
        </Column>
    )
}

ScrubSelectionModeView.propTypes = {
    history: PropTypes.object,
    intl: PropTypes.object,
    location: PropTypes.object
};
export default ScrubSelectionModeView;