import {fetchShipmentSummaryDetails} from "../helper/Left2FixFilterHelper";
import {fetchOpenShipmentDetails} from "../helper/Left2FixHelper";


export const LeftToFixFilterReducerInitialState = {
    loading: false,
    exceptionShipmentsWithDetails:[],
    loadShipmentDetails: false,
    selectedCategory: ""
};

export const LEFT_TO_FIX_FILTER_REDUCERS_ACTION_TYPE = {
    SET_EXCEPTION_SHIPMENT_SUMMARY_DETAILS: "SET_EXCEPTION_SHIPMENT_SUMMARY_DETAILS",
    FETCH_EXCEPTION_SHIPMENT_SUMMARY_DETAILS: "FETCH_EXCEPTION_SHIPMENT_SUMMARY_DETAILS",
    ERROR: "ERROR"
}


export const LeftToFixFilterReducer = (state, action) => {
    switch (action.type) {

        case LEFT_TO_FIX_FILTER_REDUCERS_ACTION_TYPE.FETCH_EXCEPTION_SHIPMENT_SUMMARY_DETAILS: {
            fetchShipmentSummaryDetails(action.data.selectedQueueDetails, action.data.queueToCategories, action.data.selectedCategory, action.dispatcher)
            return {...state, selectedCategory: action.data.selectedCategory, loading: true};
        }
        case LEFT_TO_FIX_FILTER_REDUCERS_ACTION_TYPE.SET_EXCEPTION_SHIPMENT_SUMMARY_DETAILS: {
            let exceptionShipmentsWithDetails = fetchOpenShipmentDetails(action.data.exceptionShipmentSummaryReport)
            return {
                ...state,
                exceptionShipmentsWithDetails,
                loading: false,
                loadShipmentDetails: true
            };
        }
        case LEFT_TO_FIX_FILTER_REDUCERS_ACTION_TYPE.ERROR:
            return {...state, loading: false};
        default:
            return state
    }
}
