import {Route, Switch} from "react-router-dom";
import React from "react";
import {withRouter} from "react-router";
import {injectIntl} from "react-intl";
import UnassignRoute from "../components/unassignroute/UnassignRoute";
import TopNavigation from "../components/common/TopNavigation";
import Column from "@amzn/meridian/column";
import AppContextProvider from "../components/backPressedView/AppContext";

export const ROUTE_ENTITY_ROUTE_PATH = {
    UNASSIGN_ROUTE: "/routeEntity/unassignRoute"
};

export default function RouteEntityRouter() {
    return (
        <div>
            <AppContextProvider>
                <TopNavigation title="operation_problem_solve"/>
            </AppContextProvider>
            <div className="content">
                <Column height="90vh">
                    <Switch>
                        <Route path={ROUTE_ENTITY_ROUTE_PATH.UNASSIGN_ROUTE}
                               component={withRouter(injectIntl(UnassignRoute))}/>
                    </Switch>
                </Column>
            </div>
        </div>
    )

}