import {FeatureManager} from "@amzn/dolphin-web-framework";
import {getDisplayName} from "../handler/TranslationHandler";
import {getShipmentDetail} from "../dataStore/PSExecutionDataStore";
import {getShipmentCategory, getShipmentDestinationDomain} from "../utils/ShipmentUtils";
import {ExceptionAction, ExceptionCategories, RETURN_ELIGIBLE_CATEGORIES, SWA_SHIPMENT_CATEGORY} from "../constants/Constants";

const MIDDLE_MILE = "MIDDLE_MILE"

export const getProblemCategoryItemDescription = (problemCategoryConfig, scannedTrackingIds, intl) => {
  if (isBulkUseCaseNotSupported(problemCategoryConfig, scannedTrackingIds)) {
    return getDisplayName(intl, "bulk_not_supported_msg");
  } else if (isOnlyBulkUseCaseSupported(problemCategoryConfig, scannedTrackingIds)) {
    return getDisplayName(intl,"multiple_required_pallet_label", {packageCount: problemCategoryConfig.minimumScannablesRequired});
  }
};

export const isBulkUseCaseNotSupported = (problemCategoryConfig, scannedTrackingIds) => {
  return problemCategoryConfig.disabledForBulk && scannedTrackingIds.length > 1;
};

export const isOnlyBulkUseCaseSupported = (problemCategoryConfig, scannedTrackingIds) => {
  if (problemCategoryConfig.minimumScannablesRequired === undefined) {
    return false;
  }
  if (scannedTrackingIds.length < problemCategoryConfig.minimumScannablesRequired) {
    return true;
  } else if (isSameParentContainerRequired(problemCategoryConfig)) {
    return !isParentContainerSameForAllScannables();
  } else {
    return false;
  }

};

export const getProblemCategoryTextColour = (problemCategoryConfig, scannedTrackingIds) => {
  if (isProblemCategoryDisabled(problemCategoryConfig, scannedTrackingIds)) {
    return "secondary";
  } else {
    return "primary";
  }
};

export const isProblemCategoryDisabled = (problemCategoryConfig, scannedTrackingIds) => {
  return isBulkUseCaseNotSupported(problemCategoryConfig, scannedTrackingIds)
    || isOnlyBulkUseCaseSupported(problemCategoryConfig, scannedTrackingIds);
};

const isSameParentContainerRequired = (problemCategoryConfig) => {
  return problemCategoryConfig.sameParentContainerRequired !== undefined
      && problemCategoryConfig.sameParentContainerRequired === true;
};

const isParentContainerSameForAllScannables = () => {
  var location = getShipmentDetail()[0].location;
  if (location === undefined || location === null) return false;
  for (var shipment of getShipmentDetail()) {
    if (shipment.location !== location) {
      return false;
    }
  }
  return true;
};

export const isSolveLaterSupported = (problemCategoryConfig) => {
  return problemCategoryConfig.solveLaterSupported !== undefined
      && problemCategoryConfig.solveLaterSupported === true
};

export const getOpenExceptionTargetAction = (problemCategory) => {
  switch (problemCategory) {
    case ExceptionCategories.MISSING:
      return ExceptionAction.NO_OP;
    case ExceptionCategories.CUBE_OUT:
      return ExceptionAction.CUBE_OUT_REPLAN;
    default:
      return ExceptionAction.REPLAN;
  }
};


const SWA_EXCLUDED_ACTIONS = {
  'UNDELIVERABLE': ['UNDELIVERABLE_SUSPECTED_ABUSE_RFFC'],
  'WRONG_PLANNED_STATION': ['MANUAL_RETURN_TO_FC']
};


export const getActionList = (problemCategoryConfig, problemCategory) => {
  const dynamicExceptionShipmentActions = getDynamicExceptionShipmentActions();
  let actionList = [];

  if(problemCategoryConfig[problemCategory].isDynamicAction && dynamicExceptionShipmentActions && dynamicExceptionShipmentActions.length) {
    actionList = getDynamicExceptionShipmentActions();
  } else if(MIDDLE_MILE === getShipmentDestinationDomain(getShipmentDetail()) && RETURN_ELIGIBLE_CATEGORIES.includes(problemCategory) ) {
    actionList = problemCategoryConfig[problemCategory].returnActionList
  } else {
    actionList = problemCategoryConfig[problemCategory].actionList
  }

  if (getShipmentCategory(getShipmentDetail()) === SWA_SHIPMENT_CATEGORY
      && FeatureManager.isFeatureEnabled(FeatureManager.Features.SWA_UNDELIVERABLE_RTO_ACTIONS_DISABLED)) {
    return actionList.filter(a => !(SWA_EXCLUDED_ACTIONS[problemCategory] || []).includes(a));
  } else {
    return actionList;
  }
};

const getDynamicExceptionShipmentActions = () => {
  return (getShipmentDetail() && getShipmentDetail().length
      && getShipmentDetail()[0].exceptionShipmentsDetails && getShipmentDetail()[0].exceptionShipmentsDetails.length
      && getShipmentDetail()[0].exceptionShipmentsDetails[0].actions) ? validateActions(getShipmentDetail()[0].exceptionShipmentsDetails[0].actions) : [];
};

const validateActions = (actions) => {
  let actionsList = [];
  Object.keys(actions).forEach(key => {
    if (actions[key].valid) {
      actionsList.push(key);
    }
  });
  return actionsList;
};

export const isProblemCategoryNotSupported = (problemCategoryConfig) => {
  return problemCategoryConfig === undefined
      || (problemCategoryConfig.actionList === undefined && !problemCategoryConfig.isDynamicAction);
};