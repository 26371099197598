import React from 'react'
import { FormattedMessage } from 'react-intl'

const translate = (id, value = {}) => <FormattedMessage id={id.toLowerCase()} values={{ ...value }} />

export default translate;

export const getDisplayName= (intl, id, values={}) => {
    return intl.formatMessage({id: id.toLowerCase()}, values)

}

export const getCategoriesDisplayName= (intl, id, values={}) => {
    return intl.formatMessage({id: id}, values)

}