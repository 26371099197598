import {callActionApi, processApiExecutionResponse} from "../helper/ActionComponentsHelper";
import {PROBLEM_ACTION_API} from "../constants/Constants";
import {saveExceptionResolutionResult, saveProblemAction} from "../dataStore/PSExecutionDataStore";
import {useHistory} from "react-router";
import {PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS} from "../router/ProblemSolveExecutionRouter";
import {getSuccessScreenDescription} from "../helper/QRExceptionsHelper";

export const QRPickupExceptionsInitialState = {
    QRPickupConfig: [
        {
            stringId: "cube_out_issue_title",
            exceptionCategory: "CUBE_OUT",
            expanded: true
        },
        {
            stringId: "missing_issue_title",
            exceptionCategory: "MISSING_PACKAGE",
            expanded: false
        }
    ],
    isLoading: false
};

export const QR_PICKUP_EXCEPTIONS_REDUCERS_ACTION_TYPE = {
    UPDATE_MULTIPLE_EXCEPTIONS: "UPDATE_MULTIPLE_EXCEPTIONS",
    UPDATE_MULTIPLE_EXCEPTIONS_RESPONSE: "UPDATE_MULTIPLE_EXCEPTIONS_RESPONSE",
    ERROR: "ERROR",
};

export const QRPickupExceptionsReducer = (state, action) => {
    switch (action.type) {
        case QR_PICKUP_EXCEPTIONS_REDUCERS_ACTION_TYPE.UPDATE_MULTIPLE_EXCEPTIONS:
            saveProblemAction("QR_PICKUP_EXCEPTION");
            callActionApi({
                    api: PROBLEM_ACTION_API.CREATE_MULTIPLE_OPEN_EXCEPTIONS,
                    properties: {
                        request: action.data
                    }
                }, action.dispatch,
                QR_PICKUP_EXCEPTIONS_REDUCERS_ACTION_TYPE.UPDATE_MULTIPLE_EXCEPTIONS_RESPONSE,
                QR_PICKUP_EXCEPTIONS_REDUCERS_ACTION_TYPE.ERROR);
            return {...state, isLoading: true};
        case QR_PICKUP_EXCEPTIONS_REDUCERS_ACTION_TYPE.UPDATE_MULTIPLE_EXCEPTIONS_RESPONSE: {
            const {exceptionResolutionResult} = processApiExecutionResponse(action.data)
            saveExceptionResolutionResult(exceptionResolutionResult);
            //eslint-disable-next-line react-hooks/rules-of-hooks
            useHistory().push({
                pathname: PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.SUCCESS_SCREEN,
                state: {
                    properties: {
                        isPrintLabelSupported: false
                    },
                    metaData: {description: getSuccessScreenDescription(action.data.updateExceptionShipmentResponses)}
                }
            });
            return {...state, isLoading: false};
        }
        case QR_PICKUP_EXCEPTIONS_REDUCERS_ACTION_TYPE.ERROR:
            return {...state, isLoading: false};
        default:
            return state;
    }
};