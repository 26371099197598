import {APIEvents} from "../../../constants/AnalyticsEventKeys";
import {MobileAnalyticsHelper} from "@amzn/dolphin-web-framework";
import {getNPSWClient} from "../../base/ClientInterface";

export function UpdateExceptionShipmentsForMultipleProblemCategoryAPI(data, nextAction, errorAction, dispatcher) {
    let startTime = Date.now();
    getNPSWClient().updateExceptionShipmentsForMultipleProblemCategory(data)
        .then(({success, data}) => {
            if (success) {
                dispatcher({
                    type: nextAction,
                    data: data
                });
                MobileAnalyticsHelper.executeAPIAnalytics(APIEvents.UpdateExceptionShipmentsForMultipleProblemCategory, startTime);
            } else {
                dispatcher({
                    type: errorAction
                });
                MobileAnalyticsHelper.executeAPIAnalytics(APIEvents.UpdateExceptionShipmentsForMultipleProblemCategory, startTime, true);
            }
        })

}