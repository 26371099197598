import React from 'react';
import Box from "@amzn/meridian/box";
import Text from "@amzn/meridian/text";
import {FormattedMessage, injectIntl} from "react-intl";
import {TaskView} from "@amzn/dolphin-uiweb-framework";
import {getDisplayName} from "../../handler/TranslationHandler";
import {PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS} from "../../router/ProblemSolveExecutionRouter";
import PropTypes from 'prop-types';
import {ScannedPackagesListView} from "../scannedpackageslist/ScannedPackagesListView";
import {getProblemCategory, getProblemCategoryConfig} from "../../dataStore/PSExecutionDataStore";
import {withRouter} from "react-router-dom";
import {PROBLEM_CATEGORY_STRING_PREFIX} from "../../constants/Constants";
import {isSalColorAuditRequired} from "../../utils/ShipmentUtils";

const TapeDamaged = ({history, intl}) => {
    const problemCategoryConfig = getProblemCategoryConfig()

    const handleTapeDamageClick = (isTapeDamaged) => {
        history.push({
            pathname: isSalColorAuditRequired() ? PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.SAL_COLOR_AUDIT : PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.PROBLEM_ACTION,
            state: {
                properties: {
                    actionList: problemCategoryConfig[getProblemCategory()].actionList
                },
                additionalProperties: {
                    tape_damaged_key: isTapeDamaged
                }
            }
        })
    };
    const getTapeDamagedTasks = () => [{
        title: getDisplayName(intl, "ps_yes"),
        onClick: () => handleTapeDamageClick("YES"),
        titleFontWeight: "Normal"
    }, {
        title: getDisplayName(intl, "ps_no"),
        onClick: () => handleTapeDamageClick("NO"),
        titleFontWeight: "Normal"
    }];
    return (
        <Box spacingInset="none">
            <Box spacingInset="medium medium small medium">
                <Text type="h300"><FormattedMessage id={"ps_tape_damaged_title_text"}/></Text>
            </Box>
            <TaskView tasks={getTapeDamagedTasks()}/>
            <ScannedPackagesListView rightHeader={PROBLEM_CATEGORY_STRING_PREFIX + getProblemCategory()}/>
        </Box>
    );
};

TapeDamaged.propTypes = {
    history: PropTypes.object,
    intl: PropTypes.object
};
export default withRouter(injectIntl(TapeDamaged));