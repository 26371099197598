import React, {useEffect} from 'react';
import {FormattedMessage, injectIntl} from "react-intl";
import Column from "@amzn/meridian/column";
import Alert from "@amzn/meridian/alert";
import Button from "@amzn/meridian/button";
import Text from "@amzn/meridian/text";
import {ROUTE_PATH} from "../../router/routing";
import {getScannedScannableIds} from "../../dataStore/PSExecutionDataStore";
import PropTypes from 'prop-types';
import {withRouter} from "react-router";
import {SoundManager} from "../../audio/SoundManager";
import {
    clearBackPressedEventListener,
    interceptBackButton,
    interceptBackButtonStop,
    setBackPressedEventListener
} from "../backPressedView/BackButton";


const RTODestroySuccessScreen = ({intl, history, location}) => {
    const handleSolveMoreProblems = () => {
        history.push(ROUTE_PATH.HOME)
    };

    const onBackPressListener = () => {
        handleSolveMoreProblems();
    };
    useEffect(() => {
        SoundManager.playSuccessSound();
        setBackPressedEventListener(onBackPressListener);
        interceptBackButton();
        return () => {
            interceptBackButtonStop();
            clearBackPressedEventListener();
        };
    }, []);

    return (
        <Column height="100%" heights={["fill", "fit"]}>
            <Column spacingInset="medium">
                <Alert
                    type="success"
                    size="xlarge"
                    title={intl.formatMessage({id: "success_package_marked_heading"})}>
                    <FormattedMessage id="ps_rto_destroy_singular_success"
                                      values={{trackingId: getPrintTrackingId()}}/>
                </Alert>
                <Column width="90%" alignmentVertical="top" align="left"><Text type="b300"><FormattedMessage
                    id="ps_rto_destroy_text_message_1" values={{trackingId: getPrintTrackingId()}}/></Text></Column>
            </Column>
            <Column width="100%" spacingInset="medium" alignmentVertical="top">
                <Button size="large" onClick={() => handleSolveMoreProblems()}>
                    <FormattedMessage id="solve_more_problems"/>
                </Button>
                <div className="spaceBlockerRTO"/>
            </Column>
        </Column>
    );
};

const getPrintTrackingId = () => {
    const trackingIds = getScannedScannableIds();
    return(trackingIds[0])?trackingIds[0].substr(-4):"";
};

RTODestroySuccessScreen.propTypes = {
    intl: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object
};

export default withRouter(injectIntl(RTODestroySuccessScreen));