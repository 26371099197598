import React, {useReducer} from 'react';
import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";
import Alert from "@amzn/meridian/alert";
import Heading from "@amzn/meridian/heading";
import Card, {CardActionBar, CardHeader} from "@amzn/meridian/card";
import {FormattedMessage, injectIntl} from "react-intl";
import {getDisplayName} from "../../handler/TranslationHandler";
import {GET_PACKAGE_ATTRIBUTE_ACTION_TYPE, GetPackageAttributeReducer} from "../../reducers/GetPackageAttributeReducer";
import InProgress from "../common/InProgress";
import {withRouter} from "react-router-dom";
import {getShipmentDetail} from "../../dataStore/PSExecutionDataStore";

const ProductItems = ({intl}) => {
    const [{packageAttributes, loading}, dispatch] = useReducer(GetPackageAttributeReducer, {loading: false});
    const ASINDetailsMap = getASINDetailsMap();
    const fetchProductImages = () => {
        dispatch({
            type: GET_PACKAGE_ATTRIBUTE_ACTION_TYPE.FETCH_PACKAGE_ATTRIBUTE,
            data: {
                asinCode: getASINCodesOfScannedShipments(ASINDetailsMap)
            },
            dispatcher: dispatch
        })
    };
    if (loading) {
        return (<InProgress/>)
    }
    if (!packageAttributes) {
        return (
            <Column height="100%" heights={["fill", "fit"]}>
                <Column spacingInset="medium">
                    <Alert type="warning" size="xlarge"
                           title={getDisplayName(intl, "ps_product_list_access_warning")}/>
                </Column>
                <Column width="100%" spacingInset="medium" alignmentVertical="top">
                    <Button size="large" onClick={() => fetchProductImages()}>
                        <FormattedMessage id={"ps_get_items"}/>
                    </Button>
                    <div className="spaceBlockerMedium"/>
                </Column>
            </Column>
        )
    } else {
        return (
            <Column height="100%" spacingInset="medium">
                <Text type="h300"><FormattedMessage id={"ps_item_list"}/></Text>
                {Object.keys(packageAttributes.asinToURLMap).map((key) => (
                    <div key={key}>
                        <Card maxWidth="100%">
                            <CardHeader>
                                <Heading level={3} type="h200">
                                    <FormattedMessage id={"ps_asin_code"}/> {key}
                                </Heading>
                            </CardHeader>
                            <img width={270}
                                 src={packageAttributes.asinToURLMap[key]}
                                 alt="ASIN"/>
                            <CardActionBar widths={["fill", "fill"]} alignmentHorizontal="justify">
                                <Text type="b300"><b><FormattedMessage id={"ps_item_title"}/></b> {ASINDetailsMap[key].title}</Text>
                                <Text type="b300"><b><FormattedMessage id={"ps_item_quantity"}/></b> {ASINDetailsMap[key].quantity}</Text>
                            </CardActionBar>
                        </Card>
                        <div className="spaceBlocker"/>
                    </div>
                ))}
            </Column>
        );
    }
};

const getASINDetailsMap = () => {
    const shipmentDetail = getShipmentDetail()[0];
    return (shipmentDetail.packageAdditionalAttributes) ?
        shipmentDetail.packageAdditionalAttributes.asinToPackageDetailMap : "";
};

const getASINCodesOfScannedShipments = (asinDetailsMap) => {
    return Object.keys(asinDetailsMap);
};

export default withRouter(injectIntl(ProductItems));