import React, {useEffect, useReducer, useState} from 'react';
import {useParams, withRouter} from "react-router-dom";
import Select, {SelectOption} from "@amzn/meridian/select";
import Text from "@amzn/meridian/text";
import Column from "@amzn/meridian/column";
import Button from "@amzn/meridian/button";
import {FormattedMessage, injectIntl} from "react-intl";
import {
    GET_STATION_LIST_ACTION_TYPE,
    GetStationListInitialState,
    GetStationListReducer
} from "../../reducers/GetStationListReducer";
import {ScannedPackagesListView} from "../scannedpackageslist/ScannedPackagesListView";
import InProgress from "../common/InProgress";
import {getProblemCategory} from "../../dataStore/PSExecutionDataStore";
import {PROBLEM_CATEGORY_STRING_PREFIX} from "../../constants/Constants";
import {getDisplayName} from "../../handler/TranslationHandler";
import PropTypes from 'prop-types';
import NotificationMessage from "../common/NotificationMessage";

const initialState = {
    targetStation: "",
    newStation: "",
    searchStation: "",
};

const SelectStation = ({intl, history}) => {
    const {view} = useParams();
    const [state, setState] = useState(initialState);
    const [{stationList, loading, notificationType, notificationMessageId}, dispatcher] = useReducer(GetStationListReducer, GetStationListInitialState);

    useEffect(() => {
        dispatcher({
            type: GET_STATION_LIST_ACTION_TYPE.FETCH_STATION_LIST,
            data: {},
            dispatcher: dispatcher
        })
    }, [view]);

    const matchedStationOptions = stationList.filter(station => !state.searchStation ||
        station.code.toLowerCase().startsWith(state.searchStation.toLowerCase())
    );

    const changeStation = () => {
        dispatcher({
            type: GET_STATION_LIST_ACTION_TYPE.SET_NEW_STATION,
            data: state.targetStation,
            dispatch: dispatcher
        })
    };

    return (
        <Column height="100%" heights={["fill", "fit"]}>
            <Column spacingInset="medium">
                {loading && <InProgress/>}
                {notificationType &&
                <NotificationMessage dispatch={dispatcher} actionType={GET_STATION_LIST_ACTION_TYPE.CLOSE_NOTIFICATION}
                                     type={notificationType} messageId={notificationMessageId} messageValues={{"TARGET_STATION": state.targetStation}}/>}
                <Text type="h300"><FormattedMessage id={"what_would_you_like_to_do"}/></Text>
                <Select value={state.targetStation}
                        onChange={(newStation) => setState({...state, targetStation: newStation})}
                        searchQuery={state.searchStation}
                        onSearch={(newStation) => setState({...state, searchStation: newStation})}
                        placeholder={getDisplayName(intl, "search_station_hint")}>
                    {matchedStationOptions.map(station => (
                        <SelectOption
                            label={station.code}
                            value={station.code}
                            key={station.code}/>
                    ))}
                    {!matchedStationOptions.length ? (<Column
                        alignmentVertical="center"
                        spacing="small"
                        spacingInset="xlarge">
                        <Text alignment="center"><FormattedMessage id="no_results"/></Text>
                    </Column>) : null}
                </Select>
            </Column>
            <Column alignmentVertical="bottom" width="100%" spacingInset="medium medium xlarge medium">
                {state.targetStation !== "" &&
                <Button onClick={() => changeStation()}><FormattedMessage id="continue_solving"/></Button>}
                <Button type="secondary" onClick={() => history.goBack()}><FormattedMessage id="cancel_text"/></Button>
                <div className="spaceBlockerMedium"/>
            </Column>
            <ScannedPackagesListView rightHeader={PROBLEM_CATEGORY_STRING_PREFIX + getProblemCategory()}/>
        </Column>);
};
SelectStation.propTypes = {
    history: PropTypes.object,
    intl: PropTypes.object,
};
export default withRouter(injectIntl(SelectStation));