import React, {useContext} from "react"
import {ScrubContext} from "../context/ScrubContext";
import ScanBox from "../../scanbox/GenericScanBox";

const ScrubScanner = () => {
    const { scrubActions} = useContext(ScrubContext)

    const handleSubmit = (trackingId) => {
        scrubActions.scrubPackage(trackingId, scrubActions.scrubPackageResponse,
            scrubActions.errorNotification)
    }

    return (
        <ScanBox onScan={handleSubmit} titleId={"scan_package"} />
    )
}

export default ScrubScanner;