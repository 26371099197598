import {fetchproblemActionConfig} from "../handler/ProblemActionConfigHandler";
import {
    getProblemAction,
    getProblemActionNextComponentDetails, getRetryCounter, getScannedScannableIds,
    saveExceptionResolutionResult,
    saveProblemAction,
    saveProblemActionConfig,
    saveProblemActionNextComponentDetails, saveRetryCounter
} from "../dataStore/PSExecutionDataStore";
import {
    callActionApi,
    processApiExecutionResponse
} from "../helper/ActionComponentsHelper";
import {NOTIFICATION_TYPE} from "../constants/Constants";
import {useHistory} from "react-router";
import {PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS} from "../router/ProblemSolveExecutionRouter";
import {Logger} from "@amzn/dolphin-web-framework";
import {NETWORK_MISSORT_REPLAN} from "../constants/Constants";

export const ProblemActionReducerInitialState = {
    fetchingData: false,
    loadNextComponent: false,
    nextComponentDetails:{},
    exceptionResolutionResult: {},
    notificationMessageId: "",
    counter: 0,
    warningText: "",
    networkMissortAction: false,
}

export const ProblemActionReducerActionType = {
    GET_PROBLEM_ACTION_CONFIG: "GET_PROBLEM_ACTION_CONFIG",
    SET_PROBLEM_ACTION_CONFIG: "SET_PROBLEM_ACTION_CONFIG",
    EXECUTE_PROBLEM_ACTION_API: "EXECUTE_PROBLEM_ACTION_API",
    SET_PROBLEM_ACTION_API_RESULT: "SET_PROBLEM_ACTION_API_RESULT",
    SET_PROBLEM_ACTION_API_RESULT_ERROR: "SET_PROBLEM_ACTION_API_RESULT_ERROR",
    CLOSE_NOTIFICATION: "CLOSE_NOTIFICATION",
    SHOW_ERROR_FETCHING_STAGING_AREA: "SHOW_ERROR_FETCHING_STAGING_AREA",
    ERROR: "ERROR",
    PRINTER_ERROR: "PRINTER_ERROR"
};

export const ProblemActionReducer = (state, action) => {
    switch (action.type) {
        case ProblemActionReducerActionType.GET_PROBLEM_ACTION_CONFIG: {
            Logger.log.info("Getting problem action config: ", action);
            saveProblemAction(action.data)
            fetchproblemActionConfig(action.data, action.dispatch, ProblemActionReducerActionType.SET_PROBLEM_ACTION_CONFIG,
                ProblemActionReducerActionType.ERROR)
            return {
                ...state,
                fetchingData: true
            };
        }

        case ProblemActionReducerActionType.SET_PROBLEM_ACTION_CONFIG: {
            Logger.log.info("Setting Problem Action Config: ", action);
            let networkMissortAction = (getProblemAction() === NETWORK_MISSORT_REPLAN)
            saveProblemActionConfig(action.data)
            saveProblemActionNextComponentDetails(action.data[0])
            return {
                ...state,
                loadNextComponent: true,
                fetchingData: false,
                networkMissortAction: networkMissortAction,
                nextComponentDetails: action.data[0]
            };

        }

        case ProblemActionReducerActionType.EXECUTE_PROBLEM_ACTION_API: {
            Logger.log.info("Executing problem action api: ", action);
            callActionApi(action.data, action.dispatch, ProblemActionReducerActionType.SET_PROBLEM_ACTION_API_RESULT,
                ProblemActionReducerActionType.SET_PROBLEM_ACTION_API_RESULT_ERROR,
                ProblemActionReducerActionType.PRINTER_ERROR);
            return {
                ...state,
                loadNextComponent: false,
                fetchingData: true,
                nextComponentDetails: getProblemActionNextComponentDetails()
            };

        }

        case ProblemActionReducerActionType.SET_PROBLEM_ACTION_API_RESULT: {
            Logger.log.info("Setting Problem Action api result: ", action);
            const {exceptionResolutionResult, loadNextComponent} = processApiExecutionResponse(action.data)
            saveExceptionResolutionResult(exceptionResolutionResult)
            return {
                ...state,
                loadNextComponent: loadNextComponent,
                fetchingData: false,
                exceptionResolutionResult: exceptionResolutionResult
            };
        }

        case ProblemActionReducerActionType.SET_PROBLEM_ACTION_API_RESULT_ERROR: {
            let trackingId = getScannedScannableIds()[0]
            Logger.log.info("Operator got error on trying to resolve exception for TID: ", trackingId);
            let retryCounter = getRetryCounter()
            retryCounter += 1;
            saveRetryCounter(retryCounter)
            return {
                ...state,
                loadNextComponent: false,
                fetchingData: false,
                counter: retryCounter,
                warningText: "retry_limit_exceeded",
                notificationType: NOTIFICATION_TYPE.ALERT,
                notificationMessageId: "internal_error",
                exceptionResolutionResult: {}
            };

        }
        case ProblemActionReducerActionType.ERROR:
            return {
                ...state,
                fetchingData: false,
                notificationType: NOTIFICATION_TYPE.ALERT,
                notificationMessageId:"internal_error"
            }

        case ProblemActionReducerActionType.PRINTER_ERROR:
            //eslint-disable-next-line react-hooks/rules-of-hooks
            useHistory().push(({
                pathname: PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.CONNECT_PRINTER
            }));

            return {
                ...state,
                fetchingData: false,
                notificationType: NOTIFICATION_TYPE.ALERT,
                notificationMessageId: "printer_not_connect"
            };

        case ProblemActionReducerActionType.CLOSE_NOTIFICATION: {
            return {...state, notificationType: null};
        }

        case ProblemActionReducerActionType.SHOW_ERROR_FETCHING_STAGING_AREA: {
            let trackingId = getScannedScannableIds()[0]
            return {
                ...state,
                fetchingData: false,
                notificationType: NOTIFICATION_TYPE.ALERT,
                notificationMessageId: "error_fetching_staging_area",
                notificationMessageValues: {trackingId: trackingId.substr(trackingId.length-4)}
            }
        }
        default:
            return state;
    }
}