import React from "react";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Box from "@amzn/meridian/box";
import Alert from "@amzn/meridian/alert";
import {FormattedMessage} from "react-intl";
import PropTypes from 'prop-types';

const WarningScreen = ({text}) => {

    return (
        <Box spacingInset="xlarge" backgroundColor="#ffffff90" className="appLoader" height="90%" width="100%" heights="fill"
             alignmentHorizontal="center">
            <Row alignmentHorizontal="center" alignmentVertical="bottom" height="40%" >
            </Row>
            {text &&
                <Row alignmentHorizontal="center" alignmentVertical="top" height="60%">
                    <Alert type={"warning"}><Text alignment="center" type="b200"><FormattedMessage id={text} /></Text></Alert>
                </Row>}
        </Box>
    )
}

WarningScreen.propTypes = {
    text: PropTypes.string,
};
export default WarningScreen