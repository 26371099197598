import React from "react";
import Box from "@amzn/meridian/box";
import Text from "@amzn/meridian/text";
import {FormattedMessage} from "react-intl";
import {TaskView} from "@amzn/dolphin-uiweb-framework";
import {getDisplayName} from "../../handler/TranslationHandler";
import {DRIVER_ENTITY_ROUTE_PATH} from "../../router/DriverEntityRouter";
import {PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS} from "../../router/ProblemSolveExecutionRouter";
import {ENTITY_TYPES} from "../../constants/Constants";
import {DRIVER_NO_SHOW_REDUCER_ACTION_TYPE} from "../../reducers/DriverNoShowReducer";
import PropTypes from 'prop-types';

const DriverNoShow = ({intl, history, location}) => {
    const onClickReplan = () => {
        history.push({
            pathname: DRIVER_ENTITY_ROUTE_PATH.DRIVER_NO_SHOW_RESULT_SCREEN,
            state: {
                metaData: {
                    actionType: DRIVER_NO_SHOW_REDUCER_ACTION_TYPE.DRIVER_NO_SHOW_REPLAN,
                    currentTransporterId: location.state.transporter.transporterId
                }
            }
        })
    };
    const onClickReassign = () => {
        history.push({
            pathname: PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.COMPONENT_SELECT_DRIVER,
            state: {
                entity: ENTITY_TYPES.DRIVER,
                nextScreen: DRIVER_ENTITY_ROUTE_PATH.DRIVER_NO_SHOW_RESULT_SCREEN,
                metaData: {
                    actionType: DRIVER_NO_SHOW_REDUCER_ACTION_TYPE.DRIVER_NO_SHOW_REASSIGN,
                    currentTransporterId: location.state.transporter.transporterId
                }
            }
        })
    };
    const getDriverNoShowTasks = () => {
        return [
            {
                title: getDisplayName(intl, "action_replan"),
                onClick: () => onClickReplan()
            },
            {
                title: getDisplayName(intl, "action_reassign_to_another_driver"),
                onClick: () => onClickReassign()
            }
        ]
    };

    return (
        <Box>
            <Box spacingInset="medium">
                <Text type="h300"><FormattedMessage id={"whats_the_problem"}/></Text>
            </Box>
            <TaskView tasks={getDriverNoShowTasks()}/>
        </Box>
    )
};
DriverNoShow.propTypes = {
    intl: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object
};
export default DriverNoShow;