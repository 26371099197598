import React, {useReducer, useState, useEffect} from 'react';
import {FormattedMessage} from "react-intl";
import Column from "@amzn/meridian/column";
import Button from "@amzn/meridian/button";
import {ROUTE_PATH} from "../router/routing";
import ScanBox from "../components/scanbox/GenericScanBox";
import InProgress from "../components/common/InProgress";
import NotificationMessage from "../components/common/NotificationMessage";
import {ProblemActionReducerActionType} from "../reducers/ProblemActionReducer";
import {getLabelType} from "./LabelTypeConfig";
import Text from "@amzn/meridian/text";
import Box from "@amzn/meridian/box";
import {
    REPRINT_LABEL_REDUCERS_ACTION_TYPE,
    ReprintLabelInitialState,
    ReprintLabelReducer
} from "../reducers/ReprintLabelReducer";
import PropTypes from 'prop-types';
import Printer from "./Printer";

const ReprintLabels = ({history}) => {
    const [scannedTrackingId, setScannedTrackingId] = useState("");
    const [{isLoading, label, notificationType, notificationMessageId, isPrintSuccess}, dispatch] = useReducer(ReprintLabelReducer, ReprintLabelInitialState);

    const handleOnScan = (trackingId) => {
        setScannedTrackingId(trackingId);
        dispatch({
            type: REPRINT_LABEL_REDUCERS_ACTION_TYPE.FETCH_PACKAGE_LABEL,
            data: {
                scannableId: trackingId,
                labelType: getLabelType("SLAM_LABEL")
            },
            dispatcher: dispatch
        });
    };

    useEffect(()=>{
        return () => {
            Printer.closePrinter();
        };
    },[]);

    if(isPrintSuccess) {
        dispatch({
            type: REPRINT_LABEL_REDUCERS_ACTION_TYPE.EXECUTE_PROBLEM_ACTION_API,
            data: {
                scannableId: scannedTrackingId
            },
            dispatcher: dispatch
        })
    }

    return (
        <Column height="100%" heights={["fill", "fit"]}>
            <Column spacingInset="none">
                {isLoading && <InProgress/>}
                {notificationType &&
                <NotificationMessage dispatch={dispatch} actionType={ProblemActionReducerActionType.CLOSE_NOTIFICATION}
                                     type={notificationType} messageId={notificationMessageId}/>}
                <ScanBox titleId="ps_reprint_more_labels" onScan={(input) => handleOnScan(input)}/>
                <Box spacingInset="none medium small medium">
                    {!isLoading && label &&
                    <Text type="h200">
                        <FormattedMessage id="ps_damaged_label_singular_success"
                                          values={{trackingId: scannedTrackingId.substr(-5)}}/>
                    </Text>
                    }
                </Box>
            </Column>
            <Column width="100%" spacingInset="medium" alignmentVertical="top">
                <Button size="large" onClick={() => history.push(ROUTE_PATH.HOME)}>
                    <FormattedMessage id="ps_finish_printing"/>
                </Button>
                <div className="spaceBlockerMedium"/>
            </Column>
        </Column>
    );
};
ReprintLabels.propTypes = {
    history: PropTypes.object
};
export default ReprintLabels;