import React, {useEffect, useReducer, useState} from "react"
import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";
import Button from "@amzn/meridian/button";
import {ProgressBarView} from "@amzn/dolphin-uiweb-framework";
import {PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS} from "../../../router/ProblemSolveExecutionRouter";
import {isNotEmptyObject} from "./Utils";
import {ScrubResponseCode} from "../constants/constants";
import PropTypes from 'prop-types';
import {SCRUB_ROUTE_PATH} from "../../../router/ScrubRouter";
import {ScrubDataStore} from "../DataStore/ScrubDataStore";
import Alert from "@amzn/meridian/alert";
import {
    clearPSExecutionData,
    saveProblemCategory,
    saveProblemCategoryConfig,
    saveScannedScannableIds,
    saveShimentDetail
} from "../../../dataStore/PSExecutionDataStore";
import {getActionList} from "../../../helper/ProblemCategoryHelper";
import {
    SCAN_SHIPMENTS_REDUCERS_ACTION_TYPE,
    ScanShipmentReducer,
    ScanShipmentsnIntialState
} from "../../../reducers/ScanShipmentReducer";
import InProgress from "../../common/InProgress";
import NotificationMessage from "../../common/NotificationMessage";
import {SCAN_TYPE} from "../../../constants/Constants";

const ScrubSolveOtherExceptionView = ({intl, history, location}) => {
    const [{
        loading, notificationType, notificationMessageId, notificationMessageValues, shipmentDetail,
        problemCategoryConfig}, dispatch] = useReducer(ScanShipmentReducer, ScanShipmentsnIntialState);
    const [scrubPackageResponse, setScrubPackageResponse] = useState({})
    const [resolvedPackages, totalPackages, messageId] = [location.state.resolvedPackages,
        location.state.totalPackages, location.state.messageId]
    const DAMAGED_PACKAGE = "DAMAGED_PACKAGE"
    const WRONG_STATION_SAME_CITY = "WRONG_STATION_SAME_CITY"


    useEffect(() => {
        if (ScrubDataStore.getScannedPackageDetails() !== null) {
            dispatch({
                type: SCAN_SHIPMENTS_REDUCERS_ACTION_TYPE.FETCH_SHIPMENT_DETAILS,
                data: [ScrubDataStore.getScannedPackageDetails().scannableId],
                dispatcher: dispatch,
                scanType: SCAN_TYPE.PACKAGE
            });
            setScrubPackageResponse(ScrubDataStore.getScannedPackageDetails())
        }
    }, []);

    const solveLaterExecution = () => {
        ScrubDataStore.setScannedPackageDetails(null)
        history.push(SCRUB_ROUTE_PATH.SCRUB_CONTINUE)
    }

    const solveNowExecution = () => {
        if(isNotEmptyObject(scrubPackageResponse)) {
            const {userOutput = {}} = scrubPackageResponse;
            if(isNotEmptyObject(userOutput)) {
                const {responseCode} = userOutput
                let problemType;
                if(responseCode === ScrubResponseCode.AT_WRONG_STATION) {
                    problemType = WRONG_STATION_SAME_CITY
                } else if (responseCode === ScrubResponseCode.DAMAGED
                    || responseCode === ScrubResponseCode.OPEN_EXCEPTION ){
                    problemType = DAMAGED_PACKAGE
                }
                clearPSExecutionData();
                saveShimentDetail(shipmentDetail);
                saveScannedScannableIds(scrubPackageResponse.scannableId);
                saveProblemCategoryConfig(problemCategoryConfig);
                saveProblemCategory(problemType);
                history.push({
                    pathname: PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.PROBLEM_ACTION,
                    state: {
                        properties: {
                            actionList: getActionList(problemCategoryConfig, problemType)
                        },
                        nextScreenAfterSuccess: SCRUB_ROUTE_PATH.SCRUB
                    }
                })
            }
        }

    };

    return (
        <div className="content">
            <Column height="100%">
                <ProgressBarView currentCount={resolvedPackages} totalCount={totalPackages} primaryColor={"#00A8E1"}/>
                {loading && <InProgress/>}
                {notificationType &&
                <NotificationMessage dispatch={dispatch}
                                     actionType={SCAN_SHIPMENTS_REDUCERS_ACTION_TYPE.CLOSE_NOTIFICATION}
                                     type={notificationType} messageId={notificationMessageId}
                                     messageValues={notificationMessageValues}/>}
                <Column height="100%" heights="fill" spacingInset="medium">
                    <Column alignmentHorizontal="center">
                        <Alert type="warning" size="xlarge"/>
                        <Text alignment="center" type="b300">
                            {intl.formatMessage({id: messageId})}<br/>
                            {intl.formatMessage({id: "ps_what_do_want_to_do"})}
                        </Text>
                    </Column>
                    <Column width="100%" alignmentVertical={"bottom"}>
                        <Button size="large" type="secondary" onClick={solveNowExecution}>
                            {intl.formatMessage({id: "ps_solve_now"})}
                        </Button>
                        <Button size="large" type="primary" onClick={solveLaterExecution}>
                            {intl.formatMessage({id: "ps_solve_later"})}
                        </Button>
                        <div className="spaceBlockerMedium"/>
                    </Column>
                </Column>
            </Column>
        </div>
    )
};

ScrubSolveOtherExceptionView.propTypes = {
    history: PropTypes.object,
    intl: PropTypes.object,
    location: PropTypes.object
};

export  default ScrubSolveOtherExceptionView