import {APIEvents} from "../../../constants/AnalyticsEventKeys";
import {MobileAnalyticsHelper} from "@amzn/dolphin-web-framework";
import {getNPSWClient} from "../../base/ClientInterface";

export function getFeatureValue(data, dispatcher, nextAction, errorAction) {
    let startTime = Date.now();
    getNPSWClient().getFeatureValue(data)
        .then(({success, data}) => {
            if (success) {
                dispatcher({
                    type: nextAction,
                    data: data,
                    dispatcher: dispatcher
                });
                MobileAnalyticsHelper.executeAPIAnalytics(APIEvents.GetFeatureValue, startTime);
            } else {
                dispatcher({
                    type: errorAction
                });
                MobileAnalyticsHelper.executeAPIAnalytics(APIEvents.GetFeatureValue, startTime, true);
            }
        });
}