import {Logger, NativeMeshInteractor} from "@amzn/dolphin-web-framework";

const Printer = {
    printLabel: async (label) => {
        try {
            const zebraConnectionStatus = await NativeMeshInteractor.getZebraStatus();
            if (zebraConnectionStatus === "true") {
                const response = await NativeMeshInteractor.printZebra(label);
                return (!response);
            }
            return null;
        } catch (e) {
            Logger.log.error("zebra printing failed", e);
            return null;
        }
    },
    getPrinterResolution: async () => {
        return NativeMeshInteractor.getZebraResolution();
    },
    getConnectionStatus: async () => {
        return NativeMeshInteractor.getZebraStatus();
    },
    connectPrinter: async (mac) => {
        try {
            const response = await NativeMeshInteractor.connectZebra(mac);
            return (!response);
        } catch(e) {
            return false;
        }

    },
    closePrinter: () => {
        try {
            NativeMeshInteractor.closeConnectionZebra();
        } catch (e) {
        }
    }
};

export default Printer;