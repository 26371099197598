export const PS_STATION_SWEEP = "STATION_SWEEP"
const SCRUB_HFCR_CANCELLED = "SCRUB_HFCR_CANCELLED"
const SCRUB_HFCR_REDIRECT = "SCRUB_HFCR_REDIRECT"
const SCRUB_HFCR_RESCHEDULE = "SCRUB_HFCR_RESCHEDULE"

export const OPEN = "OPEN"
export const RESOLVED = "RESOLVED"

export const ScrubCategories = {
    PS_STATION_SWEEP: [PS_STATION_SWEEP],
    PS_CUSTOMER_REQUEST: [SCRUB_HFCR_CANCELLED, SCRUB_HFCR_REDIRECT, SCRUB_HFCR_RESCHEDULE],
    ALL: [PS_STATION_SWEEP, SCRUB_HFCR_CANCELLED, SCRUB_HFCR_REDIRECT, SCRUB_HFCR_RESCHEDULE],
}

export const ResponseStatus = {
    SUCCESS: "SUCCESS",
    ERROR: "ERROR",
    WARN: "WARN",
    WARNING: "WARNING"
}

export const ScrubResponseCode =  {
    SUCCESS: "SUCCESS",
    AT_WRONG_STATION: "AT_WRONG_STATION",
    DAMAGED: "DAMAGED",
    INVALID_TRACKING_ID: "INVALID_TRACKING_ID",
    DEPENDENCY_FAILURE: "DEPENDENCY_FAILURE",
    LABEL_PRINT_REQUIRED: "LABEL_PRINT_REQUIRED",
    OPEN_EXCEPTION: "OPEN_EXCEPTION",
    NO_EXCEPTION_FOUND: "NO_EXCEPTION_FOUND"
}