import React, {useReducer} from "react";
import "@amzn/meridian-tokens/component/card.css"
import Box from "@amzn/meridian/box";
import PropTypes from 'prop-types';
import TopNavigation from "../common/TopNavigation";
import {DetailsCardView} from "@amzn/dolphin-uiweb-framework";
import {getDataForDetailsCardView} from "../../helper/Left2FixHelper";
import {
    LEFT_TO_FIX_SHIPMENT_DETAIL_REDUCER_ACTION_TYPE,
    LeftToFixShipmentDetailInitialState,
    LeftToFixShipmentDetailReducer
} from "../../reducers/LeftToFixShipmentDetailReducer";
import {
    clearPSExecutionData,
    saveProblemCategory,
    saveProblemCategoryConfig,
    saveRetryCounter,
    saveScannedScannableIds,
    saveShimentDetail
} from "../../dataStore/PSExecutionDataStore";
import {
    getExceptionShipmentsWithDetails,
    getSdViewHeadingKey, saveActionList
} from "../../dataStore/LocationStateDataStore";
import {PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS} from "../../router/ProblemSolveExecutionRouter";
import InProgress from "../common/InProgress";
import NotificationMessage from "../common/NotificationMessage";
import {TAPE_DAMAGED_CATEGORIES} from "../../constants/Constants";
import {getActionList} from "../../helper/ProblemCategoryHelper";
import Column from "@amzn/meridian/column";
import AppContextProvider from "../backPressedView/AppContext";
import {Logger} from "@amzn/dolphin-web-framework";
import {isSalColorAuditRequired} from "../../utils/ShipmentUtils";

const Left2FixShipmentDetailsView = ({history, location, intl}) => {
    const [{loading, scannableId, notificationType, notificationMessageId, problemCategoryConfig, shipments,
        loadNextComponentSucess}, dispatch] = useReducer(LeftToFixShipmentDetailReducer, LeftToFixShipmentDetailInitialState);

    if(!location.state){
        location.state = {
            exceptionShipmentsWithDetails: getExceptionShipmentsWithDetails(),
            title: getSdViewHeadingKey()
        }
    }

    const {exceptionShipmentsWithDetails, title} = location.state;

    if(loadNextComponentSucess) {
        loadNextCompnent(history, problemCategoryConfig, shipments, scannableId, dispatch)
        return (<div/>)
    }

    const onShipmentSelect = (shipment) => {
        dispatch({
            type: LEFT_TO_FIX_SHIPMENT_DETAIL_REDUCER_ACTION_TYPE.FETCH_SHIPMENT_DETAILS,
            data: [shipment.scannableId],
            dispatcher: dispatch
        })
    }

    return (
        <Box>
            <AppContextProvider>
                <TopNavigation title={title}/>
                <div className="content">
                    <Column height="90vh">
                        {loading &&
                        <InProgress/>
                        }
                        {notificationType &&
                        <NotificationMessage dispatch={dispatch}
                                             actionType={LEFT_TO_FIX_SHIPMENT_DETAIL_REDUCER_ACTION_TYPE.CLOSE_NOTIFICATION}
                                             type={notificationType} messageId={notificationMessageId}/>}
                        {exceptionShipmentsWithDetails.map((shipment, index) => (
                            <div onClick={() => onShipmentSelect(shipment)} key={index}>
                                <Box spacingInset={"medium medium none medium"}>
                                    <DetailsCardView snapshots={getDataForDetailsCardView(shipment, intl)}
                                                     display="Column"/>
                                </Box>
                            </div>
                        ))
                        }
                        <br/><br/>
                    </Column>
                </div>
            </AppContextProvider>
        </Box>
    )
};

const loadNextCompnent = (history, problemCategoryConfig, shipments, scannableId, dispatch) => {
    let problemCategory = "";
    if(shipments[0].exceptionShipmentsDetails &&
        shipments[0].exceptionShipmentsDetails[0].exceptionShipment) {
        problemCategory = shipments[0].exceptionShipmentsDetails[0].exceptionShipment.category
    }
    Logger.log.info("Operator opening resolution for resolving issue for tracking id :", scannableId);
    clearPSExecutionData()

    saveScannedScannableIds(scannableId)
    saveProblemCategoryConfig(problemCategoryConfig)
    saveProblemCategory(problemCategory);
    saveShimentDetail(shipments)
    saveRetryCounter(0);
    let actionList = getActionList(problemCategoryConfig, problemCategory)
    saveActionList(actionList)

    if(problemCategoryConfig[problemCategory] === undefined) {
        dispatch({
            type: LEFT_TO_FIX_SHIPMENT_DETAIL_REDUCER_ACTION_TYPE.SHOW_INVALID_CATEGORY_NOTIFICATION,
            dispatcher: dispatch
        })
        return
    }
    if (TAPE_DAMAGED_CATEGORIES.includes(problemCategory)) {
        history.push(PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.COMPONENT_TAPE_DAMAGED);
    } else {
        history.push({
            pathname: isSalColorAuditRequired() ? PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.SAL_COLOR_AUDIT : PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.PROBLEM_ACTION,
            state: {
                properties: {
                    actionList: actionList
                }
            }
        });
    }
}

Left2FixShipmentDetailsView.propTypes = {
    location: PropTypes.object,
    intl: PropTypes.object,
    history: PropTypes.object
};
export default Left2FixShipmentDetailsView