import React, {useReducer, useState} from 'react';
import Box from "@amzn/meridian/box";
import TopNavigation from "../common/TopNavigation";
import PropTypes from 'prop-types';
import fetchFilterValueToCount, {
    getFilterDisplayName,
    getShipmentDetailViewHeading,
    getFilteredShipments,
    isFilterValueValidProblemCategory
} from "../../helper/Left2FixFilterHelper";
import Select, {SelectOption} from "@amzn/meridian/select";
import {LeftToFixFiterKey} from "../../constants/Left2FixFilterKey"
import Table, {TableCell, TableRow} from "@amzn/meridian/table";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import {FormattedMessage, injectIntl} from "react-intl";
import InProgress from "../common/InProgress";
import {
    LEFT_TO_FIX_FILTER_REDUCERS_ACTION_TYPE,
    LeftToFixFilterReducer,
    LeftToFixFilterReducerInitialState
} from "../../reducers/LeftToFixFilterReducer";
import {LEFT_TO_FIX_ROUTE_PATH} from "../../router/Left2FixRouter";
import Column from "@amzn/meridian/column";
import chevronRightSmallTokens from "@amzn/meridian-tokens/base/icon/chevron-right-small";
import Icon from "@amzn/meridian/icon";
import AppContextProvider from "../backPressedView/AppContext";
import {
    getExceptionQueueToCount,
    getExceptionShipmentSummaryReport,
    getExceptionShipmentsWithDetails, getLeftToFixFilters,
    getOpenExceptionShipmentsWithDetails,
    getQueueToCategories,
    getSdViewHeadingKey,
    getSelectedQueueDetails,
    saveExceptionShipmentsWithDetails,
    saveSdViewHeadingKey
} from "../../dataStore/LocationStateDataStore";


const Left2FixFilterView = (props) => {
    if(!props.location.state){
        props.location.state = {
            selectedQueueDetails: getSelectedQueueDetails(),
            queueToCategories: getQueueToCategories(),
            exceptionQueueToCount: getExceptionQueueToCount(),
            exceptionShipmentSummaryReport: getExceptionShipmentSummaryReport(),
            openExceptionShipmentsWithDetails: getOpenExceptionShipmentsWithDetails(),
            leftToFixFilters: getLeftToFixFilters()
        }
    }
    const {selectedQueueDetails, queueToCategories, openExceptionShipmentsWithDetails,
        exceptionShipmentSummaryReport, leftToFixFilters} = props.location.state

    const [filterKey, setFilterKey] = useState(LeftToFixFiterKey.ISSUE_TYPE)

    const [{exceptionShipmentsWithDetails, loading, loadShipmentDetails, selectedCategory}, dispatch] = useReducer(LeftToFixFilterReducer, LeftToFixFilterReducerInitialState);

    if (loading) {
        return (
            <InProgress/>
        )
    }

    if(loadShipmentDetails) {
        loadShipmentDetailsView(exceptionShipmentsWithDetails, props,
            getShipmentDetailViewHeading(selectedCategory, LeftToFixFiterKey.ISSUE_TYPE, selectedQueueDetails) )
        return <div/>
    }

    let filterValueToCount = fetchFilterValueToCount(selectedQueueDetails, queueToCategories, openExceptionShipmentsWithDetails, exceptionShipmentSummaryReport, filterKey)

    const onSelectedFilter = (filterValue, filterKey) => {
        if(isFilterValueValidProblemCategory(filterValue) ||  openExceptionShipmentsWithDetails.length === 0) {
            dispatch({
                 type: LEFT_TO_FIX_FILTER_REDUCERS_ACTION_TYPE.FETCH_EXCEPTION_SHIPMENT_SUMMARY_DETAILS,
                 data: {
                     selectedQueueDetails: selectedQueueDetails,
                     queueToCategories: queueToCategories,
                     selectedCategory: filterValue
                 },
                 dispatcher: dispatch
            })
        } else {
            const sdViewHeadingKey = getShipmentDetailViewHeading(filterValue, filterKey, selectedQueueDetails)
            loadShipmentDetailsView(getFilteredShipments(openExceptionShipmentsWithDetails, filterValue, filterKey), props, sdViewHeadingKey)
            return <div/>
        }
    };

    return (
        <Box spacingInset="none">
            <AppContextProvider>
                <TopNavigation title={`${selectedQueueDetails.queueName.toLowerCase()}_toolbar_txt`}/>
            </AppContextProvider>
            <div className="content">
                <Column height="90vh">
                    <Box spacingInset="medium medium medium medium">
                        <Box spacingInset="none medium small none">
                            <Text type="h200"><FormattedMessage id="ltf_group_spinner_title"/></Text>
                        </Box>
                        <Select size="medium" value={filterKey} placeholder={filterKey} onChange={setFilterKey}>
                            {leftToFixFilters.map((value) => (
                                <SelectOption label={props.intl.formatMessage({id: value})} value={value} key={value}/>
                            ))}
                        </Select>
                    </Box>
                    <Table showDividers={true}>

                        {filterValueToCount.map(([key, value], index) =>
                            (
                                <TableRow key={index} onClick={() => onSelectedFilter(key, filterKey)}>
                                    <TableCell>
                                        <Row alignmentHorizontal="justify">
                                                <Text type="b300">
                                                    {getFilterDisplayName(key, filterKey, props.intl)}
                                                </Text>
                                                <Text type="h100" alignment="right">
                                                    <u>{value}</u>
                                                    <Icon tokens={chevronRightSmallTokens}/>
                                                </Text>
                                        </Row>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </Table>
                    <br/><br/>
                </Column>
            </div>
        </Box>
    )
}

const loadShipmentDetailsView = (exceptionShipmentsWithDetails, props, sdViewHeadingKey) => {
    saveExceptionShipmentsWithDetails(exceptionShipmentsWithDetails)
    saveSdViewHeadingKey(sdViewHeadingKey)
    props.history.push({
        pathname: LEFT_TO_FIX_ROUTE_PATH.LEFT_TO_FIX_SHIPMENT_DETAILS,
        state: {
            exceptionShipmentsWithDetails: getExceptionShipmentsWithDetails(),
            title: getSdViewHeadingKey()
        }
    })
}


Left2FixFilterView.propTypes = {
    location: PropTypes.object,
    intl: PropTypes.object
}

export default injectIntl(Left2FixFilterView)