import React, {useEffect, useReducer} from "react";
import {DriverNoShowReducer, DriverNoShowReducerInitialState} from "../../reducers/DriverNoShowReducer";
import InProgress from "../common/InProgress";
import Column from "@amzn/meridian/column";
import Alert from "@amzn/meridian/alert";
import Button from "@amzn/meridian/button";
import {FormattedMessage} from "react-intl";
import {TaskView} from "@amzn/dolphin-uiweb-framework";
import {getDisplayName} from "../../handler/TranslationHandler";
import {ROUTE_PATH} from "../../router/routing";
import PropTypes from 'prop-types';

const DriverNoShowResultScreen = ({location, intl, history}) => {
    const [{loading, alertMessage, alertType, failedScannables}, dispatcher] = useReducer(DriverNoShowReducer, DriverNoShowReducerInitialState);
    useEffect(() => {
        dispatcher({
            type: location.state.metaData.actionType,
            data: location.state.metaData,
            dispatcher: dispatcher
        })
    }, []);
    const getFailedShipmentsList = () => {
        return failedScannables.map((scannable) => {
            return {title: scannable}
        });
    };
    return (
        <Column height="100%" heights={["fill", "fit"]}>
            <Column spacingInset="medium">
                {loading && <InProgress/>}
                {alertType &&
                <Alert
                    type={alertType}
                    size="xlarge"
                    title={getDisplayName(intl, alertMessage, {transporterId: location.state.metaData.currentTransporterId})}/>
                }
                {failedScannables && <TaskView tasks={getFailedShipmentsList()}/>}
            </Column>
            <Column width="100%" spacingInset="medium" alignmentVertical="top">
                {!loading &&
                <Button size="large" onClick={() => history.push(ROUTE_PATH.HOME)}>
                    <FormattedMessage id="move_ahead"/>
                </Button>
                }
            </Column>
        </Column>
    );
};
DriverNoShowResultScreen.propTypes = {
    intl: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object
};
export default DriverNoShowResultScreen;