import React, {useState, useEffect} from 'react';
import Box from "@amzn/meridian/box";
import Input from "@amzn/meridian/input";
import Text from "@amzn/meridian/text";
import {FormattedMessage} from "react-intl";
import PropTypes from 'prop-types';

const ScanBox = ({titleId, titleParams, onScan}) => {
    const scanBoxRef = React.useRef(null);
    const [value, setValue] = useState("");

    useEffect(()=>{
        setInputBoxReadOnlyState(true);
        setTimeout(() => {
            setInputBoxReadOnlyState(false);
        }, 200);
    },[]);

    const setInputBoxReadOnlyState = (isReadOnly) => {
        if(scanBoxRef && scanBoxRef.current){
            scanBoxRef.current.readOnly = isReadOnly;
        }
    };

    const onScanningScannableId = (event) => {
        if (event.keyCode === 13) {
            console.log(value);
            onScan(value);
            setValue("");
        }
    };
    const requestFocus = () => {
        setInputBoxReadOnlyState(true);
        setTimeout(() => {
            if(scanBoxRef && scanBoxRef.current) {
                scanBoxRef.current.focus();
                setInputBoxReadOnlyState(false);
            }
        }, 200);
    };
    return (
        <Box spacingInset="medium">
            <Text type="h300" id="input_box_text"> <FormattedMessage id={titleId} values={titleParams}/></Text>
            <br/>
            <Input
                aria-describedby= "input_box_text"
                ref = {scanBoxRef}
                value={value}
                onChange={setValue}
                type="text"
                placeholder=""
                onKeyDown={(event) => onScanningScannableId(event)}
                size="small"
                autoFocus={true}
                onBlur={() => requestFocus()}
                autoFill={false}/>
        </Box>
    )
};
ScanBox.propTypes = {
    titleId: PropTypes.string,
    onScan: PropTypes.func,
    titleParams: PropTypes.any
};
export default ScanBox;