const BACK_PRESSED = "BACK_PRESSED";

let prevBackPressedListener = undefined;
let interceptBackButtonReason = undefined;
let userTime = undefined;
const MAX_TIME_DIFF = 1500;

window.addEventListener(BACK_PRESSED, function (event) {
    prevBackPressedListener && prevBackPressedListener(event);
});

window.addEventListener('popstate', function (event) {
    console.log("back button event listener ");
    if (interceptBackButtonReason && interceptBackButtonReason === window.location.href) {
        // if time diff is < MAX_TIME_DIFF we ignore the pop event
        const timeDiff = new Date().getTime() - userTime;
        console.error("timeDiff", timeDiff);
        if (timeDiff > MAX_TIME_DIFF) {
            window.dispatchEvent(new Event(BACK_PRESSED));
        }
        setTimeout(() => window.history.pushState({noBack: true}, ''));
        console.log("back button intercepted for reason: ", interceptBackButtonReason);
    }
});

export function setBackPressedEventListener(listener) {
    prevBackPressedListener = listener;
}

export function clearBackPressedEventListener() {
    prevBackPressedListener = undefined;
}

export function interceptBackButton(reason) {
    if (!userTime) {
        userTime = new Date().getTime();
    }
    interceptBackButtonReason = window.location.href;
    setTimeout(() => {
        window.history.pushState({noBack: true}, '');
    });
}

export function interceptBackButtonStop() {
    interceptBackButtonReason = undefined;
}