import {APIEvents} from "../../../constants/AnalyticsEventKeys";
import {DataHandler, MobileAnalyticsHelper} from "@amzn/dolphin-web-framework";
import {getNPSWClient} from "../../base/ClientInterface";

export function getProblemSolveCategoryMap(data, dispatcher, nextAction, errorAction) {
    let startTime = Date.now();
    getNPSWClient().getProblemSolveCategoryMap(data)
        .then(({success, data}) => {
            if (success) {
                removeDisabledProblemCategories(data);
                dispatcher({
                    type: nextAction,
                    data: data,
                    dispatcher: dispatcher
                });
                MobileAnalyticsHelper.executeAPIAnalytics(APIEvents.GetProblemSolveCategoryMap, startTime);
            } else {
                dispatcher({
                    type: errorAction
                });
                MobileAnalyticsHelper.executeAPIAnalytics(APIEvents.GetProblemSolveCategoryMap, startTime, true);
            }
        });
}

const removeDisabledProblemCategories = (problemSolveCategoryMap) => {
    const driverProblemType = problemSolveCategoryMap.DRIVER;
    for(const key in driverProblemType) {
        if(driverProblemType.hasOwnProperty(key)) {
            const problemCategory = driverProblemType[key];
            for(const problemAction in problemCategory) {
                if(problemCategory.hasOwnProperty(problemAction)) {
                    const problemActionInfo = problemCategory[problemAction];
                    const country = DataHandler.getCountry();
                    //If Country Key Exists, Check if its enabled
                    if(country && problemActionInfo[country] && problemActionInfo[country].enabled) {
                        continue;
                    } else {
                        //If Country Key doesnt exist, disable by default
                        delete driverProblemType[key][problemAction];
                    }
                }
            }
        }
    }
    removeDisabledPackageProblemCategories(problemSolveCategoryMap);
};

const removeDisabledPackageProblemCategories = (problemSolveCategoryMap) => {
    const packageProblemType = problemSolveCategoryMap.PACKAGE;
    for(const key in packageProblemType) {
        if(packageProblemType.hasOwnProperty(key)) {
            const problemCategory = packageProblemType[key];
            for(const problemAction in problemCategory) {
                if(problemCategory.hasOwnProperty(problemAction)) {
                    const problemActionInfo = problemCategory[problemAction];
                    const country = DataHandler.getCountry();
                    const stationCode = DataHandler.getStationCode();
                    if(country && problemActionInfo[country] && !problemActionInfo[country].enabled) {
                        if (stationCode && problemActionInfo[country].exceptionNodeIds
                            && problemActionInfo[country].exceptionNodeIds.includes(stationCode)) {
                            continue;
                        } else {
                            delete packageProblemType[key][problemAction];
                        }
                    }
                }
            }
        }
    }
};