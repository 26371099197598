import React, { useReducer } from "react";
import { withRouter } from "react-router";
import { FormattedMessage, injectIntl } from "react-intl";
import Box from "@amzn/meridian/box";
import Text from "@amzn/meridian/text";
import Column from "@amzn/meridian/column";
import Button from "@amzn/meridian/button";
import { colorOrange400 } from "@amzn/meridian-tokens/base/color"
import Theme from "@amzn/meridian/theme"
import Alert from "@amzn/meridian/alert"
import {
    DRIVER_ENTITY_REDUCER_ACTION_TYPE,
    DriverEntityReducer,
    DriverEntityReducerInitialState
} from "../../reducers/DriverEntityReducer";
import NotificationMessage from "../common/NotificationMessage";

const DriverOverbookConfirm = ({ intl, history, location }) => {

    const [{ notificationType, notificationMessageId }, dispatch] =
        useReducer(DriverEntityReducer, DriverEntityReducerInitialState)
    const onConfirm = () => {
        dispatch({
            type: DRIVER_ENTITY_REDUCER_ACTION_TYPE.CALL_UPDATE_EXCEPTION_TRASPORTER,
            dispatcher: dispatch,
            data: {
                transporterId: location.state.transporterId,
                problemCategory: location.state.problemCategory,
                transporterName: location.state.transporterName
            }
        })
    }
    return (

        <Box>
            {notificationType &&
                <NotificationMessage dispatch={dispatch} actionType={DRIVER_ENTITY_REDUCER_ACTION_TYPE.CLOSE_NOTIFICATION}
                    type={notificationType} messageId={notificationMessageId} />}
            <Column spacingInset="medium medium xlarge medium" height={400}>
                <Theme tokens={{ boxBorderColorOutline: colorOrange400 }}>
                    <Box type="outline" spacingInset="medium">
                        <Alert
                            type="warning"
                            title=""
                            size="xlarge"
                        ></Alert>
                        <Text
                            type="h300"
                            alignment="center"
                        ><FormattedMessage id="confirm_driver_overbook" /></Text>
                    </Box>
                </Theme>
            </Column>

            <Column width="100%" alignmentVertical="bottom" spacingInset="medium medium xlarge medium">
                <Button onClick={() => history.goBack()}><FormattedMessage id="cancel_text" /></Button>
                <Button type="secondary" onClick={() => onConfirm()}><FormattedMessage id="confirm" /></Button>
            </Column>
        </Box>



    )
}
export default withRouter(injectIntl(DriverOverbookConfirm))