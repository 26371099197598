import React, {createContext, useReducer} from 'react';
import {SCRUB_ACTION_TYPE, ScrubReducer, ScrubReducerInitialState} from "../reducers/ScrubReducer";
import PropTypes from 'prop-types';

export const ScrubContext = createContext()

const ScrubContextProvider = ({children}) => {
    const [state, dispatch] = useReducer(ScrubReducer, ScrubReducerInitialState);

    const scrubActions = {
        fetchScrubCount : (dispatcher, errorNotifier) => {
            dispatch({
                type: SCRUB_ACTION_TYPE.FETCH_SCRUB_COUNT,
                dispatcher: dispatcher,
                notifier: errorNotifier
            })
        },
        setScrubCount: (data) => {
            dispatch({
                type: SCRUB_ACTION_TYPE.SET_SCRUB_COUNT,
                data: data
            })
        },
        setSelectedCategory: (category) => {
            dispatch({
                type: SCRUB_ACTION_TYPE.SET_SELECTED_CATEGORY,
                data: category
            })
        },
        fetchScrubExceptionDetails: (dispatcher, errorNotifier) => {
            dispatch({
                type: SCRUB_ACTION_TYPE.FETCH_SCRUB_EXCEPTION_DETAILS,
                dispatcher: dispatcher,
                notifier: errorNotifier
            })
        },
        setScrubExceptionDetails: (data) => {
            dispatch({
                type: SCRUB_ACTION_TYPE.SET_SCRUB_EXCEPTION_DETAILS,
                data: data
            })
        },
        scrubPackage: (trackingId, dispatcher, errorNotifier) => {
            dispatch({
                type: SCRUB_ACTION_TYPE.SCRUB_PACKAGE,
                data: trackingId,
                dispatcher: dispatcher,
                notifier: errorNotifier
            })
        },
        scrubPackageResponse: (data) => {
            dispatch({
                type: SCRUB_ACTION_TYPE.SCRUB_PACKAGE_RESPONSE,
                data: data
            })
        },
        resetScrubPackageResponse: () => {
            dispatch({
                type: SCRUB_ACTION_TYPE.RESET_SCRUB_PACKAGE_RESPONSE,
            })
        },
        setNotification: (message) => {
            dispatch({
                type: SCRUB_ACTION_TYPE.SET_NOTIFICATION,
                data: message
            })
        },
        resetNotification: () => {
            dispatch({
                type: SCRUB_ACTION_TYPE.RESET_NOTIFICATION,
            })
        },
        incrementResolvedPackage: () => {
            dispatch({
                type: SCRUB_ACTION_TYPE.INCREMENT_RESOLVED_PACKAGE,
            })
        },
        errorNotification: (data) => {
            dispatch({
                type: SCRUB_ACTION_TYPE.ERROR_NOTIFICATION,
                data: data
            })
        }
    }

    return (
        <ScrubContext.Provider value={{state, scrubActions}}>
            {children}
        </ScrubContext.Provider>
    )
};

ScrubContextProvider.propTypes = {
    children: PropTypes.object,
};

export default ScrubContextProvider;