import React from "react";
import Text from "@amzn/meridian/text";
import {FormattedMessage} from "react-intl";
import {
    areStagingCartsPresent,
    getRecommendedCartLabel,
    getStageAreaLabel
} from "../../../helper/ActionComponentsHelper";
import Column from "@amzn/meridian/column";
import Button from "@amzn/meridian/button";
import {ScannedPackagesListView} from "../../scannedpackageslist/ScannedPackagesListView";
import {PROBLEM_CATEGORY_STRING_PREFIX} from "../../../constants/Constants";
import {getProblemCategory} from "../../../dataStore/PSExecutionDataStore";
import PropTypes from 'prop-types';
import {PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS} from "../../../router/ProblemSolveExecutionRouter";
import {TaskView} from "@amzn/dolphin-uiweb-framework";
import Box from "@amzn/meridian/box";

const StageProposedSolution = ({history,intl}) => {

    const handleScanStage = (titleId, titleParams) => {
        history.push({
            pathname: PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.STAGE_SOLUTION_ACTION,
            state: {
                metaData: {
                    titleId: titleId,
                    titleParams: titleParams,
                    isStagingToCart: areStagingCartsPresent(),
                    cartLabel: getRecommendedCartLabel(),
                    stagingAreaLabel: getStageAreaLabel()
                }
            }
        })
    }
    const handleCancel = () => {
        history.goBack()
    }

    const getStagingOptions = () => {
        return [{
            title: intl.formatMessage({id: "move_to_assigned_cart"}, {label: getRecommendedCartLabel()}),
            description: getStageAreaLabel(),
            onClick: () => handleScanStage("scan_with_text", {stageAreaLabel: getRecommendedCartLabel()}),
            titleFontWeight: ""
        },
        {
            title: intl.formatMessage({id: "move_to_any_route_cart"}),
            onClick: () => handleScanStage("move_to_any_route_cart", {}),
            titleFontWeight: ""
        }];
    }

    const getStagingCartsView = () => {
        return (
            <Column height="100%" heights={["full"]}>
                <Box spacingInset="none">
                    <Box spacingInset="medium medium small medium">
                        <Text type="h300"><FormattedMessage id={"what_would_you_like_to_do"}/></Text>
                    </Box>
                    <TaskView
                        tasks={getStagingOptions()}/>
                </Box>
                <ScannedPackagesListView rightHeader={PROBLEM_CATEGORY_STRING_PREFIX + getProblemCategory()}/>
            </Column>
        )
    }

    const getStagingAreaOnlyView = () => {
        return (
            <Column height="90%" heights={["70%", "fit"]}>
                <Column spacingInset="medium">
                    <Text type="h300" alignment="center"><FormattedMessage id={"action_stage_item"}/></Text>
                    <Text type="h100"><FormattedMessage id={"move_to_stage_location"}/></Text>
                    <Text type="b300"><FormattedMessage id={"stage_location_with_name"}/><b>{getStageAreaLabel()}</b></Text>
                    <Text type="b300"><FormattedMessage id={"stage_location_description_text"}/></Text>
                </Column>
                <Column width="100%" spacingInset="medium" alignmentVertical="top">
                    <Button onClick={() => handleScanStage( "scan_with_text", {stageAreaLabel: getStageAreaLabel()})}><FormattedMessage id="scan_stage"/></Button>
                    <Button type="secondary" onClick={() => handleCancel()}><FormattedMessage id="cancel_text"/></Button>
                    <div className="spaceBlocker"/>
                </Column>
                <ScannedPackagesListView rightHeader={PROBLEM_CATEGORY_STRING_PREFIX + getProblemCategory()}/>
            </Column>
        )
    }


    return  areStagingCartsPresent() ? getStagingCartsView() : getStagingAreaOnlyView();
}

StageProposedSolution.propTypes = {
    history: PropTypes.object,
    intl: PropTypes.object
}

export default StageProposedSolution