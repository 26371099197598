import {LeftToFixFiterKey} from "../constants/Left2FixFilterKey";
import {getExceptionShipmentSummaryDetails} from "../network/apis/npsw/GetExceptionShipmentSummaryDetailsAPI";
import {getStartTimeForSummaryDetails} from "./Left2FixHelper";
import {LEFT_TO_FIX_FILTER_REDUCERS_ACTION_TYPE} from "../reducers/LeftToFixFilterReducer";
import {PROBLEM_CATEGORY_STRING_PREFIX} from "../constants/Constants";
import {fetchProblemCategoryConfig} from "../handler/ProblemCategoryConfigHandler";

const ALL = "ALL"

export default function fetchFilterValueToCount(selectedQueueDetails, queueToCategories, openExceptionShipmentsWithDetails,
                                        exceptionShipmentSummaryReport, filterKey) {

    let filterValueToCount = new Map();
    switch (filterKey) {
        case LeftToFixFiterKey.ISSUE_TYPE:
            queueToCategories[selectedQueueDetails.queueName]
                .forEach((category) => {
                    let exceptionCount = exceptionShipmentSummaryReport[category].OPEN.length
                    if(exceptionCount>0)
                        filterValueToCount.set(category, exceptionCount)
                })
            break

        default:
            openExceptionShipmentsWithDetails.forEach( (ShipmentDetails) => {
            let value = ShipmentDetails[filterKey]
            let count = filterValueToCount.get(value)
            if(count) {
                filterValueToCount.set(value, ++count)
            } else if(value) {
                filterValueToCount.set(value, 1)
            }
        })
    }
    let finalResult = Array.from(filterValueToCount).sort()
    finalResult.push([ALL, selectedQueueDetails.exceptionCount ])
    return finalResult
}

export function fetchShipmentSummaryDetails(selectedQueueDetails, queueToCategories, selectedCategory, dispatcher) {
    let categories = []

    if (selectedCategory === ALL) {
        categories = queueToCategories[selectedQueueDetails.queueName]
    } else {
        categories.push(selectedCategory)
    }
    getExceptionShipmentSummaryDetails({
        category: categories,
        startDate: getStartTimeForSummaryDetails(),
        endDate: new Date().toString(),
    }, dispatcher, LEFT_TO_FIX_FILTER_REDUCERS_ACTION_TYPE.SET_EXCEPTION_SHIPMENT_SUMMARY_DETAILS, LEFT_TO_FIX_FILTER_REDUCERS_ACTION_TYPE.ERROR)
}

export function getFilterDisplayName(filterValue, filterKey, intl) {
    if (filterKey === LeftToFixFiterKey.ISSUE_TYPE) {
        return intl.formatMessage({id: PROBLEM_CATEGORY_STRING_PREFIX + filterValue.toUpperCase()})
    } else {
        return filterValue
    }
}

export function getShipmentDetailViewHeading(filterValue, filterKey, selectedQueueDetails) {
    if (filterKey === LeftToFixFiterKey.ISSUE_TYPE && filterValue !== ALL) {
        return PROBLEM_CATEGORY_STRING_PREFIX + filterValue
    } else {
        return `${selectedQueueDetails.queueName.toLowerCase()}_toolbar_txt`
    }
}

export function getFilteredShipments(exceptionShipments, filterValue, filterKey) {
    if(filterValue === ALL) {
        return exceptionShipments
    } else {
        return exceptionShipments.filter((exceptionShipment) => (exceptionShipment[filterKey] === filterValue))
    }
}

export function isFilterValueValidProblemCategory(filterValue) {
    const problemCategoryConfig = fetchProblemCategoryConfig();
    return problemCategoryConfig[filterValue] !== undefined;
}