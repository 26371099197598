import {Constants} from "@amzn/dolphin-web-framework";

export function getProblemSolveServiceBaseURL() {
    switch(getStage()) {
        case Constants.Stage.BETA:
            return Constants.DolphinURL.NA.PREPROD;
        case Constants.Stage.GAMMA:
            switch(getRegion()) {
                case Constants.Region.REGION_NA: return Constants.DolphinURL.NA.PREPROD;
                case Constants.Region.REGION_EU: return Constants.DolphinURL.EU.PREPROD;
                case Constants.Region.REGION_FE: return Constants.DolphinURL.FE.PREPROD;
                case Constants.Region.REGION_IN: return Constants.DolphinURL.IN.PREPROD;
                // no default
            }
            break;
        // no default
        case Constants.Stage.PROD:
            switch(getRegion()) {
                case Constants.Region.REGION_NA: return Constants.DolphinURL.NA.PROD;
                case Constants.Region.REGION_EU: return Constants.DolphinURL.EU.PROD;
                case Constants.Region.REGION_FE: return Constants.DolphinURL.FE.PROD;
                case Constants.Region.REGION_IN: return Constants.DolphinURL.IN.PROD;
                // no default
            }
            break;
        // no default
    }
}

export function getAnalyticsRegion() {
    const hostname = window.location.hostname;
    if (hostname.startsWith(Constants.RegionLowerCase.REGION_NA)) return Constants.Region.REGION_NA;
    else if (hostname.startsWith(Constants.RegionLowerCase.REGION_EU)) return Constants.Region.REGION_EU;
    else if (hostname.startsWith(Constants.RegionLowerCase.REGION_JP)) return Constants.Region.REGION_FE;
    else if (hostname.startsWith(Constants.RegionLowerCase.REGION_IN)) return Constants.Region.REGION_EU;
    return Constants.Region.REGION_NA;
}

export function getRegion() {
    const hostname = window.location.hostname;
    if (hostname.startsWith(Constants.RegionLowerCase.REGION_NA)) return Constants.Region.REGION_NA;
    else if (hostname.startsWith(Constants.RegionLowerCase.REGION_EU)) return Constants.Region.REGION_EU;
    else if (hostname.startsWith(Constants.RegionLowerCase.REGION_JP)) return Constants.Region.REGION_FE;
    else if (hostname.startsWith(Constants.RegionLowerCase.REGION_IN)) return Constants.Region.REGION_IN;
    return Constants.Region.REGION_NA;
}

export function getStage() {
    const hostname = window.location.hostname.split('.');
    if (hostname[1] != null) {
        const stage = hostname[1];
        if (stage.endsWith(Constants.Stage.INTEG)) return Constants.Stage.BETA;
        else if (stage.endsWith(Constants.Stage.GAMMA)) return Constants.Stage.GAMMA;
        else if (stage.endsWith(Constants.Stage.PROD)) return Constants.Stage.PROD;
        return Constants.Stage.GAMMA;
    }
    return Constants.Stage.GAMMA;
}

export function getLoggerUrl() {
    switch(getStage()) {
        case Constants.Stage.BETA:
            return "https://4xj170wbj1.execute-api.us-west-2.amazonaws.com/prod/v1/log";
        case Constants.Stage.GAMMA:
            switch(getRegion()) {
                case Constants.Region.REGION_NA: return "https://pklq12tiv3.execute-api.us-east-1.amazonaws.com/prod/v1/log";
                case Constants.Region.REGION_EU: return "https://o5p2gfst73.execute-api.eu-west-1.amazonaws.com/prod/v1/log";
                case Constants.Region.REGION_FE: return "https://xbgrz70qm0.execute-api.us-west-2.amazonaws.com/prod/v1/log";
                case Constants.Region.REGION_IN: return "https://22xr6xh0f5.execute-api.eu-west-1.amazonaws.com/prod/v1/log";
                // no default
            }
            /* istanbul ignore next */
            break;
        case Constants.Stage.PROD:
            switch(getRegion()) {
                case Constants.Region.REGION_NA: return "https://tfzhbix3kg.execute-api.us-east-1.amazonaws.com/prod/v1/log";
                case Constants.Region.REGION_EU: return "https://799kkn67q6.execute-api.eu-west-1.amazonaws.com/prod/v1/log";
                case Constants.Region.REGION_FE: return "https://p3ifj6pc42.execute-api.us-west-2.amazonaws.com/prod/v1/log";
                case Constants.Region.REGION_IN: return "https://y5n15whuye.execute-api.eu-west-1.amazonaws.com/prod/v1/log";
                // no default
            }
            /* istanbul ignore next */
            break;
        // no default
    }
}

export function getPUMARegion() {
    const hostname = window.location.hostname;
    if (hostname.startsWith(Constants.RegionLowerCase.REGION_NA)) return Constants.RegionLowerCase.REGION_NA;
    else if (hostname.startsWith(Constants.RegionLowerCase.REGION_EU)) return Constants.RegionLowerCase.REGION_EU;
    else if (hostname.startsWith(Constants.RegionLowerCase.REGION_JP)) return Constants.RegionLowerCase.REGION_JP;
    else if (hostname.startsWith(Constants.RegionLowerCase.REGION_IN)) return Constants.RegionLowerCase.REGION_EU;
    return Constants.RegionLowerCase.REGION_JP;
}

export function getPUMACountry() {
    const hostname = window.location.hostname;
    if (hostname.startsWith(Constants.RegionLowerCase.REGION_NA)) return Constants.Region.REGION_US;
    else if (hostname.startsWith(Constants.RegionLowerCase.REGION_EU)) return Constants.Region.REGION_UK;
    else if (hostname.startsWith(Constants.RegionLowerCase.REGION_JP)) return Constants.Region.REGION_JP;
    else if (hostname.startsWith(Constants.RegionLowerCase.REGION_IN)) return Constants.Region.REGION_IN;
    return Constants.Region.REGION_JP;
}

export function getPUMAStage() {
    const hostname = window.location.hostname.split('.');
    if (hostname[1] != null) {
        const stage = hostname[1];
        if (stage.endsWith(Constants.Stage.INTEG)) return Constants.Stage.TEST;
        else if (stage.endsWith(Constants.Stage.MASTER)) return Constants.Stage.TEST;
        else if (stage.endsWith(Constants.Stage.PROD)) return Constants.Stage.PROD;
        return Constants.Stage.TEST;
    }
    return Constants.Stage.TEST;
}