import React, {useReducer} from 'react';
import Column from "@amzn/meridian/column";
import Box from "@amzn/meridian/box";
import {FormattedMessage} from "react-intl";
import {PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS} from "../../router/ProblemSolveExecutionRouter";
import Button from "@amzn/meridian/button";
import {ScannedPackagesListView} from "../scannedpackageslist/ScannedPackagesListView";
import {NOTIFICATION_TYPE, PROBLEM_CATEGORY_STRING_PREFIX} from "../../constants/Constants";
import {getProblemCategory} from "../../dataStore/PSExecutionDataStore";
import ScanBox from "../scanbox/GenericScanBox";
import {
    HazmatScanReducer,
    HazmatScanReducerActionType,
    HazmatScanReducerInitialState
} from "../../reducers/HazmatScanReducer";
import NotificationMessage from "../common/NotificationMessage";
import InProgress from "../common/InProgress";
import PropTypes from 'prop-types';

const HazmatScanView = ({location, history}) => {
    const [{fetchingData, notificationType, notificationMessageId}, dispatch] = useReducer(HazmatScanReducer, HazmatScanReducerInitialState);
    const hazmatCode = location.state.additionalProperties.hazmatCode;
    const HAZMAT_REGULATORY_ID_SUFFIX = ["Top", "Bot"];
    const handleScan = (scanHazmatCode) => {
        const scannedHazmatId = getHazmatLabelId(scanHazmatCode);
        if (hazmatCode === scannedHazmatId) {
            dispatch({
                type: HazmatScanReducerActionType.EXECUTE_PROBLEM_ACTION_API,
                data :{
                    hazmat_united_nations_regulatory_id: scannedHazmatId,
                    tape_damaged_key: location.state.additionalProperties.tape_damaged_key
                },
                dispatch: dispatch
            });
        } else {
            dispatch({
                type: HazmatScanReducerActionType.SHOW_NOTIFICATION,
                data: {
                    type: NOTIFICATION_TYPE.ALERT,
                    message: "hazmat_label_mismatch"
                }
            });
        }
    };
    const getHazmatLabelId = (scanHazmatCode) => {
        let hazmatCode = scanHazmatCode;
        HAZMAT_REGULATORY_ID_SUFFIX.forEach((suffix) => {
            hazmatCode = hazmatCode.split(suffix)[0];
        });
        return hazmatCode.toUpperCase();
    };
    return (
        <Column height="100%" heights={["fit", "fill"]}>
            <Box spacingInset="none">
                {fetchingData && <InProgress/>}
                {notificationType &&
                <NotificationMessage dispatch={dispatch}
                                     actionType={HazmatScanReducerActionType.CLOSE_NOTIFICATION}
                                     type={notificationType}
                                     messageId={notificationMessageId}/>}
                <ScanBox titleId="hazmat_code_message" titleParams={{hazmatCode: hazmatCode}}
                         onScan={(input) => handleScan(input)}/>
            </Box>
            <Column width="100%" spacingInset="medium" alignmentVertical="bottom">
                <Button size="large" type="secondary"
                        onClick={() => history.push(PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.PRODUCT_ITEMS)}>
                    <FormattedMessage id={"ps_product_list"}/>
                </Button>
                <div className="spaceBlocker"/>
            </Column>
            <ScannedPackagesListView rightHeader={PROBLEM_CATEGORY_STRING_PREFIX + getProblemCategory()}/>
        </Column>
    );
};
HazmatScanView.propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};
export default HazmatScanView;