import React from "react"
import Text from "@amzn/meridian/text";
import Box from "@amzn/meridian/box";
import Divider from "@amzn/meridian/divider";
import Column from "@amzn/meridian/column";
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";

const ScrubPackageDetails = ({data}) => {

    return (
        <Column spacingInset="small">
            <Box spacingInset="medium" type="outline">
                <div tabIndex={0}>
                    <Text type="h300">{data.scannableId}</Text>
                    <Text type="b300">
                        <FormattedMessage id={"scannable_id"} />
                    </Text>
                </div>
                <br/>
                <div tabIndex={0}>
                    <Text type="h300">{data.location}</Text>
                    <Text type="b300">
                        <FormattedMessage id={"expected_location"} />
                    </Text>
                </div>
                <Divider size="small"/>
            </Box>
        </Column>
    )
}

ScrubPackageDetails.propTypes = {
    data: PropTypes.object,
};

export default ScrubPackageDetails;