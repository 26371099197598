import React, {useEffect, useReducer, useState} from 'react';
import Text from "@amzn/meridian/text"
import Box from "@amzn/meridian/box"
import {FormattedMessage, injectIntl} from "react-intl";
import {withRouter} from 'react-router-dom';
import {Loader} from "@amzn/dolphin-uiweb-framework"
import InProgress from "../common/InProgress";
import {
    LEFT_TO_FIX_REDUCERS_ACTION_TYPE,
    LeftToFixReducer,
    LeftToFixReducerInitialState
} from "../../reducers/LeftToFixReducer";
import TableRowView from "../common/TableRowView";
import PropTypes from 'prop-types';
import {LEFT_TO_FIX_ROUTE_PATH} from "../../router/Left2FixRouter";
import ToastMessage from "../common/ToastMessage";
import {SCRUB_ROUTE_PATH} from "../../router/ScrubRouter";
import {
    saveSelectedQueueDetails,
    getSelectedQueueDetails,
    saveQueueToCategories,
    getQueueToCategories,
    saveExceptionQueueToCount,
    getExceptionQueueToCount,
    saveExceptionShipmentSummaryReport,
    getExceptionShipmentSummaryReport,
    saveOpenExceptionShipmentsWithDetails,
    getOpenExceptionShipmentsWithDetails,
    saveLeftToFixFilters,
    getLeftToFixFilters
} from "../../dataStore/LocationStateDataStore";

const Left2FixView = (props) => {

    const [showAlert, setShowAlert] = useState(false)

    const [{exceptionQueueToCount, loading, queueToCategories, selectedQueueDetails, leftToFixFilters, scrubExceptionCount,
        openExceptionShipmentsWithDetails, showLeftToFixFilter, exceptionShipmentSummaryReport,
        fetchingShipmentDetails
    }, dispatch] = useReducer(LeftToFixReducer, LeftToFixReducerInitialState);

    useEffect(() => {
        dispatch({
            type: LEFT_TO_FIX_REDUCERS_ACTION_TYPE.FETCH_CATEGORIES_FOR_NODE_ID,
            data: {},
            dispatcher: dispatch
        })
    }, []);

    if (loading) {
        return (
            <InProgress text="queue_loading_text"/>
        );
    }

    if (showLeftToFixFilter) {
        saveSelectedQueueDetails(selectedQueueDetails)
        saveQueueToCategories(queueToCategories)
        saveExceptionQueueToCount(exceptionQueueToCount)
        saveExceptionShipmentSummaryReport(exceptionShipmentSummaryReport)
        saveOpenExceptionShipmentsWithDetails(openExceptionShipmentsWithDetails)
        saveLeftToFixFilters(leftToFixFilters)
        props.history.push({
            pathname: LEFT_TO_FIX_ROUTE_PATH.LEFT_TO_FIX_FILTER,
            state: {
                selectedQueueDetails: getSelectedQueueDetails(),
                queueToCategories: getQueueToCategories(),
                exceptionQueueToCount: getExceptionQueueToCount(),
                exceptionShipmentSummaryReport: getExceptionShipmentSummaryReport(),
                openExceptionShipmentsWithDetails: getOpenExceptionShipmentsWithDetails(),
                leftToFixFilters: getLeftToFixFilters()
            }

        })
        return <div/>
    }

    const onCloseToast = () => {
        setShowAlert(false)
    }

    const onSelectQueue = (selectedQueueDetails) => {
        // Structure of selectedQueueDetails is {queueName: key, exceptionCount: exceptionCount }
        if (selectedQueueDetails.exceptionCount === 0) {
            setShowAlert(true)
        } else {
            dispatch({
                type: LEFT_TO_FIX_REDUCERS_ACTION_TYPE.FETCH_EXCEPTION_SIZE_THRESHOLD,
                data: selectedQueueDetails,
                dispatcher: dispatch
            })
        }
    }

    const onSelectScrub = () => {
        props.history.push(SCRUB_ROUTE_PATH.SCRUB)
    }

    return (
        <Box>
            {fetchingShipmentDetails && <Loader />}
            <Box spacingInset="none medium medium medium">
                <Text type="h200"><FormattedMessage id="ps_reported_issues"/> </Text>
            </Box>
                <TableRowView items={exceptionQueueToCount} callback={onSelectQueue} intl={props.intl}/>
                <br/>
                {
                    showAlert &&
                    <ToastMessage text="no_issues_for_queue" callback={onCloseToast}/>
                }
                {
                    scrubExceptionCount && scrubExceptionCount[0] && scrubExceptionCount[0]["exceptionCount"] ?
                    <div>
                        <br/>
                        <Box spacingInset="medium medium medium medium">
                            <Text type="h200"><FormattedMessage id="scrub_title_des" /> </Text>
                        </Box>
                        <TableRowView items={scrubExceptionCount} callback={onSelectScrub}  intl={props.intl}/>
                        <br/><br/>
                    </div>: null
                }


        </Box>

    )
}

Left2FixView.propTypes = {
    history: PropTypes.object,
    intl: PropTypes.object
}
export default withRouter(injectIntl(Left2FixView))