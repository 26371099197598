import React from 'react';
import {ErrorView} from "@amzn/dolphin-uiweb-framework";
import PropTypes from 'prop-types';
import {Logger, NativeMeshInteractor, PUMAMetricHelper} from "@amzn/dolphin-web-framework";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {// eslint-disable-line no-unused-vars
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        Logger.log.fatal("ErrorBoundary", error, errorInfo);
        const metricName = "clientError";
        PUMAMetricHelper.publishCountToDolphinCSM(metricName);
    }

    render() {
        if (this.state.hasError) {
            return <ErrorView title="Problem solve" errorMessage="error" onBackClicked={NativeMeshInteractor.exitModule}/>;
        }

        return this.props.children;
    }
}
ErrorBoundary.propTypes = {
    children: PropTypes.object
};

export default ErrorBoundary;