import React, {useCallback, useState} from 'react';
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Sheet from "@amzn/meridian/sheet";
import Box from "@amzn/meridian/box";
import {ScannedPackagesInfoBar} from "./ScannedPackagesInfoBar";
import PropTypes from 'prop-types';
import {getShipmentDetail} from "../../dataStore/PSExecutionDataStore";
import {getClosedInfoBarConfig, getOpenSheetHeaderBodyDividerConfig} from "../../constants/ScannedPackageListStyles";
import {ShipmentDetail} from "./ShipmentDetail";

export const ScannedPackagesListView = ({rightHeader}) => {
  const [open, setOpen] = useState(false);
  const onClickOpen = useCallback(() => setOpen(true), []);
  const onClickClose = useCallback(() => setOpen(false), []);
  const onClose = useCallback(() => setOpen(false), []);

  return (
    <Column heights={["fill", "fit"]} width="100%">
      <Box>
        <div onClick={() => onClickOpen()} onTouchMove={() => onClickOpen()}
             style={getClosedInfoBarConfig()}>
          <ScannedPackagesInfoBar rightHeader={rightHeader}/>
        </div>

        <Row widths="fill" width="100%">
          <Sheet type="overlay" side="bottom" open={open} onClose={onClose} spacingInset="none">
            <div onClick={() => onClickClose()} onTouchMove={() => onClickClose()}>
              <ScannedPackagesInfoBar rightHeader={rightHeader}/>
              <hr style={getOpenSheetHeaderBodyDividerConfig()}/>
            </div>
            {getShipmentDetail().map(
                (shipmentDetail, index) => (
                  <ShipmentDetail shipmentDetail={shipmentDetail} key={index}/>
                ))
            }
          </Sheet>
        </Row>
      </Box>
    </Column>
  );
};

ScannedPackagesListView.propTypes = {
  rightHeader: PropTypes.any
};