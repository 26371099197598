import React, {useEffect} from "react";
import Row from "@amzn/meridian/row";
import Alert from "@amzn/meridian/alert";
import {FormattedMessage} from "react-intl";
import PropTypes from 'prop-types';
import {closeNotification} from "../../helper/NotificationMessageHelper";
import {NOTIFICATION_TYPE} from "../../constants/Constants";
import {SoundManager} from "../../audio/SoundManager";

const NotificationMessage = ({dispatch, actionType, type, messageId, messageValues}) => {
    useEffect(()=>{
        switch (type) {
            case NOTIFICATION_TYPE.ALERT:
                SoundManager.playErrorSound();
                break;
            case NOTIFICATION_TYPE.WARNING:
                SoundManager.playWarningSound();
                break;
            case NOTIFICATION_TYPE.SUCCESS:
                SoundManager.playSuccessSound();
                break;
            default:
                break;
        }
    },[type]);
    return (
        <Row width="100%" widths="fill" spacingInset="medium medium none medium">
            <Alert type={type} size="medium" onClose={() => closeNotification(dispatch, actionType)}>
                <FormattedMessage id={messageId} values={{ ...messageValues }}/></Alert>
        </Row>
    )
};


NotificationMessage.propTypes = {
    dispatch: PropTypes.func,
    actionType: PropTypes.string,
    type: PropTypes.string,
    messageId: PropTypes.string,
    messageValues: PropTypes.any
};
export default NotificationMessage