import React, {useReducer, useState} from 'react';
import Box from "@amzn/meridian/box";
import Text from "@amzn/meridian/text";
import {FormattedMessage, injectIntl} from "react-intl";
import Column from "@amzn/meridian/column";
import Button from "@amzn/meridian/button";
import PropTypes from 'prop-types';
import {
    getExceptionCountByCategory,
    getPackageDetailsByCategory,
    prepareUpdateExceptionShipmentsRequest
} from "../../helper/QRExceptionsHelper";
import {ShipmentDetail} from "../scannedpackageslist/ShipmentDetail";
import {
    QR_PICKUP_EXCEPTIONS_REDUCERS_ACTION_TYPE,
    QRPickupExceptionsInitialState,
    QRPickupExceptionsReducer
} from "../../reducers/QRPickupExceptionsReducer";
import {ROUTE_PATH} from "../../router/routing";
import InProgress from "../common/InProgress";
import {withRouter} from "react-router";
import {AccordionView} from "@amzn/dolphin-uiweb-framework";
import {getDisplayName} from "../../handler/TranslationHandler";

const QRPickupExceptions = ({history, intl}) => {
    const [{QRPickupConfig, isLoading}, dispatch] = useReducer(QRPickupExceptionsReducer, QRPickupExceptionsInitialState);
    const [visible, setVisible] = useState("");
    const changeVisibility = (exceptionCategory) => {
        if (visible === exceptionCategory) {
            setVisible("");
            return;
        }
        setVisible(exceptionCategory);
    };
    const updateExceptionShipments = () => {
        dispatch({
            type: QR_PICKUP_EXCEPTIONS_REDUCERS_ACTION_TYPE.UPDATE_MULTIPLE_EXCEPTIONS,
            dispatch: dispatch,
            data: prepareUpdateExceptionShipmentsRequest()
        })
    };

    return (
        <Column height="100%" heights={["75%", "25%"]}>
            <div style={{'overflow': 'auto'}}>
                <Box spacingInset="medium medium small medium">
                    <Text type="h300"><FormattedMessage id={"what_would_you_like_to_do"}/></Text>
                </Box>
                <Box>
                    {isLoading && <InProgress/>}
                    {QRPickupConfig.map(({stringId, exceptionCategory}) => (
                        <Box key={stringId}>
                            <AccordionView
                                title={getDisplayName(intl, stringId, {count: getExceptionCountByCategory(exceptionCategory)})}
                                onClick={() => changeVisibility(exceptionCategory)}/>
                            {visible === exceptionCategory &&
                            <Box>
                                <Box spacingInset="medium medium none medium">
                                    <Text type="h200"><FormattedMessage id="details"/></Text>
                                </Box>
                                {getPackageDetailsByCategory(exceptionCategory).map((shipmentDetail) => (
                                    <ShipmentDetail shipmentDetail={shipmentDetail}
                                                    key={shipmentDetail.scannableId}/>
                                ))}
                            </Box>
                            }
                        </Box>
                    ))}
                </Box>
            </div>
            <Column width="100%" spacingInset="medium">
                <Button size="large" onClick={() => updateExceptionShipments()}><FormattedMessage
                    id="confirm"/></Button>
                <Button type="secondary" size="large" onClick={() => history.push(ROUTE_PATH.HOME)}>
                    <FormattedMessage id="restart"/>
                </Button>
            </Column>
        </Column>
    )
};

QRPickupExceptions.propTypes = {
    intl: PropTypes.object,
    history: PropTypes.object,
};
export default withRouter(injectIntl(QRPickupExceptions));