import {UpdateExceptionTransporterAPI} from "../network/apis/npsw/UpdateExceptionTransporterAPI";

export const DriverNoShowReducerInitialState = {
    loading: true
};

export const DRIVER_NO_SHOW_REDUCER_ACTION_TYPE = {
    DRIVER_NO_SHOW_REPLAN: "DA_NO_SHOW_REPLAN",
    DRIVER_NO_SHOW_REPLAN_RESPONSE: "DA_NO_SHOW_REPLAN_RESPONSE",
    DRIVER_NO_SHOW_REASSIGN: "DA_NO_SHOW_REASSIGN",
    DRIVER_NO_SHOW_REASSIGN_RESPONSE: "DA_NO_SHOW_REASSIGN_RESPONSE",
    ERROR: "ERROR"
};

export const DriverNoShowReducer = (state, action) => {
    switch (action.type) {
        case DRIVER_NO_SHOW_REDUCER_ACTION_TYPE.DRIVER_NO_SHOW_REPLAN:
            UpdateExceptionTransporterAPI({
                    transporterId: action.data.currentTransporterId,
                    problemCategory: "TRANSPORTER_NO_SHOW",
                    targetAction: "TRANSPORTER_REPLAN"
                }, DRIVER_NO_SHOW_REDUCER_ACTION_TYPE.DRIVER_NO_SHOW_REPLAN_RESPONSE,
                DRIVER_NO_SHOW_REDUCER_ACTION_TYPE.ERROR,
                action.dispatcher);
            return {...state, loading: true};
        case DRIVER_NO_SHOW_REDUCER_ACTION_TYPE.DRIVER_NO_SHOW_REPLAN_RESPONSE:
            return processResponse(
                state,
                action.data,
                "driver_replan_failure",
                "driver_replan_failure_heading",
                "driver_replan_successful"
            );
        case DRIVER_NO_SHOW_REDUCER_ACTION_TYPE.DRIVER_NO_SHOW_REASSIGN:
            UpdateExceptionTransporterAPI({
                    transporterId: action.data.currentTransporterId,
                    problemCategory: "TRANSPORTER_NO_SHOW",
                    targetAction: "TRANSPORTER_REASSIGN",
                    metadata: {"NEW_TRANSPORTER_ID": action.data.newTransporterId}
                },
                DRIVER_NO_SHOW_REDUCER_ACTION_TYPE.DRIVER_NO_SHOW_REASSIGN_RESPONSE,
                DRIVER_NO_SHOW_REDUCER_ACTION_TYPE.ERROR,
                action.dispatcher);
            return {...state, loading: true};
        case DRIVER_NO_SHOW_REDUCER_ACTION_TYPE.DRIVER_NO_SHOW_REASSIGN_RESPONSE:
            return processResponse(state, action.data,
                "driver_reassign_failure",
                "driver_reassign_failure_heading",
                "driver_reassign_successful");
        case DRIVER_NO_SHOW_REDUCER_ACTION_TYPE.ERROR:
            return {
                ...state, loading: false,
                alertType: "error",
                alertMessage: "internal_error"
            };
        default:
            return state;
    }
};

const processResponse = (state, data, errorMessageId, warningMessageId, successMessageId) => {
    if (data.success) {
        return {...state, loading: false,
            success: true,
            alertType: "success",
        alertMessage: successMessageId}
    } else {
        if (data.additionalInformation && data.additionalInformation.failedScannableIds) {
            return {
                ...state, loading: false, success: false,
                failedScannables: data.additionalInformation.failedScannableIds,
                alertType: "warning",
                alertMessage: warningMessageId
            };
        } else {
            return {
                ...state, loading: false, success: false,
                alertType: "error",
                alertMessage: errorMessageId
            };
        }
    }
};