import {SEARCH_TRANSPORTER_ACTION_TYPE} from "../../../reducers/SearchTransporterReducer";
import {APIEvents} from "../../../constants/AnalyticsEventKeys";
import {MobileAnalyticsHelper} from "@amzn/dolphin-web-framework";
import {getNPSWClient} from "../../base/ClientInterface";

export function SearchTransporterAPI(data, dispatcher) {
    let startTime = Date.now();
    getNPSWClient().searchTransporter(data)
        .then(({success, data}) => {
            if (success) {
                dispatcher({
                    type: SEARCH_TRANSPORTER_ACTION_TYPE.SET_TRANSPORTER,
                    data: data
                });
                MobileAnalyticsHelper.executeAPIAnalytics(APIEvents.SearchTransporter, startTime);
            } else {
                dispatcher({
                    type: SEARCH_TRANSPORTER_ACTION_TYPE.ERROR
                });
                MobileAnalyticsHelper.executeAPIAnalytics(APIEvents.SearchTransporter, startTime, true);
            }
        })
}