import {FeatureManager, Logger} from "@amzn/dolphin-web-framework";
import {SAL_COLOR_AUDIT_INCLUDE_CATEGORIES} from "../constants/Constants";
import {getProblemCategory, getScannedScannableIds, getShipmentDetail} from "../dataStore/PSExecutionDataStore";

export const getPickListId = (shipmentDetails) => {
    return getShipmentMetdata(shipmentDetails)?.PICKLIST_ID;
}

export const getItemId = (shipmentDetails) => {
    if(shipmentDetails.length > 0 && shipmentDetails[0].exceptionShipmentsDetails &&
        shipmentDetails[0].exceptionShipmentsDetails.length > 0 ) {
        if (shipmentDetails[0].exceptionShipmentsDetails[0].exceptionShipment) {
            return shipmentDetails[0].exceptionShipmentsDetails[0].exceptionShipment.scannableId
        }
    }
}

export const isSalColorAuditRequired = () => {
    if (FeatureManager.isFeatureEnabled(FeatureManager.Features.SAL_COLOR_AUDIT)) {
        const packageId = getScannedScannableIds();
        const shipmentDetail = getShipmentDetail();
        const problemCategory = getProblemCategory();
        const salColorHexCode = shipmentDetail.length > 0 ? shipmentDetail[0].salColor : ""
        if (!salColorHexCode) {
            Logger.log.warn("ShipmentDetail: " + JSON.stringify(shipmentDetail));
        }
        if (packageId.length === 1 && salColorHexCode && salColorHexCode.startsWith("#") &&
            SAL_COLOR_AUDIT_INCLUDE_CATEGORIES.includes(problemCategory)) {
            return true;
        }
    }
    return false;
}

export const getRouteId = (shipmentDetails) => {
    return getShipmentMetdata(shipmentDetails)?.ROUTE_ID;
}

export const getEntityType = (shimentDetails) => {
    let entityType
    if(getShipmentMetdata(shimentDetails)) {
        entityType = getShipmentMetdata(shimentDetails).ENTITY_TYPE;
    }
    return entityType
}

export const getShipmentDestinationDomain = (shimentDetails) => {
    if(shimentDetails.length > 0) {
        return shimentDetails[0].destinationDomain;
    }
}

export const getShipmentCategory = (shipmentDetails) => {
    if(shipmentDetails.length > 0) {
        return shipmentDetails[0].shipmentCategory;
    }
}

export const getShipmentDestinationDomainAndCategory = (shimentDetails) => {
    if(shimentDetails.length > 0) {
        return {  "shipmentCategory" : shimentDetails[0].shipmentCategory, "destinationDomain" :shimentDetails[0].destinationDomain };
    }
}
const getShipmentMetdata = (shimentDetails) => {
    if(shimentDetails.length > 0 && shimentDetails[0].exceptionShipmentsDetails &&
        shimentDetails[0].exceptionShipmentsDetails.length > 0 ) {
        if (shimentDetails[0].exceptionShipmentsDetails[0].exceptionShipment) {
            return shimentDetails[0].exceptionShipmentsDetails[0].exceptionShipment.shipmentMetaData
        }
    }
}