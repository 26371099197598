
const SCRUB_LOCAL_STORAGE_KEYS = {
    SCRUB_CATEGORY_MAP: "SCRUB_CATEGORY_MAP",
    SCRUB_CATEGORY_EXCEPTION_DETAILS: "SCRUB_CATEGORY_EXCEPTION_DETAILS",
    SCRUB_SCANNED_PACKAGE_DETAIL: "SCRUB_SCANNED_PACKAGE_DETAIL",
    SELECTED_CATEGORY: "SELECTED_CATEGORY",
    RESOLVED_PACKAGES: "RESOLVED_PACKAGES"
}

export const ScrubDataStore = {
    setScrubCategoryMap: (scrubCategoryMap) => {
        localStorage.setItem(SCRUB_LOCAL_STORAGE_KEYS.SCRUB_CATEGORY_MAP, JSON.stringify(scrubCategoryMap))
        ScrubDataStore.setScannedPackageDetails(null)
    },
    getScrubCategoryMap: () => {
        return JSON.parse(localStorage.getItem(SCRUB_LOCAL_STORAGE_KEYS.SCRUB_CATEGORY_MAP))
    },
    setSelectedCategory: (selectedCategory) => {
        localStorage.setItem(SCRUB_LOCAL_STORAGE_KEYS.SELECTED_CATEGORY, JSON.stringify(selectedCategory))
    },
    getSelectedCategory: () => {
        return JSON.parse(localStorage.getItem(SCRUB_LOCAL_STORAGE_KEYS.SELECTED_CATEGORY))
    },
    setScrubCategoryExceptionDetails: (scrubCategoryExceptionDetails) => {
        localStorage.setItem(SCRUB_LOCAL_STORAGE_KEYS.SCRUB_CATEGORY_EXCEPTION_DETAILS, JSON.stringify(scrubCategoryExceptionDetails))
        ScrubDataStore.setScannedPackageDetails(null)
    },
    getScrubCategoryExceptionDetails: () => {
        return JSON.parse(localStorage.getItem(SCRUB_LOCAL_STORAGE_KEYS.SCRUB_CATEGORY_EXCEPTION_DETAILS))
    },
    setResolvedPackages: (resolvedPackagesCount) => {
        localStorage.setItem(SCRUB_LOCAL_STORAGE_KEYS.RESOLVED_PACKAGES, JSON.stringify(resolvedPackagesCount))
    },
    getResolvedPackages: () => {
        const count = JSON.parse(localStorage.getItem(SCRUB_LOCAL_STORAGE_KEYS.RESOLVED_PACKAGES))
        return count ? count : 0
    },
    setScannedPackageDetails: (scannedPackageDetails) => {
        localStorage.setItem(SCRUB_LOCAL_STORAGE_KEYS.SCRUB_SCANNED_PACKAGE_DETAIL, JSON.stringify(scannedPackageDetails))
    },
    getScannedPackageDetails: () => {
        return JSON.parse(localStorage.getItem(SCRUB_LOCAL_STORAGE_KEYS.SCRUB_SCANNED_PACKAGE_DETAIL))
    },
    clearScrubData: () => {
        for(let key in SCRUB_LOCAL_STORAGE_KEYS) {
            localStorage.removeItem(key)
        }
    }
}

