import React, {useContext, useEffect, useState} from "react"
import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import ScrubPackageDetails from "./ScrubPackageDetails";
import {ScrubContext} from "../context/ScrubContext";
import ScrubScanner from "./ScrubScanner";
import {handleScrubResponse, isNotEmptyObject} from "./Utils";
import NotificationView from "../NotificationView";
import {Loader, ProgressBarView} from "@amzn/dolphin-uiweb-framework";
import PropTypes from 'prop-types';
import {SCRUB_ROUTE_PATH} from "../../../router/ScrubRouter";
import {ScrubDataStore} from "../DataStore/ScrubDataStore";

const ScrubPackageListView = (props) => {

    const {
        state: {
            loading = true,
        } = {}, scrubActions
    } = useContext(ScrubContext)

    const [totalPackages, setTotalPackages] = useState(0)
    const [scrubCategoryExceptionDetails, setScrubCategoryExceptionDetails] = useState({})
    const [scrubPackageResponse, setScrubPackageResponse] = useState({})
    const [resolvedPackages, setResolvedPackages] = useState(0)

    useEffect(() => {
        if (ScrubDataStore.getScrubCategoryExceptionDetails() !== null) {
            setScrubCategoryExceptionDetails(ScrubDataStore.getScrubCategoryExceptionDetails())
        }
        if (ScrubDataStore.getResolvedPackages() !== null) {
            setResolvedPackages(ScrubDataStore.getResolvedPackages())
        }
    })

    useEffect(() => {
        if (ScrubDataStore.getScannedPackageDetails() !== null) {
            setScrubPackageResponse(ScrubDataStore.getScannedPackageDetails())
        }
    }, [loading])

    const onSuccess = () => {
        props.history.push(SCRUB_ROUTE_PATH.SCRUB_CONTINUE)
    }

    const finishScanning = () => {
        props.history.push({
            pathname: SCRUB_ROUTE_PATH.SCRUB_REVIEW,
            state: {
                resolvedPackages: resolvedPackages,
                totalPackages: totalPackages
            }
        })
    }

    useEffect(() => {
        if (isNotEmptyObject(scrubPackageResponse)) {
            const notification = handleScrubResponse(props, scrubPackageResponse, totalPackages, resolvedPackages, onSuccess)
            if (notification) {
                scrubActions.setNotification(notification)
            }
        }
    }, [scrubPackageResponse])

    useEffect(() => {
        setTotalPackages(Object.keys(scrubCategoryExceptionDetails).length + resolvedPackages)
    }, [scrubCategoryExceptionDetails])

    if (loading) {
        return <Loader/>
    }

    const packageListView = () => {
        return (
            <Column spacingInset={"small"} spacing={"none"} height={"100"}>
                {
                    Object.keys(scrubCategoryExceptionDetails).map((trackingId, index) =>
                        <ScrubPackageDetails key={index} data={scrubCategoryExceptionDetails[trackingId]}/>
                    )
                }
               <div className="spaceBlocker"/>
            </Column>
        )
    }

    return (
        <Column height={"90vh"} heights={"fit"} alignmentVertical={"top"} spacing={"none"}>
            <ProgressBarView currentCount={resolvedPackages} totalCount={totalPackages} primaryColor={"#00A8E1"}/>
            <NotificationView/>
            <ScrubScanner/>
            <div className="scrollList">
                {packageListView()}
            </div>
            <div className="bottomSticky">
                <Column width="100%" spacingInset={"small"} alignmentVertical="top">
                    <Button onClick={finishScanning} size="large" type="secondary">
                        {props.intl.formatMessage({id: "finish_scanning"})}
                    </Button>
                </Column>
            </div>
        </Column>
    )
}

ScrubPackageListView.propTypes = {
    history: PropTypes.object,
    intl: PropTypes.object
};

export default ScrubPackageListView;