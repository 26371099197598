import React, {useContext, useEffect, useState} from "react"
import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";
import Button from "@amzn/meridian/button";
import {DetailsCardView, ProgressBarView} from "@amzn/dolphin-uiweb-framework";
import {ScrubContext} from "../context/ScrubContext";
import PropTypes from 'prop-types';
import {SCRUB_ROUTE_PATH} from "../../../router/ScrubRouter";
import {ScrubDataStore} from "../DataStore/ScrubDataStore";

const ScrubPackageReview = (props) => {
    const {scrubActions} = useContext(ScrubContext)

    const [scrubCategoryExceptionDetails, setScrubCategoryExceptionDetails] = useState({})
    const [resolvedPackages, totalPackages] = [props.location.state.resolvedPackages, props.location.state.totalPackages]

    const endScrub = () => {
        props.history.push(SCRUB_ROUTE_PATH.SCRUB_COMPLETE)
    }

    const continueScrub = () => {
        scrubActions.fetchScrubExceptionDetails(scrubActions.setScrubExceptionDetails, scrubActions.errorNotification)
        props.history.push(SCRUB_ROUTE_PATH.SCRUB_PACKAGE_LIST)
    }

    const [data, setData] = useState([])

    useEffect(() => {
        const packageInfo = [{
            description: props.intl.formatMessage({id: "missing_packages_scrub"}),
            title: Object.keys(scrubCategoryExceptionDetails).length
        }]
        setData(packageInfo)
    }, [scrubCategoryExceptionDetails])

    useEffect(() => {
        if (ScrubDataStore.getScrubCategoryExceptionDetails() !== null) {
            setScrubCategoryExceptionDetails(ScrubDataStore.getScrubCategoryExceptionDetails())
        }
    })

    return (
        <div className="content">
            <Column height="100%" heights={["fill", "fit"]}>
                <Column>
                    <ProgressBarView currentCount={resolvedPackages} totalCount={totalPackages}
                                     primaryColor={"#00A8E1"}/>
                </Column>
                <Column height="100%" heights="fill" spacingInset="medium">
                    <Column width="100%" alignmentHorizontal="center">
                        <div tabIndex={0}>
                            <Text type="h100">
                                {props.intl.formatMessage({id: "pending_scrub"})}
                            </Text>
                            <Text type="h100">
                                {props.intl.formatMessage({id: "ps_end_scrubbing"})}
                            </Text>
                        </div>
                        <Column width={"100%"} spacingInset={"medium"}>
                            <DetailsCardView snapshots={data} headerFontSize="Small" display="Column"/>
                        </Column>
                    </Column>
                    <Column width="100%" alignmentVertical={"bottom"}>
                        <Button size="large" type="secondary" onClick={endScrub}>
                            {props.intl.formatMessage({id: "scrub_end_button_text"})}
                        </Button>
                        <Button size="large" type="primary" onClick={continueScrub}>
                            {props.intl.formatMessage({id: "move_ahead"})}
                        </Button>
                        <div className="spaceBlockerMedium"/>
                    </Column>
                </Column>
            </Column>
        </div>
    )
}

ScrubPackageReview.propTypes = {
    history: PropTypes.object,
    intl: PropTypes.object,
    location: PropTypes.object
};

export default ScrubPackageReview