import {callAPI} from "../network/base/NetworkInterface";
import {HTTP_METHOD} from "../constants/Constants";
import {Logger} from "@amzn/dolphin-web-framework";

const StationPickingServiceEndpoint = "/sps/package/";

const ENDPOINTS = {
    planforpackage: "plan"
}

export const SPSClient = {
    getPlanForPackage: async (args) => {
        Logger.log.info("Request: planforpackage, Args: ", args);
        return await callAPI({
            method: HTTP_METHOD.GET,
            endpoint: StationPickingServiceEndpoint + ENDPOINTS.planforpackage,
            data: args
        });
    }
}