import React, {useEffect, useState} from "react"
import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";
import Button from "@amzn/meridian/button";
import {ROUTE_PATH} from "../../../router/routing";
import PropTypes from 'prop-types';
import {ScrubDataStore} from "../DataStore/ScrubDataStore";
import Alert from "@amzn/meridian/alert";
import {SoundManager} from "../../../audio/SoundManager";

const ScrubCompleteView = (props) => {

    const [scrubCategoryExceptionDetails, setScrubCategoryExceptionDetails] = useState({})

    const finishScrub = () => {
        props.history.push(ROUTE_PATH.HOME)
    }

    useEffect(() => {
        SoundManager.playSuccessSound();
        if (ScrubDataStore.getScrubCategoryExceptionDetails() !== null) {
            setScrubCategoryExceptionDetails(ScrubDataStore.getScrubCategoryExceptionDetails())
        }
    },[]);

    return (
        <div className="content">
            <Column height="100%" heights="fill" spacingInset="medium">
                <Column alignmentHorizontal="center">
                    <Alert type="success" size="xlarge"/>
                    <Text type="h200">
                        {props.intl.formatMessage({id: "scrub_end"})}
                    </Text>
                    <Text type="b300">
                        {props.intl.formatMessage(
                            {id: "pending_scrub_end"},
                            {count: Object.keys(scrubCategoryExceptionDetails).length}
                        )}
                    </Text>
                </Column>
                <Column width="100%" alignmentVertical={"bottom"}>
                    <Button size="large" type="primary" onClick={finishScrub}>
                        {props.intl.formatMessage({id: "scrub_end_button_text"})}
                    </Button>
                    <div className="spaceBlockerMedium"/>
                </Column>
            </Column>
        </div>
    )
}

ScrubCompleteView.propTypes = {
    history: PropTypes.object,
    intl: PropTypes.object
};

export default ScrubCompleteView