import React, {useContext, useEffect, useState} from "react"
import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";
import containerImage from "../../../assets/images/ic_containerize.png";
import Button from "@amzn/meridian/button";
import {ScrubContext} from "../context/ScrubContext";
import NotificationView from "../NotificationView";
import ScrubScanner from "./ScrubScanner";
import {handleScrubResponse, isNotEmptyObject} from "./Utils";
import {Loader, ProgressBarView} from "@amzn/dolphin-uiweb-framework";
import PropTypes from 'prop-types';
import {SCRUB_ROUTE_PATH} from "../../../router/ScrubRouter";
import {ScrubDataStore} from "../DataStore/ScrubDataStore";

const ScrubContinueOnScan = (props) => {
    const history = props.history;
    const {state : {
        loading = true,
    } = {}, scrubActions} = useContext(ScrubContext)

    const [currentState, setCurrentState] = useState("")
    const [totalPackages, setTotalPackages] = useState(0)
    const [scrubCategoryExceptionDetails, setScrubCategoryExceptionDetails] = useState({})
    const [scrubPackageResponse, setScrubPackageResponse] = useState({})
    const [resolvedPackages, setResolvedPackages] = useState(0)

    const {userOutput: {
            displayOutput = {currentState : ""}
        }= {} } = scrubPackageResponse

    const PROBLEM_SOLVE_BIN = "Problem Solve bin"

    const toggleContinueDisplay = () => {
        scrubActions.resetScrubPackageResponse()
        scrubActions.fetchScrubExceptionDetails(scrubActions.setScrubExceptionDetails, scrubActions.errorNotification)
        history.push(SCRUB_ROUTE_PATH.SCRUB_PACKAGE_LIST)
    }

    const onSuccess = () => {
        scrubActions.incrementResolvedPackage()
        scrubActions.fetchScrubCount(scrubActions.setScrubCount,
            scrubActions.errorNotification)
    }

    useEffect(() => {
        if(displayOutput) {
            setCurrentState(displayOutput.currentState)
        } else {
            setCurrentState(PROBLEM_SOLVE_BIN)
        }
    }, [displayOutput])

    useEffect(() => {
        if(isNotEmptyObject(scrubPackageResponse)) {
            const notification = handleScrubResponse(props, scrubPackageResponse, totalPackages, resolvedPackages, onSuccess)
            if(notification) {
                scrubActions.setNotification(notification)
            }
        }
    }, [scrubPackageResponse])

    useEffect(() => {
        if(ScrubDataStore.getScrubCategoryExceptionDetails() !== null) {
            setScrubCategoryExceptionDetails(ScrubDataStore.getScrubCategoryExceptionDetails())
        }
        if(ScrubDataStore.getResolvedPackages() !== null) {
            setResolvedPackages(ScrubDataStore.getResolvedPackages())
        }
        if(ScrubDataStore.getScrubCategoryMap() !== null) {
            const categoryMap = ScrubDataStore.getScrubCategoryMap()
            const count = categoryMap.filter(data => data.queueName === "ALL").map(data => data.exceptionCount)
            setTotalPackages(count[0] + resolvedPackages)
        }
    })

    useEffect(() => {
        if(!loading) {
            if(ScrubDataStore.getScannedPackageDetails() !== null) {
                setScrubPackageResponse(ScrubDataStore.getScannedPackageDetails())
            }
        }
    }, [loading])

    useEffect(() => {
        setTotalPackages(Object.keys(scrubCategoryExceptionDetails).length + resolvedPackages)
    }, [scrubCategoryExceptionDetails])

    return (
        <div className="content">
            <Column height="100%" heights={["fit", "fill"]}>
                <Column spacingInset="none">
                    <ProgressBarView currentCount={resolvedPackages} totalCount={totalPackages}
                                     primaryColor={"#00A8E1"}/>
                    {loading && <Loader/>}
                    <NotificationView/>
                    <ScrubScanner/>
                    <Column alignmentHorizontal={"center"}>
                        <Text type="h200">
                            {props.intl.formatMessage({id: "move_package_to"})}
                        </Text>
                        {currentState && <Text type="h200">{currentState}</Text>}
                        <img src={containerImage} alt="Container" height="fit-content"
                             style={{width: '50%'}}/>
                    </Column>
                </Column>
                <Column width="100%" spacingInset="medium" alignmentVertical="bottom">
                    <Column width="100%" spacing={"none"}>
                        <Button onClick={toggleContinueDisplay} size="large" type="secondary">
                            {props.intl.formatMessage({id: "move_ahead"})}
                        </Button>
                        <div className="spaceBlockerMedium"/>
                    </Column>
                </Column>
            </Column>
        </div>
    )
}

ScrubContinueOnScan.propTypes = {
    history: PropTypes.object,
    intl: PropTypes.object
};

export default ScrubContinueOnScan