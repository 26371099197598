import React, {useEffect} from 'react';
import InProgress from "./InProgress";
import {getDisplayName} from "../../handler/TranslationHandler";
import PropTypes from 'prop-types';
import {getRegion, getStage} from "../../config/NetConf";
import {Logger, PWADomainHelper} from "@amzn/dolphin-web-framework";

const DolphinModuleRedirector = ({location, history, intl}) => {

    useEffect(() => {
        if (location.state && location.state.properties
            && location.state.properties.targetModule) {
            try {
                const baseURLData = PWADomainHelper.getVersionedBaseURLForModule(location.state.properties.targetModule);
                console.log(baseURLData);
                let baseURL = baseURLData.url[getStage()][getRegion()];
                if(baseURLData.version) {
                    baseURL += baseURLData.version + "/index.html";
                }
                const queryParams = PWADomainHelper.getInitializationQueryParams();
                window.location.replace(baseURL + "?" + queryParams);
            } catch (e) {
                Logger.log.error("Unable to redirect " + e);
                history.goBack();
            }
        } else {
            history.goBack();
        }
    }, []);

    return (<InProgress text={getDisplayName(intl, "loading")}/>);
};

DolphinModuleRedirector.propTypes = {
    history: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
};
export default DolphinModuleRedirector;