import {Route, Switch} from "react-router-dom";
import Left2FixFilterView from "../components/left2fix/Left2FixFilterView";
import Left2FixShipmentDetailsView from "../components/left2fix/LeftToFixShipmentDetailsView";
import React from "react";
import {injectIntl} from "react-intl";

export const LEFT_TO_FIX_ROUTE_PATH = {
    LEFT_TO_FIX_FILTER:"/problemsolve/left2fix",
    LEFT_TO_FIX_SHIPMENT_DETAILS:"/problemsolve/left2fix/shipmentDetails"

};

export default function Left2FixRouter () {
    return (
        <Switch>
            <Route exact path={LEFT_TO_FIX_ROUTE_PATH.LEFT_TO_FIX_FILTER} component={Left2FixFilterView} />
            <Route exact path={LEFT_TO_FIX_ROUTE_PATH.LEFT_TO_FIX_SHIPMENT_DETAILS} component={injectIntl(Left2FixShipmentDetailsView)} />
        </Switch>
    )
}