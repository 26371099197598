import React, {Component} from "react";
import Routing from "../router/routing";
import {withRouter} from "react-router-dom";
import PropTypes from 'prop-types';
import {MeshClient} from "@amzn/dolphin-web-framework";
import {Loader} from "@amzn/dolphin-uiweb-framework";
import {FeatureManager} from "@amzn/dolphin-web-framework";
import {getRegion, getStage} from "../config/NetConf";


class App extends Component {

  constructor(props) {
    super(props);
    this.state = {loading: true};
  }

  async componentWillMount() {
    await MeshClient.connectWebSocket();
    await FeatureManager.getFeatures(getStage(), getRegion());
    this.setState({loading: false})
  }

  componentWillUnmount() {
    MeshClient.closeWebSocketConnection();
  }

  render() {
    return (
        <>
        {this.state.loading? <Loader/> : <Routing/>}
        </>
    )
  }
}

App.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object
};
export default withRouter(props => <App {...props} />);