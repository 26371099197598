import React, {useReducer} from "react";
import Box from "@amzn/meridian/box";
import InProgress from "../../common/InProgress";
import NotificationMessage from "../../common/NotificationMessage";

import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";
import {FormattedMessage} from "react-intl";
import {
    STAGE_ITEM_REDUCERS_ACTION_TYPE,
    StageItemInitialState,
    StageItemReducer
} from "../../../reducers/StageItemReducer";
import {ScannedPackagesListView} from "../../scannedpackageslist/ScannedPackagesListView";
import {COMMON_PROBLEM_SOLVE_PREFIX, ERROR_CODES, PROBLEM_CATEGORY_STRING_PREFIX} from "../../../constants/Constants";
import {getProblemCategory, getScannedScannableIds, getShipmentDetail} from "../../../dataStore/PSExecutionDataStore";
import {
    getContainerTaskId,
    getStageAreaLabel,
    getStageAreaLocation,
    updateNextComponentDetails
} from "../../../helper/ActionComponentsHelper";
import {getEntityType, getPickListId} from "../../../utils/ShipmentUtils";
import PropTypes from 'prop-types';
import ScanBox from "../../scanbox/GenericScanBox";
import QRCode from "../../../assets/images/ic_qr_code.png";
import Row from "@amzn/meridian/row";
import {ERROR_SCREEN_ERROR_TYPE} from "../../../constants/ErrorStringConstants";
import {PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS} from "../../../router/ProblemSolveExecutionRouter";

const StageSolutionAction = ({history, location}) => {
    const[{loading, notificationType, notificationMessageId, loadNextComponent, nextComponentDetails, exceptionResolutionResult},
        dispatch] = useReducer(StageItemReducer, StageItemInitialState)

    const metaData = (location.state)? location.state.metaData : {};

    if (loadNextComponent) {
        updateNextComponentDetails()

        const additionalInformation = exceptionResolutionResult.additionalInformation &&
            exceptionResolutionResult.additionalInformation[getScannedScannableIds()[0]];
        history.push({
            pathname: `${COMMON_PROBLEM_SOLVE_PREFIX}${nextComponentDetails.component}`,
            state: {
                properties: nextComponentDetails.properties,
                metaData: {
                    stageArea:  additionalInformation && additionalInformation.scannedLabel ?
                        additionalInformation.scannedLabel : getStageAreaLabel(),
                    packageType: getEntityType(getShipmentDetail())
                }
            }
        });
        return <div/>
    }

    if (exceptionResolutionResult.isError) {
        const errorCode = exceptionResolutionResult.errorCodes &&
            exceptionResolutionResult.errorCodes[getScannedScannableIds()[0]];

        if((errorCode === ERROR_CODES.INCORRECT_STAGING_LOCATION || errorCode === ERROR_CODES.INVALID_DESTINATION_LOCATION)
            && metaData.isStagingToCart) {
            // create that popup screen
            history.push({
                pathname: PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.ERROR_SCREEN,
                state: {
                    errorType: ERROR_SCREEN_ERROR_TYPE.INVALID_CART,
                    metaData: {
                        cartLabel: metaData.cartLabel,
                        stagingAreaLabel: metaData.stagingAreaLabel
                    }
                }
            });
        } else {
            dispatch({
                type: STAGE_ITEM_REDUCERS_ACTION_TYPE.ERROR,
                dispatcher: dispatch
            })
        }
    }

    const onScanningStage = (value) => {
        // checking in the frontend only when not staging to cart
        if(!metaData.isStagingToCart && getStageAreaLocation() !== value) {
            dispatch({
                type: STAGE_ITEM_REDUCERS_ACTION_TYPE.INCORRECT_STAGE_LOCATION_SCANNED,
                dispatcher: dispatch
            })
        } else {
            dispatch({
                type: STAGE_ITEM_REDUCERS_ACTION_TYPE.EXECUTE_PROBLEM_ACTION_API,
                data: {
                    stageArea: value,
                    pickListId: getPickListId(getShipmentDetail()),
                    entityType: getEntityType(getShipmentDetail()),
                    containerTaskId: getContainerTaskId()
                },
                dispatcher: dispatch
            })
        }
    };

    return (
        <Column height="100%" heights={["75%", "20%"]}>
            <Box>
                {loading &&
                <InProgress/>
                }
                {notificationType &&
                <NotificationMessage dispatch={dispatch} actionType={STAGE_ITEM_REDUCERS_ACTION_TYPE.CLOSE_NOTIFICATION}
                                     type={notificationType} messageId={notificationMessageId}/>}
                <Box spacingInset="medium medium small medium">
                    <Text type="h300" alignment="center"><FormattedMessage id={"action_stage_item"}/></Text>
                </Box>
                <ScanBox titleId={metaData.titleId} titleParams={metaData.titleParams} onScan={onScanningStage}/>
                <Column alignmentHorizontal="center">
                    <Row spacing={"none"} width="70%">
                        <img aria-hidden={true} src={QRCode} alt="Stage Location Scan" height="fit-content" style={{width: '100%'}}/>
                    </Row>
                </Column>
            </Box>
            <ScannedPackagesListView rightHeader={PROBLEM_CATEGORY_STRING_PREFIX + getProblemCategory()}/>
        </Column>
    )
}

StageSolutionAction.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object
}

export default StageSolutionAction