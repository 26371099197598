import {APIEvents} from "../../../constants/AnalyticsEventKeys";
import {MobileAnalyticsHelper} from "@amzn/dolphin-web-framework";
import {getNPSWClient} from "../../base/ClientInterface";

export function unassignRoute(data, dispatcher, nextAction, errorAction) {
    let startTime = Date.now();
    getNPSWClient().unassignRoute(data)
        .then(({success, data})=>{
            if(success && data.success) {
                dispatcher({
                    type: nextAction,
                    data: data,
                    dispatcher: dispatcher
                });
                MobileAnalyticsHelper.executeAPIAnalytics(APIEvents.UnassignRoute, startTime);
            } else {
                dispatcher({
                    type: errorAction
                });
                MobileAnalyticsHelper.executeAPIAnalytics(APIEvents.UnassignRoute, startTime, true);
            }
    });
}