import React, {useReducer, useState} from "react";
import DriverEntityCommon from "./DriverEntityCommon";
import Box from "@amzn/meridian/box";
import Text from "@amzn/meridian/text";
import {FormattedMessage, injectIntl} from "react-intl";
import Select, {SelectOption} from "@amzn/meridian/select";
import {getDisplayName} from "../../handler/TranslationHandler";
import Column from "@amzn/meridian/column";
import Button from "@amzn/meridian/button";
import {withRouter} from "react-router";
import {
    DRIVER_ENTITY_REDUCER_ACTION_TYPE,
    DriverEntityReducer,
    DriverEntityReducerInitialState
} from "../../reducers/DriverEntityReducer";
import InProgress from "../common/InProgress";
import NotificationMessage from "../common/NotificationMessage";
import {getProblemCategory} from "../../dataStore/PSExecutionDataStore";
import PropTypes from 'prop-types';

const DriverOverrideReason = ({location, intl, history}) => {
    const [overrideReason, setOverrideReason] = useState("")

    const [{loading, notificationType, notificationMessageId}, dispatch] =
        useReducer(DriverEntityReducer, DriverEntityReducerInitialState)

    const transporter = location.state.transporter
    const dropDownValue = getDisplayName(intl, "choose_reason")
    const reasonList = [{
            reason: "App issue",
            messageId: "ps_app_issue",
        },
        {
            reason: "No picklist",
            messageId: "ps_no_picklist"
        }]

    const onContinue = () => {
        dispatch ({
            type: DRIVER_ENTITY_REDUCER_ACTION_TYPE.CALL_UPDATE_EXCEPTION_TRASPORTER,
            dispatcher: dispatch,
            data: {
                transporterId: transporter.transporterId,
                problemCategory: getProblemCategory(),
                transporterName: transporter.transporterName,
                overrideReason: overrideReason
            }
        })
    }

    return (
        <Column  height="100%" heights={["fill","fit"]}>
            <Column>
                {loading && <InProgress/>}
                {notificationType &&
                <NotificationMessage dispatch={dispatch} actionType={DRIVER_ENTITY_REDUCER_ACTION_TYPE.CLOSE_NOTIFICATION}
                                     type={notificationType} messageId={notificationMessageId}/>}
                <DriverEntityCommon transporter={transporter}/>
                <Box spacingInset="none medium small medium">
                    <Text type="h300"><FormattedMessage id={"select_reason_override"}/></Text>

                    <Select size="medium" value={overrideReason} placeholder={dropDownValue}
                            onChange={setOverrideReason}>
                        {reasonList.map((value) => (
                            <SelectOption label={intl.formatMessage({id: value.messageId})} value={value.reason}
                                          key={value}/>))}
                    </Select>
                </Box>
            </Column>
            <Column width="100%" alignmentVertical="bottom" spacingInset="medium">
                {overrideReason !== "" &&
                    <Button size="large" onClick={() => onContinue()}><FormattedMessage id="continue_solving"/></Button>}
                {overrideReason !== "" &&
                    <Button size="large" type="secondary" onClick={() => history.goBack()}><FormattedMessage id="cancel_text"/></Button>}
                <div className="spaceBlockerMedium"/>

            </Column>
        </Column>
    )
}

DriverOverrideReason.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    intl: PropTypes.object
};

export default withRouter(injectIntl(DriverOverrideReason))