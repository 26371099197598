const LabelType = {
    "SLAM_LABEL": "DEFAULT",
    "WRONG_CARRIERCARRIER_MISSORT": "SORT_ASSIST_WRONG_CARRIER",
    "WRONG_STATION_SAME_CITYMILK_RUN": "SORT_ASSIST_WRONG_STATION_MILK_RUN",
    "WRONG_STATION_SAME_CITYMANUAL_RETURN_TO_FC": "SORT_ASSIST_WRONG_STATION_MANUAL_RTO",
    "PICK_DAMAGEDDAMAGED_UNSALVAGEABLE": "PICK_DAMAGED_UNSALVAGEABLE",
    "STOW_DAMAGEDDAMAGED_UNSALVAGEABLE": "STOW_DAMAGED_UNSALVAGEABLE",
    "DAMAGED_PACKAGEDAMAGED_SALVAGEABLE": "ACTIVE_LABEL",
    "DAMAGED_PACKAGEDAMAGED_UNSALVAGEABLE": "DAMAGED_PACKAGE_UNSALVAGEABLE",
    "CUSTOMER_CANCELEDREADY_FOR_FC_RETURN": "ACTIVE_LABEL",
    "RTS_DAMAGEDDAMAGED_UNSALVAGEABLE": "DAMAGED_PACKAGE_UNSALVAGEABLE",
    "RTS_DAMAGEDRTS_DAMAGED_SALVAGEABLE": "ACTIVE_LABEL",
    "PRINT_LABELRTS_DAMAGED_SALVAGEABLE": "ACTIVE_LABEL",
    "LOST_BUT_FOUNDLABEL_PRINT": "ACTIVE_LABEL",
    "WRONG_PLANNED_STATIONWRONG_PLANNED_STATION_TRANSFER": "SORT_ASSIST_WRONG_STATION_MILK_RUN",
    "PALLET_LABEL": "PALLET_LABEL",
    "RTO_DAMAGEDRTO_UNSALVAGEABLE": "ACTIVE_LABEL",
    "RTO_WRONG_PLANNED_STATIONRTO_REPLAN": "ACTIVE_LABEL",
    "PRINT_REVERSE_LABELRTO_PRINT_LABEL": "ACTIVE_LABEL",
    "INDUCT_PRINT_LABELINDUCT_RELABEL_REQUIRED": "ACTIVE_LABEL",
    "WRONG_CARRIER_V2NETWORK_MISSORT_REPLAN": "ACTIVE_LABEL",
    "WRONG_STATION_SAME_CITY_V2NETWORK_MISSORT_REPLAN": "ACTIVE_LABEL"
};

export const getLabelType = (labelType) => {
    return LabelType[labelType];
};