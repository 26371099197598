import {LeftToFixQueue} from "../constants/LeftToFixQueue";
import {getExceptionShipmentSummaryDetails} from "../network/apis/npsw/GetExceptionShipmentSummaryDetailsAPI";
import {LEFT_TO_FIX_REDUCERS_ACTION_TYPE} from "../reducers/LeftToFixReducer";
import {LeftToFixFiterKey} from "../constants/Left2FixFilterKey";
import {getCategoriesDisplayName, getDisplayName} from "../handler/TranslationHandler";
import moment from "moment";
import {PROBLEM_CATEGORY_STRING_PREFIX} from "../constants/Constants";

const KEY_ALL_CATEGORY_QUEUE = "ALL_CATEGORY_QUEUE"


export function fetchExceptionShipmentSummaryCount(getCategoriesForNodeIdResponse, dispatcher) {
    const categories =  getCategoriesForNodeIdResponse[KEY_ALL_CATEGORY_QUEUE]
    getExceptionShipmentSummaryDetails({
        category: categories,
        startDate: getStartTimeForSummaryDetails(),
        endDate: new Date().toString(),
        shipmentDetailNotRequired: true
    }, dispatcher, LEFT_TO_FIX_REDUCERS_ACTION_TYPE.SET_EXCEPTION_CATEGORIES_TO_COUNT, LEFT_TO_FIX_REDUCERS_ACTION_TYPE.ERROR)
}

export function getStartTimeForSummaryDetails() {
    //set start date as immediate previous 7:30pm in current or previous day
    const now = new Date()
    const hour = now.getHours()
    const startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 19, 30)
    if(hour < 19 ||
        (hour === 19 && now.getMinutes()<30)) {
        startDate.setDate(now.getDate() - 1)
    }
    return startDate.toString()
}

export function fetchQueueToCount(getExceptionShipmentSummaryResponse, getCategoriesForNodeIdResponse) {
    let exceptionQueueToCount = []
    const exceptionShipmentSummaryReport = getExceptionShipmentSummaryResponse.exceptionShipmentSummaryReport
    for (let key in LeftToFixQueue ) {
        let exceptionCount = 0
        getCategoriesForNodeIdResponse[key].forEach( (category) => {
            exceptionCount+= exceptionShipmentSummaryReport[category].OPEN.length
        })
        exceptionQueueToCount.push({queueName: key, exceptionCount: exceptionCount })
    }
    return exceptionQueueToCount
}

export function fetchShipmentSummaryDetails(selectedQueueDetails, queueToCategories, dispatcher) {
        let categories = queueToCategories[selectedQueueDetails.queueName]
        getExceptionShipmentSummaryDetails({
            category: categories,
            startDate: getStartTimeForSummaryDetails(),
            endDate: new Date().toString(),
        }, dispatcher, LEFT_TO_FIX_REDUCERS_ACTION_TYPE.SET_EXCEPTION_SHIPMENT_SUMMARY_DETAILS, LEFT_TO_FIX_REDUCERS_ACTION_TYPE.ERROR)
}

export function fetchOpenShipmentDetails(exceptionShipmentSummaryDetails) {
    let openExceptionShipmentsWithDetails = []
    Object.values(exceptionShipmentSummaryDetails)
        .map((categoryOpenResolvedShipmentDetails) => categoryOpenResolvedShipmentDetails.OPEN)
        .filter((categoryOpenShipmentDetails) => categoryOpenShipmentDetails.length > 0)
        .forEach((categoryShipmentDetails) =>
            categoryShipmentDetails.forEach( (shipmentDetails) => {
                openExceptionShipmentsWithDetails.push(shipmentDetails)
            }))
    return openExceptionShipmentsWithDetails
}

export function fetchLeftToFixFilters(openExceptionShipmentsWithDetails){
    let leftToFixFilters = []
    for(let filter in LeftToFixFiterKey) {
        let filterKey = LeftToFixFiterKey[filter]
        for (let i = 0; i < openExceptionShipmentsWithDetails.length; i++) {
            if(openExceptionShipmentsWithDetails[i][filterKey]){
                leftToFixFilters.push(filterKey)
                break
            }
        }
    }
    return leftToFixFilters
}

export const getDataForDetailsCardView = (shipment, intl) => {
    var data = [];
    data.push({description: getDisplayName(intl, "scannable_id"), title: shipment.scannableId});
    if(shipment.category) {
        data.push({
            description: getDisplayName(intl, "ltf_sd_category"),
            title: getCategoriesDisplayName(intl, PROBLEM_CATEGORY_STRING_PREFIX + shipment.category)
        });
    }
    if(shipment.solvedDate) {
        data.push({
            description: getDisplayName(intl, "ltf_sd_solved_date"),
            title: moment(shipment.solvedDate).fromNow()
        });
    }
    if(shipment.location) {
        data.push({
            description: getDisplayName(intl, "ltf_group_location"),
            title: shipment.location
        });
    }
    if(shipment.sortZone) {
        data.push({description: getDisplayName(intl, "ltf_sd_sort_zone"), title: shipment.sortZone});
    }
    if(shipment.shipmentStatusCode) {
        data.push({
            description: getDisplayName(intl, "ltf_sd_shipment_status_code"),
            title: shipment.shipmentStatusCode
        });
    }
    if(shipment.nextDestinationType) {
        data.push({
            description: getDisplayName(intl, "ltf_sd_next_destination_type"),
            title: shipment.nextDestinationType
        });
    }
    if (shipment.packagePhysicalAttributes) {
        data.push({
            title: getDisplayName(intl, "length")+" "+ shipment.packagePhysicalAttributes.length + " " + shipment.packagePhysicalAttributes.lengthUnit,
            padding: "none none none small"
        });
        data.push({
            title: getDisplayName(intl, "width")+" "+ shipment.packagePhysicalAttributes.width + " " + shipment.packagePhysicalAttributes.widthUnit,
            padding: "none none none small"
        });
        data.push({
            title: getDisplayName(intl, "height")+" "+ shipment.packagePhysicalAttributes.height + " " + shipment.packagePhysicalAttributes.heightUnit,
            padding: "none none none small"
        });
        data.push({
            title: getDisplayName(intl, "weight")+" "+ shipment.packagePhysicalAttributes.weight + " " + shipment.packagePhysicalAttributes.weightUnit,
            description: getDisplayName(intl, "ltf_sd_package_physical_attributes"),
            padding: "none none none small"
        });
    }
    return data;
};