import intlMessageDE from '../strings/de.json';
import intlMessageEN from '../strings/default.json';
import intlMessageES from '../strings/es.json';
import intlMessageFR from '../strings/fr.json';
import intlMessageHI from '../strings/hi.json';
import intlMessageIT from '../strings/it.json';
import intlMessageJP from '../strings/jp.json';
import intlMessagePT from '../strings/pt.json';
import intlMessageRO from '../strings/ro.json';
import intlMessageAR from '../strings/ar.json';
import intlMessageTR from '../strings/tr.json';
import intlMessageNL from '../strings/nl.json';

import {addLocaleData} from "react-intl";
import de from 'react-intl/locale-data/de'
import en from 'react-intl/locale-data/en'
import es from 'react-intl/locale-data/es'
import fr from 'react-intl/locale-data/fr'
import hi from 'react-intl/locale-data/hi'
import it from 'react-intl/locale-data/it'
import ja from 'react-intl/locale-data/ja'
import pt from 'react-intl/locale-data/pt'
import ro from 'react-intl/locale-data/ro'
import ar from 'react-intl/locale-data/ar'
import tr from 'react-intl/locale-data/tr'
import nl from 'react-intl/locale-data/nl'
import {DataHandler} from "@amzn/dolphin-web-framework";
import {CommonLocaleManagerInstance} from "@amzn/dolphin-uiweb-framework";

addLocaleData(de);
addLocaleData(en);
addLocaleData(es);
addLocaleData(fr);
addLocaleData(hi);
addLocaleData(it);
addLocaleData(ja);
addLocaleData(pt);
addLocaleData(ro);
addLocaleData(ar);
addLocaleData(nl);
addLocaleData(tr);

export function getLocale() {
    const locale = DataHandler.getUserLocale();
    switch (locale) {
        case "de":
        case "de-CH":
        case "de-AT":
        case "de-LU":
        case "de-LI":
            return "de"
        case "es":
        case "es-AR":
        case "es-GT":
        case "es-CR":
        case "es-PA":
        case "es-DO":
        case "es-MX":
        case "es-VE":
        case "es-CO":
        case "es-PE":
        case "es-EC":
        case "es-CL":
        case "es-UY":
        case "es-PY":
        case "es-BO":
        case "es-SV":
        case "es-HN":
        case "es-NI":
        case "es-PR":
            return "es"
        case "fr":
        case "fr-BE":
        case "fr-CA":
        case "fr-CH":
        case "fr-LU":
            return "fr"
        case "hi":
        case "hi-IN":
            return "hi"
        case "it":
        case "it-CH":
            return "it"
        case "ja":
        case "jp":
            return "ja"
        case "pt":
        case "pt-BR":
        case "pt-PT":
            return "pt"
        case "ro":
        case "ro-RO":
            return "ro"
        case "ar":
        case "ar-SA":
        case "ar-IQ":
        case "ar-EG":
        case "ar-LY":
        case "ar-DZ":
        case "ar-MA":
        case "ar-TN":
        case "ar-OM":
        case "ar-YE":
        case "ar-SY":
        case "ar-JO":
        case "ar-LB":
        case "ar-KW":
        case "ar-AE":
        case "ar-BH":
        case "ar-QA":
            return "ar"
        case "nl":
        case "nl-NL":
        case "nl-BE":
            return "nl"
        case "tr":
        case "tr-TR":
            return "tr"
        default:
            return "en"
    }
}

export const getDirection = () => {
    return getLocale() === "ar" ? "rtl" : "ltr";
}

export function getMessages() {
    const locale = getLocale();
    let messages;
    switch (locale) {
        case "de":
            messages = intlMessageDE;
            break;
        case "es":
            messages = intlMessageES;
            break;
        case "fr":
            messages = intlMessageFR;
            break;
        case "hi":
            messages = intlMessageHI;
            break;
        case "it":
            messages = intlMessageIT;
            break;
        case "ja":
            messages = intlMessageJP;
            break;
        case "pt":
            messages = intlMessagePT;
            break;
        case "ro":
            messages = intlMessageRO;
            break;
        case "ar":
            messages = intlMessageAR;
            break;
        case "nl":
            messages = intlMessageNL;
            break;
        case "tr":
            messages = intlMessageTR;
            break;
        default:
            messages = intlMessageEN;
    }
    return {...CommonLocaleManagerInstance.getMessages(), ...messages}
}