import React, {useReducer, useState} from 'react';
import {withRouter} from 'react-router-dom';
import Box from "@amzn/meridian/box";
import {FormattedMessage, injectIntl} from "react-intl";
import ScanBox from "../scanbox/GenericScanBox";
import Text from "@amzn/meridian/text";
import {SnapshotView, TaskView} from "@amzn/dolphin-uiweb-framework";
import {getDisplayName} from "../../handler/TranslationHandler";
import Column from "@amzn/meridian/column";
import Button from "@amzn/meridian/button";
import {
    UNASSIGN_ROTUE_ACTION_TYPE,
    UnassignRouteInitialState,
    UnassignRouteReducer
} from "../../reducers/UnassignRouteReducer";
import InProgress from "../common/InProgress";
import PropTypes from 'prop-types';
import {ROUTE_PATH} from "../../router/routing";
import NotificationMessage from "../common/NotificationMessage";

const UnassignRoute = ({history, intl}) => {
    const [routeCode, setRouteCode] = useState("");
    const [{loading, notificationType, notificationMessageId}, dispatch] = useReducer(UnassignRouteReducer, UnassignRouteInitialState);

    const handleOnScan = (route) => {
        console.log("Route scanned" + route);
        setRouteCode(route);
    };
    const handleRouteUnassign = () => {
        dispatch({
            type: UNASSIGN_ROTUE_ACTION_TYPE.UNASSIGN_ROUTE,
            dispatcher: dispatch,
            data: "routeCode=" + routeCode
        })
    };

    const task = {
        title: getDisplayName(intl, "unassign_route"),
        description: getDisplayName(intl, "unassign_route_details"),
        onClick: () => handleRouteUnassign()
    };

    return (
        <Column height="100%" heights={["fill","fit"]}>
            <Column>
                {loading && <InProgress/>}
                {notificationType &&
                <NotificationMessage dispatch={dispatch} actionType={UNASSIGN_ROTUE_ACTION_TYPE.CLOSE_NOTIFICATION}
                                     type={notificationType} messageId={notificationMessageId}/>}
                <ScanBox titleId="scan_route_id" onScan={(route) => handleOnScan(route)}/>
                {routeCode &&
                <div>
                    <SnapshotView title={getDisplayName(intl, "route_code_name")}
                                  description={routeCode} headerFontSize="Medium"
                                  padding="medium"/>

                    <Box spacingInset="medium">
                        <Text type="h300"><FormattedMessage id="whats_the_problem"/></Text>
                    </Box>
                    <TaskView tasks={[task]}/>
                </div>
                }
            </Column>
            <Column width="100%" spacingInset="medium" alignmentVertical="top">
                <Button size="large" type="secondary"
                        onClick={() => history.push(ROUTE_PATH.SELECT_ENTITY)}>
                    <FormattedMessage id="restart"/>
                </Button>
            </Column>
        </Column>
    );
};
UnassignRoute.propTypes = {
    history: PropTypes.object,
    intl: PropTypes.object
};
export default withRouter(injectIntl(UnassignRoute));