import {ResponseStatus, ScrubResponseCode, PS_STATION_SWEEP} from "../constants/constants";
import {SCRUB_ROUTE_PATH} from "../../../router/ScrubRouter";
import {ScrubDataStore} from "../DataStore/ScrubDataStore";
import {SoundManager} from "../../../audio/SoundManager";

export const handleScrubResponseBasedOnResponseCode = (props, resCode, totalPackages, resolvedPackages, onSuccess) => {
    if(!resCode) {
        SoundManager.playWarningSound();
        return ("ps_scrub_not_eligible_warning");
    }
    switch (resCode) {
        case ScrubResponseCode.OPEN_EXCEPTION:
        case ScrubResponseCode.DAMAGED:
            SoundManager.playWarningSound();
            loadScrubSolveExceptionView(props,"ps_scrub_damaged_package_warning", totalPackages, resolvedPackages);
            break;
        case ScrubResponseCode.AT_WRONG_STATION:
            SoundManager.playWarningSound();
            loadScrubSolveExceptionView(props,"ps_scrub_wrong_station_warning", totalPackages, resolvedPackages);
            break;
        case ScrubResponseCode.INVALID_TRACKING_ID:
            SoundManager.playErrorSound();
            return ("invalid_tid");
        case ScrubResponseCode.DEPENDENCY_FAILURE:
            SoundManager.playWarningSound();
            return ("ps_scrub_not_eligible_warning");
        case ScrubResponseCode.NO_EXCEPTION_FOUND:
            SoundManager.playWarningSound();
            return ("ps_scrub_not_eligible_warning");
        case ScrubResponseCode.LABEL_PRINT_REQUIRED:
            break;
        case ScrubResponseCode.SUCCESS:
        default:
            SoundManager.playWarningSound();
            onSuccess()
    }
};

const loadScrubSolveExceptionView = (props, messageId, totalPackages, resolvedPackages) => {
    props.history.push({
        pathname: SCRUB_ROUTE_PATH.SCRUB_SOLVE_EXCEPTION,
        state: {
            totalPackages: totalPackages,
            resolvedPackages: resolvedPackages,
            messageId: messageId
        }
    });
};

export const handleScrubResponse = (props, scrubPackageResponse, totalPackages, resolvedPackages, onSuccess) => {
    const {userOutput = {}} = scrubPackageResponse;
    if(isNotEmptyObject(scrubPackageResponse) && isNotEmptyObject(userOutput)) {
        const {responseStatus, responseCode} = userOutput
        switch (responseStatus) {
            case ResponseStatus.SUCCESS:
                SoundManager.playSuccessSound();
                onSuccess();
                break;
            case ResponseStatus.WARNING:
                SoundManager.playWarningSound();
                onSuccess();
                break;

            case ResponseStatus.WARN:
                handleScrubResponseBasedOnResponseCode(props, responseCode, totalPackages, resolvedPackages, onSuccess)
                break;

            case ResponseStatus.ERROR:
            default:
                SoundManager.playWarningSound();
                return ("ps_scrub_not_eligible_warning")
        }
    }
}

export const getPackageCategory = (trackingId) => {
    const scrubCategoryExceptionDetails = ScrubDataStore.getScrubCategoryExceptionDetails()
    return scrubCategoryExceptionDetails && scrubCategoryExceptionDetails[trackingId] && scrubCategoryExceptionDetails[trackingId].category?
        scrubCategoryExceptionDetails[trackingId].category: PS_STATION_SWEEP
}

export const isNotEmptyObject = (obj) => {
    return obj && Object.keys(obj).length
}