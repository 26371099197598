import {callActionApi, processApiExecutionResponse} from "../helper/ActionComponentsHelper";
import {saveExceptionResolutionResult, saveProblemAction} from "../dataStore/PSExecutionDataStore";
import {NOTIFICATION_TYPE} from "../constants/Constants";
import {useHistory} from "react-router";
import {PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS} from "../router/ProblemSolveExecutionRouter";

export const HazmatScanReducerInitialState = {
    fetchingData: false,
    notificationMessageId: ""
};

export const HazmatScanReducerActionType = {
    EXECUTE_PROBLEM_ACTION_API: "EXECUTE_PROBLEM_ACTION_API",
    SET_PROBLEM_ACTION_API_RESULT: "SET_PROBLEM_ACTION_API_RESULT",
    SET_PROBLEM_ACTION_API_RESULT_ERROR: "SET_PROBLEM_ACTION_API_RESULT_ERROR",
    PRINTER_ERROR: "PRINTER_ERROR",
    SHOW_NOTIFICATION: "SHOW_NOTIFICATION",
    CLOSE_NOTIFICATION: "CLOSE_NOTIFICATION"
};
export const HazmatScanReducer = (state, action) => {
    switch (action.type) {
        case HazmatScanReducerActionType.EXECUTE_PROBLEM_ACTION_API:
            saveProblemAction("DAMAGED_SALVAGEABLE");
            callActionApi({
                    api: "updateExceptionShipments",
                    properties: {targetAction: "DAMAGED_SALVAGEABLE"},
                    additionalProperties: action.data
                },
                action.dispatch,
                HazmatScanReducerActionType.SET_PROBLEM_ACTION_API_RESULT,
                HazmatScanReducerActionType.SET_PROBLEM_ACTION_API_RESULT_ERROR,
                HazmatScanReducerActionType.PRINTER_ERROR);
            return {
                ...state,
                fetchingData: true
            };
        case HazmatScanReducerActionType.SET_PROBLEM_ACTION_API_RESULT:{
            const {exceptionResolutionResult} = processApiExecutionResponse(action.data);
            saveExceptionResolutionResult(exceptionResolutionResult);
            //eslint-disable-next-line react-hooks/rules-of-hooks
            useHistory().push({
                pathname: PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.SUCCESS_SCREEN,
                state: {
                    properties: {
                        isPrintLabelSupported: true
                    }
                }
            });
            return {
                ...state,
                fetchingData: false
            };
        }
        case HazmatScanReducerActionType.SET_PROBLEM_ACTION_API_RESULT_ERROR:
            return {
                ...state,
                fetchingData: false,
                notificationType: NOTIFICATION_TYPE.ALERT,
                notificationMessageId: "internal_error"
            };
        case HazmatScanReducerActionType.PRINTER_ERROR:
            //eslint-disable-next-line react-hooks/rules-of-hooks
            useHistory().push(({
                pathname: PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.CONNECT_PRINTER
            }));
            return {
                ...state,
                fetchingData: false,
                notificationType: NOTIFICATION_TYPE.ALERT,
                notificationMessageId: "printer_not_connect"
            };
        case HazmatScanReducerActionType.SHOW_NOTIFICATION:
            return {
                ...state, notificationType: action.data.type,
                notificationMessageId: action.data.message
            };
        case HazmatScanReducerActionType.CLOSE_NOTIFICATION:
            return {...state, notificationType: null};
        default:
            return state;
    }
};