export const getInfoBarStyleConfig = () => {
    return {'borderTop': '2px solid grey', 'backgroundColor':'#ffffff'};
};
export const getInfoBarSwiperConfig = () => {
    return {'borderTop': '2px solid grey', 'width': '40px'};
};
export const getClosedInfoBarConfig = () => {
    return {'position': 'fixed', 'bottom': '0', 'width': '100%'};
};
export const getOpenSheetHeaderBodyDividerConfig = () => {
    return {'borderTop': '1px solid grey'};
};