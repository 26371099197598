import {APIEvents} from "../constants/AnalyticsEventKeys";
import {MobileAnalyticsHelper} from "@amzn/dolphin-web-framework";
import {getNPSWClient} from "./base/ClientInterface";

export function getExceptionShipmentSummaryWithoutShipDetails(data, dispatcher, errorAction) {
    let startTime = Date.now();
    getNPSWClient().getExceptionShipmentSummaryWithoutShipDetails(data)
        .then(({success, data}) => {
            if (success) {
                dispatcher(data);
                MobileAnalyticsHelper.executeAPIAnalytics(APIEvents.ExceptionShipmentSummary, startTime);
            } else {
                errorAction("internal_error");
                MobileAnalyticsHelper.executeAPIAnalytics(APIEvents.ExceptionShipmentSummary, startTime, true);
            }
        });
}

export function getExceptionShipmentSummaryWithoutShipDetailsWithDispatcher(data, dispatcher, nextAction, errorAction) {
    let startTime = Date.now();
    getNPSWClient().getExceptionShipmentSummaryWithoutShipDetails(data)
        .then(({success, data}) => {
            if (success) {
                dispatcher({
                    type: nextAction,
                    data: data,
                    dispatcher: dispatcher
                });
                MobileAnalyticsHelper.executeAPIAnalytics(APIEvents.ExceptionShipmentSummary, startTime);
            } else {
                dispatcher({
                    type: errorAction
                });
                MobileAnalyticsHelper.executeAPIAnalytics(APIEvents.ExceptionShipmentSummary, startTime, true);
            }
        });
}