import {PROBLEM_ACTION_API} from "../constants/Constants";

const getSuccessScreenConfig = (isPrintLabelSupported, hideSolveMoreProblems) => {
  return {
    component: "successScreen",
    properties: {
      isPrintLabelSupported: isPrintLabelSupported,
      hideSolveMoreProblems: hideSolveMoreProblems,
    }
  }
};

const getRTOSuccessScreenConfig = (isPrintLabelSupported, disableBackButton) => {
  return {
    component: "rtoSuccessScreen",

    properties: {
      isPrintLabelSupported: isPrintLabelSupported,
      disableBackButton: disableBackButton,
    }
  }
};

export const problemActionConfig = {
  "DAMAGED_SALVAGEABLE": [
    {
      api: "updateExceptionShipments",
      properties: {
        targetAction: "DAMAGED_SALVAGEABLE"
      }
    },
    getSuccessScreenConfig(true, false)
  ],

  "MOVE_TO_MANIFESTED": [
    {
      api: "updateExceptionShipments",
      properties: {
        targetAction: "MOVE_TO_MANIFESTED"
      }
    },
    getSuccessScreenConfig(true)
  ],
  "SELLER_REFUSED_DISPOSE_OFF": [
    {
      api: "updateExceptionShipments",
      properties: {
        targetAction: "SELLER_REFUSED_DISPOSE_OFF"
      }
    },
    getSuccessScreenConfig(false)
  ],
  "EMPTY_PACKAGE_DISPOSE_OFF": [
    {
      api: "updateExceptionShipments",
      properties: {
        targetAction: "EMPTY_PACKAGE_DISPOSE_OFF"
      }
    },
    getSuccessScreenConfig(false)
  ],
  "DONATE_AND_DESTROY": [
    {
      api: "updateExceptionShipments",
      properties: {
        targetAction: "DONATE_AND_DESTROY"
      }
    },
    getSuccessScreenConfig(false, false)
  ],
  "WRONG_PLANNED_STATION_TRANSFER": [
    {
      component: "selectStation",
      properties: {
        targetAction: "WRONG_PLANNED_STATION_TRANSFER"
      }
    }
  ],
  "MARK_SAL_COLOR_MISMATCH": [
    {
      api: "updateExceptionShipments",
      properties: {
        targetAction: "MARK_SAL_COLOR_MISMATCH"
      }
    },
    getSuccessScreenConfig(false)
  ],
  "UNSALVAGEABLE": [
    {
      component: "problemAction",
      properties: {
        actionList: ["DONATE_AND_DESTROY", "DAMAGED_UNSALVAGEABLE"]
      }
    }
  ],
  "UNDELIVERABLE_SUSPECTED_ABUSE_RFFC": [
    {
      api: "updateExceptionShipments",
      properties: {
        targetAction: "UNDELIVERABLE_SUSPECTED_ABUSE_RFFC"
      }
    },
    getSuccessScreenConfig(false)
  ],
  "MANUAL_READY_FOR_FC_RETURN": [
    {
      api: "updateExceptionShipments",
      properties: {
        targetAction: "MANUAL_READY_FOR_FC_RETURN"
      }
    },
    getSuccessScreenConfig(false)
  ],
  "DAMAGED_UNSALVAGEABLE": [
    {
      api: "updateExceptionShipments",
      properties: {
        targetAction: "DAMAGED_UNSALVAGEABLE"
      }
    },
    getSuccessScreenConfig(true, false)
  ],
  "PRINT_PALLET_LABEL": [
    {
      api: PROBLEM_ACTION_API.GET_PRINT_LABEL,
      properties: {
        labelType: "PALLET_LABEL"
      }
    },
    {
      component: "printSuccessScreen",
      properties: {
        reprintMoreLabelsSupported: false
      }
    }
  ],
  "PRINT_SLAM_LABEL": [
    {
      api: PROBLEM_ACTION_API.GET_PRINT_LABEL,
      properties: {
        labelType: "SLAM_LABEL"
      }
    },
    {
      api: PROBLEM_ACTION_API.UPDATE_EXCEPTION_SHIPMENTS,
      properties: {
        targetAction: "NO_OP"
      }
    },
    {
      component: "printSuccessScreen",
    }
  ],
  "PRINT_ACTIVE_LABEL":[
    {
      component: "printActiveLabel",
    }
  ],
  "LABEL_PRINT":[
    {
      api: PROBLEM_ACTION_API.CREATE_OPEN_EXCEPTION,
      properties: {
        targetAction: "LABEL_PRINT"
      }
    },
    {
      api: PROBLEM_ACTION_API.GET_PRINT_LABEL,
      properties: {
        labelType: "LOST_BUT_FOUNDLABEL_PRINT"
      }
    },
    {
      api: PROBLEM_ACTION_API.UPDATE_EXCEPTION_SHIPMENTS,
      properties: {
        targetAction: "LABEL_PRINT"
      }
    },
    {
      component: "printSuccessScreen",
      properties: {
        reprintMoreLabelsSupported: false
      }
    }
  ],
  "PRINT_LABEL_AND_REPLAN":[
    {
      api: PROBLEM_ACTION_API.GET_PRINT_LABEL,
      properties: {
        labelType: "SLAM_LABEL"
      }
    },
    {
      api: PROBLEM_ACTION_API.UPDATE_EXCEPTION_SHIPMENTS,
      properties: {
        targetAction: "REPLAN"
      }
    },
    {
      component: "printSuccessScreen",
      properties: {
        reprintMoreLabelsSupported: false
      }
    }
  ],
  "REPLAN":[
    {
      api: "updateExceptionShipments",
      properties: {
        targetAction: "REPLAN"
      }
    },
    getSuccessScreenConfig(false, false)
  ],
  "REASSIGN_TO_ANOTHER_DRIVER" : [
    {
      component: "selectDriver"
    }
  ],
  "MISSING": [
    {
      api: "updateExceptionShipments",
      properties: {
        targetAction: "MISSING"
      }
    },
    getSuccessScreenConfig(false, false)
  ],
  "MISSORT_REPLAN": [
    {
      api: "updateExceptionShipments",
      properties: {
        targetAction: "MISSORT_REPLAN"
      }
    },
    getSuccessScreenConfig(false, false)
  ],
  "READY_FOR_FC_RETURN": [
    {
      api: "updateExceptionShipments",
      properties: {
        targetAction: "READY_FOR_FC_RETURN"
      }
    },
    getSuccessScreenConfig(true, false)
  ],
  "NO_OP": [
    {
      api: "updateExceptionShipments",
      properties: {
        targetAction: "NO_OP"
      }
    },
    getSuccessScreenConfig(false)
  ],
  "NOT_FOUND": [
    {
      api: "updateExceptionShipments",
      properties: {
        targetAction: "NOT_FOUND"
      }
    },
    getSuccessScreenConfig(false, false)
  ],
  "STAGE_ITEM": [
    {
      api: "getStagingAreaForPickList"
    },
    {
      component: "stageItem"
    },
    getSuccessScreenConfig(false, false)
  ],
  "ITEM_REPLAN": [
    {
      api: "updateExceptionShipments",
      properties: {
        targetAction: "ITEM_REPLAN"
      }
    },
    getSuccessScreenConfig(false, false)
  ],
  "RTS_DAMAGED_SALVAGEABLE": [
    {
      api: PROBLEM_ACTION_API.UPDATE_EXCEPTION_SHIPMENTS,
      properties: {
        targetAction: "RTS_DAMAGED_SALVAGEABLE"
      }
    },
    getSuccessScreenConfig(true, false)
  ],
  "MILK_RUN": [
    {
      api: "updateExceptionShipments",
      properties: {
        targetAction: "MILK_RUN"
      }
    },
    getSuccessScreenConfig(true, false)
  ],
  "WRONG_STATION_DELIVER": [
    {
      api: "updateExceptionShipments",
      properties: {
        targetAction: "WRONG_STATION_DELIVER"
      }
    },
    getSuccessScreenConfig(false, false)
  ],
  "MANUAL_RETURN_TO_FC": [
    {
      api: "updateExceptionShipments",
      properties: {
        targetAction: "MANUAL_RETURN_TO_FC"
      }
    },
    getSuccessScreenConfig(true, false)
  ],
  "CARRIER_MISSORT": [
    {
      api: "updateExceptionShipments",
      properties: {
        targetAction: "CARRIER_MISSORT"
      }
    },
    getSuccessScreenConfig(true, false)
  ],
  "ACTION_STOW_FAIL_RTS": [
    {
      api: "updateExceptionShipments",
      properties: {
        targetAction: "ACTION_STOW_FAIL_RTS"
      }
    },
    getSuccessScreenConfig(false, false)
  ],
  "PREPARE_FOR_INDUCT": [
    {
      api: "updateExceptionShipments",
      properties: {
        targetAction: "PREPARE_FOR_INDUCT"
      }
    },
    getSuccessScreenConfig(false, false)
  ],
  "STOW":[
    {
      component: "dolphinModuleRedirector",
      properties: {
        targetModule: "STOW"
      }
    }
  ],
  "CLUSTER_TRANSFER_REPLAN": [
    {
      api: "updateExceptionShipments",
      properties: {
        targetAction: "CLUSTER_TRANSFER_REPLAN"
      }
    },
    getSuccessScreenConfig(false, false)
  ],
  "RTO_UNSALVAGEABLE": [
    {
      api: "updateExceptionShipments",
      properties: {
        targetAction: "RTO_UNSALVAGEABLE"
      }
    },
    getRTOSuccessScreenConfig(true, true),
  ],
  "RTS_PRINT_LABEL": [
    {
      api: PROBLEM_ACTION_API.GET_PRINT_LABEL,
      properties: {
        labelType: "RTS_DAMAGEDRTS_DAMAGED_SALVAGEABLE"
      }
    },
    {
      api: PROBLEM_ACTION_API.UPDATE_EXCEPTION_SHIPMENTS,
      properties: {
        targetAction: "RTS_PRINT_LABEL"
      }
    },
    {
      component: "rtoPrintSuccessScreen",
      properties: {
        reprintMoreLabelsSupported: false
      }
    }
  ],
  "RTO_PRINT_LABEL": [
    {
      api: PROBLEM_ACTION_API.UPDATE_EXCEPTION_SHIPMENTS,
      properties: {
        targetAction: "RTO_PRINT_LABEL"
      }
    },
    {
      api: PROBLEM_ACTION_API.GET_PRINT_LABEL,
      properties: {
        labelType: "PRINT_REVERSE_LABELRTO_PRINT_LABEL"
      }
    },
    {
      component: "rtoPrintSuccessScreen",
      properties: {
        reprintMoreLabelsSupported: false
      }
    }
  ],
  "RTO_DESTROY": [
    {
      api: "updateExceptionShipments",
      properties: {
        targetAction: "RTO_DESTROY"
      }
    },
    {
      component: "rtoDestroySuccessScreen",
    }
  ],
  "RTO_REPLAN": [
    {
      api: "updateExceptionShipments",
      properties: {
        targetAction: "RTO_REPLAN"
      }
    },
    {
      api: PROBLEM_ACTION_API.GET_PRINT_LABEL,
      properties: {
        labelType: "RTO_WRONG_PLANNED_STATIONRTO_REPLAN"
      }
    },
    {
      component: "rtoPrintSuccessScreen",
    }
  ],
  "INDUCT_PRINT_LABEL": [
    {
      api: PROBLEM_ACTION_API.GET_PRINT_LABEL,
      properties: {
        labelType: "INDUCT_PRINT_LABELINDUCT_RELABEL_REQUIRED"
      }
    },
    {
      api: "updateExceptionShipments",
      properties: {
        targetAction: "INDUCT_PRINT_LABEL"
      }
    },
    getSuccessScreenConfig(true, false)
  ],
  "NETWORK_MISSORT_REPLAN": [
    {
      api: "updateExceptionShipments",
      properties: {
        targetAction: "NETWORK_MISSORT_REPLAN"
      }
    },
    {
      component: "networkMissortSuccessScreen"
    }

  ]
};

