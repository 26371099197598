import Table, {TableCell, TableRow} from "@amzn/meridian/table";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import {FormattedMessage} from "react-intl";
import React from "react";
import PropTypes from 'prop-types';
import Icon from "@amzn/meridian/icon";
import chevronRightSmallTokens from "@amzn/meridian-tokens/base/icon/chevron-right-small";

const TableRowView = ({items, callback}) => {
    if (items.length === 0) {
        return (<div/>)
    }

    let [key, value] = Object.keys(items[0])

    return (
        <Table showDividers={true} >
            {
                items.map((item, index) => (
                    <TableRow key={index} onClick={() => callback(item)}>
                        <TableCell>
                            <Row alignmentHorizontal="justify">
                                <Text type="b300"><FormattedMessage id={item[key].toLowerCase()}/></Text>
                                <Text type="h100" alignment="right"><u>{item[value]}</u><Icon tokens={chevronRightSmallTokens}/></Text>
                            </Row>
                        </TableCell>
                    </TableRow>
                ))
            }
        </Table>
            )
}

TableRowView.propTypes = {
    items: PropTypes.array,
    callback: PropTypes.func
}

export default TableRowView