import {getScannedContent, getShipmentDetail} from "../dataStore/PSExecutionDataStore";
import {
    COMMA,
    MISSING,
    PICKUP_EXCEPTIONS,
    PICKUP_EXCEPTIONS_TARGET_ACTION,
    SPACE,
    TARGET_PROBLEM_STATUS
} from "../constants/Constants";

export const isValidJSON = (inputJson) => {
    inputJson = typeof inputJson !== "string"
        ? JSON.stringify(inputJson)
        : inputJson;

    try {
        inputJson = JSON.parse(inputJson);
    } catch (e) {
        return false;
    }

    if (typeof inputJson === "object" && inputJson !== null) {
        return true;
    }

    return false;
};

export const isValidPackageExceptionQRCode = (QRJSON) => {
    QRJSON = JSON.parse(QRJSON);
    return QRJSON.pickupExceptions && QRJSON.pickupExceptions.length !== 0
        && QRJSON.transporterId && QRJSON.transporterId.length !== 0;
};

export const getScannablesFromQRCode = (QRJSON) => {
    QRJSON = JSON.parse(QRJSON);
    return QRJSON.pickupExceptions.map(pickupException => {
        return pickupException.scannableId;
    });
};

export const getExceptionCountByCategory = (category) => {
    return getPackageDetailsByCategory(category).length;
};

export const getPackageDetailsByCategory = (category) => {
    var scannedContent = JSON.parse(getScannedContent());
    return scannedContent.pickupExceptions.filter(exception => {
        return exception.exception === category;
    }).map((exception) => {
        return getShipmentDetail().find((shipment) => {
            return shipment.scannableId === exception.scannableId;
        });
    });
};

export const prepareUpdateExceptionShipmentsRequest = () => {
    var request = [];
    var scannedContent = JSON.parse(getScannedContent());
    var exceptionToScannableMap = {};
    scannedContent.pickupExceptions.forEach((exception) => {
        if (exceptionToScannableMap[exception.exception] === undefined) {
            exceptionToScannableMap[exception.exception] = [exception.scannableId];
        } else {
            exceptionToScannableMap[exception.exception].push(exception.scannableId);
        }
    });
    for (var exception in exceptionToScannableMap) {
        var param = {};
        param["problemCategory"] = getProblemCategory(exception);
        param["scannableId"] = exceptionToScannableMap[exception];
        param["targetAction"] = getTargetAction(exception);
        param["targetProblemStatus"] = TARGET_PROBLEM_STATUS.OPEN;
        request.push(param);
    }
    return request;
};

const getProblemCategory = (exception) => {
    switch (exception) {
        case PICKUP_EXCEPTIONS.CUBE_OUT:
            return PICKUP_EXCEPTIONS.CUBE_OUT;
        case PICKUP_EXCEPTIONS.MISSING_PACKAGE:
            return MISSING;
        // no default
    }
};

const getTargetAction = (exception) => {
    switch (exception) {
        case PICKUP_EXCEPTIONS.CUBE_OUT:
            return PICKUP_EXCEPTIONS_TARGET_ACTION.CUBE_OUT_REPLAN;
        case PICKUP_EXCEPTIONS.MISSING_PACKAGE:
            return PICKUP_EXCEPTIONS_TARGET_ACTION.IDENTIFY_MISSING;
        // no default
    }
};

export const getSuccessScreenDescription = (response) => {
    var cubeOutCount = getCategoryCountFromResponse(response, PICKUP_EXCEPTIONS.CUBE_OUT);
    var missingCount = getCategoryCountFromResponse(response, MISSING);
    if (cubeOutCount === 0) {
        return SPACE + missingCount + getMissingSuccessString();
    } else if (missingCount === 0) {
        return SPACE + cubeOutCount + getCubeOutSuccessString();
    } else {
        return SPACE + cubeOutCount + getCubeOutSuccessString()
            + COMMA + missingCount + getMissingSuccessString();
    }
};

const getMissingSuccessString = () => {
    return " Package(s) Missing Packages";
};
const getCubeOutSuccessString = () => {
    return " Package(s) Cube out";
};

const getCategoryCountFromResponse = (response, problemCategory) => {
    return response.filter(response => {
        return response.problemCategory === problemCategory;
    }).length;
};