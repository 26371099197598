import React, {useEffect, useCallback, useContext, useReducer} from 'react';
import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Alert from "@amzn/meridian/alert";
import Text from "@amzn/meridian/text";
import {useHistory, withRouter} from "react-router-dom";
import {NOTIFICATION_TYPE} from "../../constants/Constants";

//import {ROUTE_PATH} from "../../router/routing";
import PropTypes from 'prop-types';
import {getSecondaryTitle, getRTOSuccessScreenTextMessage1, getRTOSuccessScreenTextMessage2, getRTOSuccessScreenTextMessage3} from "../../helper/SuccessScreenHelper";
import {FormattedMessage, injectIntl} from "react-intl";
import {
    getexceptionResolutionResult,
    getProblemAction,
    getProblemCategory,
    getScannedScannableIds
} from "../../dataStore/PSExecutionDataStore";
import {getPackageLabelType, isPrintingSupported} from "../../print/PrintHelper";
import {GET_PACKAGE_LABEL_ACTION_TYPE, GetPackageLabelReducer} from "../../reducers/GetPackageLabelReducer";
import InProgress from "../common/InProgress";
import NotificationMessage from "../common/NotificationMessage";
import {ProblemActionReducerActionType} from "../../reducers/ProblemActionReducer";
import Printer from "../../print/Printer";
import {PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS} from "../../router/ProblemSolveExecutionRouter";
import {
    clearBackPressedEventListener,
    interceptBackButton,
    interceptBackButtonStop,
    setBackPressedEventListener
} from "../backPressedView/BackButton";
import {SoundManager} from "../../audio/SoundManager";
import {problemActionConfig} from "../../config/ProblemActionConfig";
import {AppContext} from "../backPressedView/AppContext";
import {setPrimaryOnClickListener} from "../common/TopNavigation";

const RTOSuccessScreen = (props) => {
    const [{isLoading, notificationType, notificationMessageId}, dispatch] = useReducer(GetPackageLabelReducer, {isLoading: false});
    const result = getexceptionResolutionResult();
    const metaData = (props.location.state)? props.location.state.metaData : {};
    const scannableId = getScannedScannableIds();
    const secondaryTitle = getSecondaryTitle(result.successCount, result.totalPackages,
        getProblemAction(), scannableId, getProblemCategory(), metaData);
    const textMessage1 = getRTOSuccessScreenTextMessage1(getProblemAction(), scannableId, getProblemCategory(), metaData);
    const textMessage2 = getRTOSuccessScreenTextMessage2(getProblemAction(), scannableId, getProblemCategory(), metaData);
    const textMessage3 = getRTOSuccessScreenTextMessage3(getProblemAction(), scannableId, getProblemCategory(), metaData);

    const history = useHistory();

    const {state: {
        onBackPressed,
        backOptions,
    } = {}, appActions} = useContext(AppContext);

    const onBackPressedEvent = useCallback(() => {
        if (backOptions.open) {
            appActions.closeBackDrop();
        }  else {
            appActions.openBackDrop();
        }
    }, [onBackPressed, backOptions]);


    useEffect(() => {
        SoundManager.playSuccessSound();

        if ( disableBackButton()) {
            setBackPressedEventListener(onBackPressedEvent);
            interceptBackButton()
            setPrimaryOnClickListener(() => handlePrintCommand(history, dispatch));
        }

        return () => {
            if(isPrintingSupported()){Printer.closePrinter()}
            interceptBackButtonStop();
            clearBackPressedEventListener();
        }

    },[]);

    useEffect(()=> {
        if(notificationType === NOTIFICATION_TYPE.SUCCESS) {
            history.push("/ps/rtoPrintSuccessScreen");
        }
    }, [notificationType]);

    const disableBackButton = () => {
        const disableBackButton = problemActionConfig[getProblemAction()][1].properties.disableBackButton;

        return disableBackButton === undefined ? false : disableBackButton;
    };

    const handlePrintCommand = (history, dispatch) => {
        Printer.getConnectionStatus().then((connectionStatus) => {
           if (connectionStatus === "true") {
                dispatch({
                    type: GET_PACKAGE_LABEL_ACTION_TYPE.FETCH_PACKAGE_LABEL,
                    data: {
                        scannableId: getScannedScannableIds()[0],
                        labelType: getPackageLabelType()
                    },
                    dispatcher: dispatch
                });
           } else {
                history.push(PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.CONNECT_PRINTER);
           }
        }).catch(()=>{
            history.push(PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.CONNECT_PRINTER);
        });

    };

    return (
        <Column height="100%" heights={["fill","fit"]}>
              <Column spacingInset="medium">
                {isLoading && <InProgress/>}
                 {notificationType && <NotificationMessage dispatch={dispatch} actionType={ProblemActionReducerActionType.CLOSE_NOTIFICATION}
                                                           type={notificationType} messageId={notificationMessageId}/>}
                <br/><br/>
                <Alert
                    type="warning"
                    size="xlarge"
                    title=<FormattedMessage id="ps_almost_there"/>>
                </Alert>
                <Column alignmentHorizontal="left">
                    <Text type="h200">{secondaryTitle}</Text>
                </Column>
                <Column width="100%" alignmentVertical="top" align="left"><Text type="b300">{textMessage1}</Text></Column>
                <Column width="100%" alignmentVertical="top" align="left"><Text type="b300">{textMessage2}</Text></Column>
                <Column width="100%" alignmentVertical="top" align="left"><Text type="b300">{textMessage3}</Text></Column>
             </Column>
            <Column width="100%" spacingInset="medium" alignmentVertical="top">
                {isPrintingSupported() &&
                <Button type="secondary" size="large" onClick={() => handlePrintCommand(props.history,
                    dispatch)}><FormattedMessage id="ps_print_label"/></Button>}
                <div className="spaceBlockerRTO"/>
            </Column>
        </Column>
    );
};


RTOSuccessScreen.propTypes = {
    primaryTitle: PropTypes.string,
    imageUrl: PropTypes.string,
    isPrintLabelSupported: PropTypes.bool,
    isSolveMoreProblemsVisible: PropTypes.bool,
    intl: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object
};

export default withRouter(injectIntl(RTOSuccessScreen));