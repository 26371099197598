import React, {useEffect} from 'react';
import {FormattedMessage, injectIntl} from "react-intl";
import Column from "@amzn/meridian/column";
import Alert from "@amzn/meridian/alert";
import Button from "@amzn/meridian/button";
import Text from "@amzn/meridian/text";
import {ROUTE_PATH} from "../../router/routing";
import {getProblemAction, getProblemCategory, getScannedScannableIds, getShipmentDetail} from "../../dataStore/PSExecutionDataStore";
import PropTypes from 'prop-types';
import {withRouter} from "react-router";
import Printer from "../../print/Printer";
import {
    clearBackPressedEventListener,
    interceptBackButton,
    interceptBackButtonStop,
    setBackPressedEventListener
} from "../backPressedView/BackButton";
import {SoundManager} from "../../audio/SoundManager";
import {
    getRTOPrintSuccessScreenTextMessage1,getRTOPrintSuccessScreenTextMessage2, getRTOPrintSuccessScreenTextMessage3, getRTOPrintSuccessScreenTextMessage4
} from "../../helper/SuccessScreenHelper";
import NotificationMessage from "../common/NotificationMessage";
import {ProblemActionReducerActionType} from "../../reducers/ProblemActionReducer";
import {NOTIFICATION_TYPE, PHARMACY_SHIPMENT_CATEGORY, SWA_SHIPMENT_CATEGORY} from "../../constants/Constants";
import {FeatureManager} from "@amzn/dolphin-web-framework";


const RTOPrintSuccessScreen = ({intl, history, location}) => {
    const metaData = (location.state)? location.state.metaData : {};
    const scannableId = getScannedScannableIds();
    const textMessage1 = getRTOPrintSuccessScreenTextMessage1(getProblemAction(), scannableId, getProblemCategory(), metaData);
    const textMessage2 = getRTOPrintSuccessScreenTextMessage2(getProblemAction(), scannableId, getProblemCategory(), metaData);
    const textMessage3 = getRTOPrintSuccessScreenTextMessage3(getProblemAction(), scannableId, getProblemCategory(), metaData);
    const textMessage4 = getRTOPrintSuccessScreenTextMessage4(getProblemAction(), scannableId, getProblemCategory(), metaData);
    const displayCampusShipWarning = getProblemAction() === "RTO_REPLAN";
    const shipmentDetail = getShipmentDetail();
    const isValidShipmentCategory = (shipmentDetail.length > 0 && shipmentDetail[0].shipmentCategory);
    const displayPharmacyStowMessage = (isValidShipmentCategory && (shipmentDetail[0].shipmentCategory === PHARMACY_SHIPMENT_CATEGORY));
    const displaySWAMilkRunMessage = (isValidShipmentCategory && (shipmentDetail[0].shipmentCategory === SWA_SHIPMENT_CATEGORY)
        && FeatureManager.isFeatureEnabled(FeatureManager.Features.SHOW_SWA_SPECIFIC_PRINT_LABEL_TEXT))

    const handleSolveMoreProblemsAndBackPressEvent = () => {
        Printer.closePrinter();
        history.push(ROUTE_PATH.HOME)
    };

    useEffect(() => {
        SoundManager.playSuccessSound();
        setBackPressedEventListener(handleSolveMoreProblemsAndBackPressEvent);
        interceptBackButton();
        return () => {
            interceptBackButtonStop();
            clearBackPressedEventListener();
        };
    }, []);

    return (
        <Column height="100%" heights={["fill", "fit"]}>
            <Column spacingInset="medium">
                {displayCampusShipWarning && <NotificationMessage actionType={ProblemActionReducerActionType.CLOSE_NOTIFICATION}
                                     type={NOTIFICATION_TYPE.WARNING} messageId={intl.formatMessage({id:"warning_do_not_use_campus_ship"})}/>}
                <Alert
                    type="success"
                    size="xlarge"
                    title={intl.formatMessage({id: "success_package_marked_heading"})}>
                    <FormattedMessage id="ps_damaged_label_singular_success"
                                      values={{trackingId: getPrintTrackingId()}}/>
                </Alert>
                <Column width="100%" alignmentVertical="top" align="left"><Text
                    type="b300">{textMessage1}</Text></Column>
                <Column width="100%" alignmentVertical="top" align="left"><Text
                    type="b300">{textMessage2}</Text></Column>
                {displayPharmacyStowMessage &&
                <div>
                    <Column width="100%" alignmentVertical="top" align="left"><Text
                        type="b300">{textMessage3}</Text></Column>
                </div>
                }
                {displaySWAMilkRunMessage &&
                    <div>
                        <Column width="100%" alignmentVertical="top" align="left"><Text
                            type="b300">{textMessage4}</Text></Column>
                    </div>
                }
            </Column>
            <Column width="100%" spacingInset="medium" alignmentVertical="top">
                <Button size="large" onClick={() => handleSolveMoreProblemsAndBackPressEvent()}>
                    <FormattedMessage id="solve_more_problems"/>
                </Button>
                <div className="spaceBlockerRTO"/>
            </Column>
        </Column>
    );
};

const getPrintTrackingId = () => {
    const trackingIds = getScannedScannableIds();
    return(trackingIds[0])?trackingIds[0].substr(-4):"";
};

RTOPrintSuccessScreen.propTypes = {
    intl: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object
};

export default withRouter(injectIntl(RTOPrintSuccessScreen));