import {getProblemCategory, getProblemCategoryConfig} from "../dataStore/PSExecutionDataStore";
import {Constants, DataHandler} from "@amzn/dolphin-web-framework";
import {LABEL_PRINT, LOST_BUT_FOUND} from "../constants/Constants";

export const isScanBoxEnabledForProblemCategory = () => {
  return getProblemCategoryConfig()
      && getProblemCategory()
      && getProblemCategoryConfig()[getProblemCategory()].isBulkSolvingSupported === true;
};

export const isShowProductListSupported = () => {
    return getProblemCategoryConfig()
        && getProblemCategory()
        && getProblemCategoryConfig()[getProblemCategory()].showProductList === true;
};

export const isAutoResolveExceptionCategory = (action) => {
    return Constants.Region.REGION_IN === DataHandler.getCountry()
        && getProblemCategory() === LOST_BUT_FOUND
        && action !== LABEL_PRINT;
};