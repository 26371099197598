import React, {useEffect, useReducer} from 'react';
import Column from "@amzn/meridian/column";
import Box from "@amzn/meridian/box";
import Text from "@amzn/meridian/text";
import {useParams} from "react-router-dom";
import {TaskView} from "@amzn/dolphin-uiweb-framework";
import {getDisplayName} from "../../handler/TranslationHandler";
import {FormattedMessage, injectIntl} from "react-intl";

import {
    ProblemTypeReducer,
    ProblemTypeReducerActionType,
    ProblemTypeReducerInitialState
} from "../../reducers/ProblemTypeReducer";
import {ScannedPackagesListView} from "../scannedpackageslist/ScannedPackagesListView";
import InProgress from "../common/InProgress";
import {PROBLEM_CATEGORY_URL_PREFIX} from "../../constants/Constants";
import ScanBox from "../scanbox/ScanBox";
import PropTypes from 'prop-types';
import {withRouter} from "react-router";
import {getShipmentDestinationDomainAndCategory} from "../../utils/ShipmentUtils";
import {getShipmentDetail} from "../../dataStore/PSExecutionDataStore";

const ProblemType = ({intl, history}) => {

    const {view} = useParams();
    const [{problemTypes, fetchingData}, dispatch] = useReducer(ProblemTypeReducer, ProblemTypeReducerInitialState);

    useEffect(() => {
        dispatch({
            type: ProblemTypeReducerActionType.GET_PROBLEM_TYPE_TO_CATEGORY_MAP,
            dispatcher: dispatch,
            data: getShipmentDestinationDomainAndCategory(getShipmentDetail())
        })
    }, [view]);

    if (fetchingData) {
        return (
            <InProgress/>
        );
    }

    const handleOnClickProblemType = (problemType) => {
        history.push(PROBLEM_CATEGORY_URL_PREFIX + problemType);
    };

    const getProblemTypeTasks = () => {
        return problemTypes.map((problemType) => {
            return {
                title: getDisplayName(intl, problemType),
                description: getDisplayName(intl, problemType + "_desc"),
                onClick: ()=>handleOnClickProblemType(problemType)
            }
        })
    };

    return (
        <Column>
            <Box spacingInset="none">
                <ScanBox showChangeEntity={false} titleId="scan_item_id" />
                <Box spacingInset="none medium small medium">
                    <Text type="h300"><FormattedMessage id={"whats_the_problem"}/></Text>
                </Box>
                <TaskView tasks={getProblemTypeTasks()}/>
            </Box>
            <ScannedPackagesListView/>
        </Column>
    );
};

ProblemType.propTypes = {
    intl: PropTypes.object,
    history: PropTypes.object
};
export default withRouter(injectIntl(ProblemType));